html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// html,
// #root,
// .wrapper,
// body {
//   height: 100%;
// }

body {
  font: 1rem 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.1;
  background: $background-color-main;
  color: $text-color-primary;
  overflow-x: hidden
}

// input, textarea {
// 	color: #727272;
// }


ol,
ul,
li {
  list-style: none;
  &.arrow {
    list-style: url(str-replace(str-replace($li-arrow-right, 'currentColor', $primary), '#', '%23'));
    // list-style: url(str-replace(str-replace($chevron-right, 'currentColor', $white), '#', '%23'));
  }
  &.disc {
    list-style: disc;
  }
  &.circle {
    list-style: circle;
  }
  &.square {
    list-style: square;
  }
}

.mainWrapper {
  margin-top: $headerTopHeight + $headerMainHeight;
  min-height: 40rem;
  @include main-transition;

  .compact+& {
    margin-top: $headerMainComapctHeight;
  }
}

.hidden {
  display: none;
}
// blockquote, q {
// 	quotes: none;
// }

// blockquote:before, blockquote:after, q:before, q:after {
// 	content: "";
// 	content: none;
// }

// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }


// /* Text elements */
// p {
// 	font: 14px 'Open Sans', sans-serif;
// 	font-weight: normal;
// 	line-height: 23px;
// }

/* Links */
a, span.link {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #454545;
  }
}

/* Margins & paddings */



// .sitecolor {
// 	color: $primary;
// }

.cusor-pointer, .pointer {
  cursor: pointer;
}

// .gray {
// 	color: #999;
// 	font-weight: 400;
// }

// .dark {
// 	color: $dark;
// 	font-weight: 400;
// }

// .bigtfont {
// 	font-size: 16px;
// 	line-height: 27px;
// }


// ul.defaulf {
// 	list-style: inherit;
// 	margin: 0 0 0 14px;
// }

// ul.defaulf li {
// 	list-style: inherit;
// 	padding: 4px 0 4px 2px;
// }

// ul, ol {
// 	margin: 0 0 20px 0;
// 	list-style-position: inside;
// }

// ul {
// 	list-style: none;
// }

// ul li {
// 	list-style: none;
// }

// ol {
// 	list-style-type: decimal;
// }

// ol ol {
// 	list-style: upper-alpha;
// }

// ol ol ol {
// 	list-style: lower-roman;
// }

// ol ol ol ol {
// 	list-style: lower-alpha;
// }

// ul ul, ol ol, ul ol, ol ul {
// 	margin-bottom: 0;
// }


// cite, em, i {
// 	font-style: italic;
// }

// blockquote {
// 	margin: 20px 0 20px 36px;
// 	font-size: 16px;
// 	border-left: 5px solid #e54c4c;
// 	padding: 5px 20px 5px 20px;
// 	font-family: 'Open Sans' !important;
// 	font-weight: normal;
// 	color: #454545;
// }

// pre {
// 	background: #f4f4f4;
// 	font-size: 13px;
// 	font: 13px 'Open Sans', sans-serif;
// 	line-height: 1.5;
// 	margin-bottom: 40px;
// 	overflow: auto;
// 	padding: 0.75em 20px;
// }

// code, kbd {
// 	font-size: 13px;
// 	font: 13px 'Open Sans', sans-serif;
// }

// abbr, acronym, dfn {
// 	border-bottom: 1px dotted #666;
// 	cursor: help;
// }

// address {
// 	display: block;
// 	margin: 0 0 40px;
// }

// ins {
// 	background: #fff9c0;
// 	text-decoration: none;
// }

// sup, sub {
// 	font-size: 10px;
// 	height: 0;
// 	line-height: 1;
// 	position: relative;
// 	vertical-align: baseline;
// }

// sup {
// 	bottom: 1ex;
// }

// sub {
// 	top: .5ex;
// }

// .dropcap {
// 	display: block;
// 	float: left;
// 	font-size: 45px;
// 	padding: 0;
// 	margin: 0;
// 	margin: 15px 8px 0 0;
// 	text-transform: uppercase;
// }




// blockquote, q {
// 	quotes: none;
// }

// blockquote:before, blockquote:after, q:before, q:after {
// 	content: "";
// 	content: none;
// }

// /* remember to define focus styles! */
// :focus {
// 	outline: 0;
// }

// /* remember to highlight inserts somehow! */
// ins {
// 	text-decoration: none;
// }

// del {
// 	text-decoration: line-through;
// }

// /* Clear Fix Styles */

// .clearfix:after {
// 	visibility: hidden;
// 	display: block;
// 	font-size: 0;
// 	content: " ";
// 	clear: both;
// 	height: 0;
// }

// .clearfix {
// 	display: inline-block;
// }

// /* start commented backslash hack \*/
// * html .clearfix {
// 	height: 1%;
// }

// .clearfix {
// 	display: block;
// }

// .clearfix {
// 	display: block;
// }

// /* less width */
// .less1 {
// 	width: 75%;
// 	margin: 0 auto;
// }

// .less2 {
// 	width: 50%;
// 	margin: 0 auto;
// }

// .less3 {
// 	width: 60%;
// 	margin: 0 auto;
// }

// .less4 {
// 	width: 45%;
// 	margin: 0 auto;
// }

// .less5 {
// 	width: 85%;
// 	margin: 0 auto;
// }

// .less6 {
// 	width: 70%;
// 	margin: 0 auto;
// }


// /* margin bitween sections */
// .margin_top1 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 10px;
// }

// .margin_top2 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 20px;
// }

// .margin_top3 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 30px;
// }

// .margin_top4 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 40px;
// }

// .margin_top5 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 50px;
// }

// .margin_top6 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 60px;
// }

// .margin_top7 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 70px;
// }

// .margin_top8 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 80px;
// }

// .margin_top9 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 90px;
// }

// .margin_top10 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 100px;
// }

// .margin_top11 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 110px;
// }

// .margin_top12 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 120px;
// }

// .margin_top13 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 130px;
// }

// .margin_top14 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 140px;
// }

// .margin_top15 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 150px;
// }

// .margin_top16 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 160px;
// }

// .margin_top17 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 170px;
// }

// .margin_top18 {
// 	float: left;
// 	width: 100%;
// 	margin-top: 180px;
// }


// .margin_bottom1 {
// 	margin-bottom: 10px;
// }

// .margin_bottom2 {
// 	margin-bottom: 20px;
// }

// .margin_bottom3 {
// 	margin-bottom: 30px;
// }

// .margin_bottom4 {
// 	margin-bottom: 40px;
// }

// .margin_bottom5 {
// 	margin-bottom: 50px;
// }

// .margin_bottom6 {
// 	margin-bottom: 60px;
// }

// .margin_bottom7 {
// 	margin-bottom: 70px;
// }

// .margin_bottom8 {
// 	margin-bottom: 80px;
// }

// .margin_bottom9 {
// 	margin-bottom: 90px;
// }

// .margin_bottom10 {
// 	margin-bottom: 100px;
// }

// .margin_bottom11 {
// 	margin-bottom: 110px;
// }

// .margin_bottom12 {
// 	margin-bottom: 120px;
// }


// /* image alignments */
// .imgleft {
// 	float: left;
// }

// .imgleft2 {
// 	float: left;
// 	margin-right: 30px;
// }

// .imgright {
// 	float: right;
// }


.alileft {
	text-align: left;
}

.alicent {
	text-align: center;
}

.aliright {
	text-align: right;
}

// /* responsive image */
// .rimg {
// 	width: 100%;
// }




// /*-------------------------------------------------------
// [Table of contents]

// 1. Header
// 	+ logo
// 	+ top navigation
// 	+ search

// 2. Content
// 	+ feature sections
// 	+ page title
// 	+ parallax backgrounds
// 	+ video backgrounds

// 3. Sidebar
// 	+ site search
// 	+ tabs
// 	+ portfolio widgets
// 	+ advertisements

// 3. Typography
// 	+ lists
// 	+ buttons
// 	+ styled boxes
// 	+ dropcaps
// 	+ highlight colors
// 	+ tables
// 	+ pricing tables
// 	+ framed boxes
// 	+ pagination

// 4. Footer
// 	+ copyrights
// 	+ google map

// --------------------------------------------------------*/


// /*----------------------------------------------------
// 	SITE MAIN STRUCTURE
// ------------------------------------------------------*/

.wrapper {
  width: 100%;
  margin: 0 auto 0 auto;
}

// .container_full {
// 	width: 100%;
// 	float: left;
// 	margin: auto;
// }

// .container {
// 	width: 1170px;
// 	margin: auto;
// }

// .content_fullwidth {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px 90px 0px;
// }

// .content_fullwidth.less {
// 	padding: 0px 0px 90px 0px;
// }

// .content_fullwidth.less2 {
// 	padding: 75px 0px 90px 0px;
// }

// .content_fullwidth.less3 {
// 	padding: 75px 0px 30px 0px;
// }

// .content_left {
// 	float: left;
// 	width: 71%;
// 	padding: 0px 0px 0px 0px;
// }

// .left_sidebar {
// 	float: left;
// 	width: 25%;
// 	padding: 0px 0px 0px 0px;
// }

// .content_right {
// 	float: right;
// 	width: 71%;
// 	padding: 0px 0px 0px 0px;
// }

// .right_sidebar {
// 	float: right;
// 	width: 25%;
// 	padding: 0px 0px 0px 0px;
// }

// .content_halfsite {
// 	float: left;
// 	width: 47.5%;
// 	margin-right: 5%;
// 	padding: 0px 0px 0px 0px;
// }

// .content_halfsite.last {
// 	margin-right: 0;
// }


// /* columns */
// .one_half, .one_third, .one_fourth, .two_third, .three_fourth, .one_fifth, .onecol_forty, .onecol_sixty, .onecol_thirtyfive {
// 	position: relative;
// 	margin-right: 4%;
// 	float: left;
// }

// .one_full {
// 	width: 100%;
// }

// .one_half {
// 	width: 48%;
// }

// .one_third {
// 	width: 30.650%;
// }

// .one_fourth {
// 	width: 22%;
// }

// .one_fifth {
// 	width: 16.800%;
// }

// .two_third {
// 	width: 65.350%;
// }

// .three_fourth {
// 	width: 74%;
// }

// .onecol_forty {
// 	width: 40%;
// }

// .onecol_sixty {
// 	width: 56%;
// }

// .onecol_thirtyfive {
// 	width: 35%;
// }

// /* Less Space Bitween Columns */
// .one_half_less, .one_third_less, .one_fourth_less, .two_third_less, .three_fourth_less, .one_fifth_less, .onecol_forty_less, .onecol_sixty_less, .onecol_thirtyfive_less {
// 	position: relative;
// 	margin-right: 2%;
// 	float: left;
// }

// .one_full_less {
// 	width: 100%;
// }

// .one_half_less {
// 	width: 49%;
// }

// .one_third_less {
// 	width: 32%;
// }

// .one_fourth_less {
// 	width: 23.500%;
// }

// .one_fifth_less {
// 	width: 18.400%;
// }

// .two_third_less {
// 	width: 66%;
// }

// .three_fourth_less {
// 	width: 74.500%;
// }

// .onecol_forty_less {
// 	width: 40%;
// }

// .onecol_sixty_less {
// 	width: 58%;
// }

// .onecol_thirtyfive_less {
// 	width: 36.250%;
// }

// .last {
// 	margin-right: 0 !important;
// 	clear: right;
// }


// /*---------------------------------------------------
// 	HEADER
// ------------------------------------------------------*/

// .slidermar {
// 	margin-top: 108px;
// }











// /*----------------------------------------------------
//    FEATURE SECTIONS
// ------------------------------------------------------*/

// /* feature section 1 */
// .feature_section1 {
// 	float: left;
// 	width: 100%;
// 	padding: 95px 0px 90px 0px;
// 	background: #f3f3f3;
// 	text-align: center;
// }

// .feature_section1 .one_third {
// 	text-align: left;
// }

// .feature_section1 i {
// 	float: left;
// 	width: 62px;
// 	height: 62px;
// 	border-radius: 100%;
// 	background-color: #fff;
// 	text-align: center;
// 	line-height: 62px;
// 	position: relative;
// 	margin: 6px;
// 	color: $dark;
// 	font-size: 23px;
// 	margin-right: 20px;
// }

// .feature_section1 i:after {
// 	border-radius: 100%;
// 	background: rgba(255, 255, 255, 0.1);
// 	border: 2px solid #fff;
// 	position: absolute;
// 	content: "";
// 	z-index: 1;
// 	top: 0px;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	transition: all ease 0.3s;
// }

// .feature_section1 i:hover:after {
// 	top: -5px;
// 	bottom: -5px;
// 	right: -5px;
// 	left: -5px;
// }


// /* feature section 2 */
// .feature_section2 {
// 	float: left;
// 	width: 100%;
// }

// .feature_section2 .title {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 75px 0px 30px 0px;
// 	background: url(../../images/.DEMO/up-arrow.png) no-repeat center bottom;
// }

// .feature_section2 .twoboxes {
// 	float: left;
// 	position: relative;
// 	z-index: 9;
// 	background: #3f4a52;
// 	width: 100%;
// 	padding: 85px 0px 85px 0px;
// }

// .feature_section2 .twoboxes:before {
// 	content: "";
// 	position: absolute;
// 	z-index: -1;
// 	top: 0;
// 	right: 50%;
// 	bottom: 0;
// 	left: 0;
// 	background: $dark;
// }

// .feature_section2 .twoboxes .left {
// 	float: left;
// 	width: 45%;
// 	text-align: left;
// }

// .feature_section2 .twoboxes .right {
// 	float: right;
// 	width: 43.5%;
// 	text-align: left;
// }

// .feature_section2 .twoboxes i {
// 	float: left;
// 	margin-right: 25px;
// 	color: #fff;
// 	font-size: 58px;
// 	margin-top: 14px;
// }

// .feature_section2 .twoboxes strong {
// 	color: #fff;
// 	font-size: 43px;
// 	line-height: 35px;
// 	font-weight: 900;
// 	display: block;
// 	text-transform: uppercase;
// 	letter-spacing: 0.5px;
// 	font-family: 'Roboto', sans-serif;
// 	margin-bottom: 45px;
// }

// .feature_section2 .twoboxes strong em {
// 	color: #fff;
// 	font-size: 18px;
// 	font-weight: 300;
// 	display: block;
// 	margin-bottom: 0px;
// 	font-style: normal;
// }

// .feature_section2 .twoboxes ul {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .feature_section2 .twoboxes li {
// 	width: 100%;
// 	padding: 14px 0px;
// 	margin: 0px;
// 	color: #fff;
// 	font-size: 16px;
// }

// .feature_section2 .twoboxes li i {
// 	color: #959b9f;
// 	float: left;
// 	font-size: 14px;
// 	margin-right: 12px;
// 	margin-top: 4px;
// 	margin-bottom: 30px;
// }

// .feature_section2 .twoboxes li a {
// 	float: left;
// 	margin-top: 10px;
// }



// /* feature section 3 */
// .feature_section3 {
// 	float: left;
// 	width: 100%;
// 	padding: 85px 0px 65px 0px;
// }

// .feature_section3 .lstblogs {
// 	float: left;
// 	width: 100%;
// 	background: $dark;
// 	font-family: 'Roboto', sans-serif;
// }

// .feature_section3 .lstblogs img {
// 	float: left;
// 	width: 100%;
// }

// .feature_section3 .lstblogs .date {
// 	float: left;
// 	color: #fff;
// 	font-size: 14px;
// 	padding: 10px 0px;
// 	border-radius: 3px;
// 	background: $primary;
// 	text-align: center;
// 	margin: 30px 20px 30px 30px;
// 	width: 10%;
// 	transition: all 0.3s ease;
// }

// .feature_section3 .lstblogs .date:hover {
// 	color: $dark;
// 	background: #fff;
// }

// .feature_section3 .lstblogs .date strong {
// 	font-size: 27px;
// 	display: block;
// 	font-weight: 400;
// }

// .feature_section3 .lstblogs h4 {
// 	float: left;
// 	margin: 30px 20px 30px 0px;
// 	width: 76%;
// 	transition: all 0.3s ease;
// }

// .feature_section3 .lstblogs .hline {
// 	width: 50px;
// 	height: 1px;
// 	background: #fff;
// 	margin-top: 10px;
// 	opacity: 0.5;
// }

// .feature_section3 .lstblogs h4 a:hover {
// 	color: $primary;
// }


// /* feature section 4 */
// .feature_section4 {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px;
// }

// .feature_section4 .left, .feature_section4 .center, .feature_section4 .right {
// 	float: left;
// 	width: 33.3333333333%;
// 	padding: 45px 50px 56px 50px;
// 	text-align: center;
// }

// .feature_section4 .left {
// 	background: $dark;
// }

// .feature_section4 .center {
// 	background: $primary;
// }

// .feature_section4 .right {
// 	background: #f3f3f3;
// }

// .feature_section4 strong {
// 	color: #fff;
// 	font-size: 27px;
// 	font-weight: 700;
// 	letter-spacing: 0.5px;
// 	text-transform: uppercase;
// 	font-family: 'Roboto', sans-serif;
// }

// .feature_section4 strong em {
// 	color: #fff;
// 	font-size: 21px;
// 	font-weight: 300;
// 	font-style: normal;
// 	display: block;
// 	text-transform: none;
// 	margin-bottom: 9px;
// }

// .feature_section4 .right strong, .feature_section4 .right strong em {
// 	color: $dark;
// }

// .feature_section4 img {
// 	margin: 25px auto 20px auto;
// 	display: block;
// }

// .feature_section4.sty2 {
// 	padding: 0px 0px;
// }

// .feature_section4.sty2 .left, .feature_section4.sty2 .center, .feature_section4.sty2 .right {
// 	padding: 35px 50px 35px 50px;
// }

// .feature_section4.sty2 strong {
// 	font-size: 45px;
// 	font-weight: 900;
// }

// .feature_section4.sty2 strong em {
// 	margin-bottom: 0px;
// 	margin-top: 7px;
// }




// /* feature section 5 */
// .feature_section5 {
// 	float: left;
// 	width: 100%;
// 	padding: 40px 0px 65px 0px;
// }

// .feature_section5 .two_third {
// 	padding-top: 70px;
// }

// .feature_section5 ul {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 5px 0px 9px 0px;
// }

// .feature_section5 li {
// 	float: left;
// 	padding: 5px 7px;
// 	border: 1px dashed #d9dbdc;
// 	text-align: center;
// 	margin: 0px 14px 14px 0px;
// }

// .feature_section5 li img {
// 	display: block;
// 	margin: 0 auto;
// 	width: 100%;
// }


// /* feature section 6 */
// .feature_section6 {
// 	float: left;
// 	width: 100%;
// 	background: #f3f3f3;
// 	padding: 90px 0px 80px 0px;
// 	text-align: center;
// }

// .feature_section6 .climg {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	background: url(../../images/.DEMO/h-lines.png) no-repeat center 48px;
// }

// .feature_section6 .climg img {
// 	width: 12.5%;
// 	border-radius: 100%;
// 	margin-bottom: 25px;
// }

// .feature_section6 .item strong {
// 	color: $primary;
// 	font-size: 16px;
// 	font-weight: 600;
// }

// .feature_section6 .item em {
// 	font-style: normal;
// 	color: #999;
// }

// .feature_section6.sty2 {
// 	background: #fff;
// }

// .feature_section6 .item strong {
// 	color: $dark;
// }

// /* feature section 7 */
// .feature_section7 {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px 97px 0px;
// 	background: #f3f3f3;
// 	text-align: center;
// }

// .feature_section7 .one_fifth_less {
// 	border: 1px solid #e3e3e3;
// 	border-radius: 3px;
// 	padding: 25px 0px 14px 0px;
// 	background: #fff;
// 	transition: all 0.1s ease;
// }

// .feature_section7 .one_fifth_less i {
// 	color: #727272;
// 	display: block;
// 	font-size: 45px;
// 	margin-bottom: 14px;
// }

// .feature_section7 .one_fifth_less:hover {
// 	border: 1px solid $dark;
// 	background: $dark;
// }

// .feature_section7 .one_fifth_less:hover i, .feature_section7 .one_fifth_less:hover h5 {
// 	color: #fff;
// }

// /* feature section 8 */
// .feature_section8 {
// 	float: left;
// 	width: 100%;
// 	background: $dark url(../../images/.DEMO/site-img22.jpg) no-repeat left top;
// 	padding: 85px 0px 67px 0px;
// 	text-align: center;
// }

// .feature_section8 .slidesec {
// 	float: left;
// 	width: 100%;
// 	padding: 40px;
// 	background: #fff;
// 	text-align: left;
// }

// .feature_section8 .imgbox {
// 	float: left;
// 	width: 40%;
// 	margin-right: 45px;
// }

// .feature_section8 .imgbox img {
// 	float: left;
// 	width: 100%;
// }

// .feature_section8 i {
// 	color: $primary;
// 	font-size: 14px;
// }


// /* feature section 9 */
// .feature_section9 {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px 45px 0px;
// 	text-align: center;
// }

// .feature_section9 .box {
// 	float: left;
// 	width: 100%;
// 	text-align: left;
// 	margin-bottom: 50px;
// 	padding: 25px 30px;
// 	background: #fff;
// 	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
// 	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
// 	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
// }



// /* feature section 10 */
// .feature_section10 {
// 	float: left;
// 	width: 100%;
// 	padding: 50px 0px 45px 0px;
// 	text-align: center;
// }

// .feature_section10 .box {
// 	float: left;
// 	width: 100%;
// 	padding: 16px 0px 10px 0px;
// 	border: 4px solid #f3f3f3;
// 	border-radius: 5px;
// }

// .feature_section10 .box h1, .feature_section10 .box h6 {
// 	margin-bottom: 5px;
// }

// .feature_section10 .box h1 em {
// 	margin-top: 0px;
// 	font-size: 14px;
// }


// /* feature section 11 */
// .feature_section11 {
// 	float: left;
// 	width: 100%;
// 	padding: 75px 0px 75px 0px;
// 	background: #eee;
// 	font-family: 'Roboto', sans-serif;
// 	text-align: center;
// }

// .feature_section11 h1 {
// 	margin-bottom: 20px;
// }

// .feature_section11 .tabs {
// 	display: table;
// 	table-layout: fixed;
// 	width: 100%;
// 	-webkit-transform: translateY(5px);
// 	transform: translateY(5px);
// 	padding: 0px;
// 	margin: 0px;
// }

// .feature_section11 .tabs>li {
// 	transition-duration: .25s;
// 	display: table-cell;
// 	list-style: none;
// 	text-align: center;
// 	padding: 20px 20px 25px 20px;
// 	position: relative;
// 	overflow: hidden;
// 	cursor: pointer;
// 	color: $dark;
// 	font-size: 18px;
// 	font-weight: 900;
// 	text-transform: uppercase;
// 	letter-spacing: 0.5px;
// }

// .feature_section11 .tabs>li:before {
// 	z-index: -1;
// 	position: absolute;
// 	content: "";
// 	width: 100%;
// 	height: 120%;
// 	top: 0;
// 	left: 0;
// 	background-color: rgba(255, 255, 255, 0.5);
// 	-webkit-transform: translateY(100%);
// 	transform: translateY(100%);
// 	transition-duration: .25s;
// 	border-radius: 3px 3px 0 0;
// }

// .feature_section11 .tabs>li:hover:before {
// 	-webkit-transform: translateY(70%);
// 	transform: translateY(70%);
// }

// .feature_section11 .tabs>li.active {
// 	color: $primary;
// }

// .feature_section11 .tabs>li.active:before {
// 	transition-duration: .5s;
// 	background-color: white;
// 	-webkit-transform: translateY(0);
// 	transform: translateY(0);
// }

// .feature_section11 .tab__content {
// 	background-color: white;
// 	position: relative;
// 	width: 100%;
// 	border-radius: 3px;
// }

// .feature_section11 .tab__content>li {
// 	width: 100%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	display: none;
// 	list-style: none;
// }

// .feature_section11 .tab__content>li .content__wrapper {
// 	text-align: center;
// 	border-radius: 5px;
// 	width: 100%;
// 	padding: 45px 40px 40px 40px;
// 	background-color: #fff;
// }

// .feature_section11 ul.domlist1 {
// 	float: left;
// 	width: 20%;
// 	padding: 0px 0px 58px 0px;
// 	margin: 0px 0px;
// 	border-right: 5px solid #fff;
// 	border-left: 5px solid #fff;
// }

// .feature_section11 .domlist1 li {
// 	width: 100%;
// 	height: 50px;
// 	padding-top: 13px;
// 	margin: 0px;
// 	border-bottom: 1px solid #f3f3f3;
// 	color: $dark;
// 	font-size: 16px;
// }

// .feature_section11 .domlist1 li.title {
// 	height: 50px;
// 	padding-top: 0px;
// }

// .feature_section11 .domlist1 li.title h6 {
// 	line-height: 18px;
// }

// .feature_section11 .domlist1 li em {
// 	color: #ccc;
// 	text-decoration: line-through;
// 	margin-right: 5px;
// 	font-style: normal;
// 	font-weight: 300;
// 	font-size: 12px;
// }


// /* feature section 12 */
// .feature_section12 {
// 	float: left;
// 	width: 100%;
// 	padding: 18px 0px 50px 0px;
// 	text-align: center;
// }

// .feature_section12 .one_fourth {
// 	text-align: left;
// }

// .feature_section12 i {
// 	float: left;
// 	width: 45px;
// 	height: 45px;
// 	border-radius: 100%;
// 	background-color: #f3f3f3;
// 	text-align: center;
// 	line-height: 45px;
// 	position: relative;
// 	margin: 6px;
// 	color: $dark;
// 	font-size: 18px;
// 	margin-right: 18px;
// }

// .feature_section12 i:after {
// 	border-radius: 100%;
// 	background: rgba(255, 255, 255, 0.1);
// 	border: 2px solid #f3f3f3;
// 	position: absolute;
// 	content: "";
// 	z-index: 1;
// 	top: 0px;
// 	left: 0;
// 	bottom: 0;
// 	right: 0;
// 	transition: all ease 0.3s;
// }

// .feature_section12 i:hover:after {
// 	top: -5px;
// 	bottom: -5px;
// 	right: -5px;
// 	left: -5px;
// }


// /* feature section 13 */
// .feature_section13 {
// 	float: left;
// 	width: 100%;
// 	padding: 75px 0px 90px 0px;
// 	text-align: center;
// 	background: #f3f3f3;
// }

// .feature_section13 .one_half_less {
// 	padding: 35px 30px 35px 30px;
// 	background: #fff;
// }


// /* feature section 14 */
// .feature_section14 {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px 95px 0px;
// 	text-align: center;
// 	background: #f3f3f3;
// }

// .feature_section14 .one_half {
// 	float: left;
// 	padding: 30px 35px;
// 	background: #fff;
// 	text-align: left;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// }

// .feature_section14 .one_half:hover {
// 	color: #fff;
// 	background: $dark;
// }

// .feature_section14 .one_half img {
// 	float: left;
// 	width: 140px;
// 	height: 140px;
// 	border-radius: 100%;
// 	margin-right: 25px;
// }

// .feature_section14 .one_half h5 em {
// 	display: block;
// 	font-size: 13px;
// 	font-style: normal;
// 	color: #999;
// 	font-weight: 300;
// }


// /* feature section 15 */
// .feature_section15 {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px 95px 0px;
// 	text-align: center;
// 	background: #f3f3f3;
// }

// .feature_section15 .one_fourth_less {
// 	float: left;
// 	padding: 10px 10px 30px 10px;
// 	background: #fff;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// }

// .feature_section15 .one_fourth_less:hover {
// 	color: #fff;
// 	background: $dark;
// }

// .feature_section15 .one_fourth_less img {
// 	width: 100%;
// 	margin-bottom: 20px;
// }

// .feature_section15 .one_fourth_less h5 em {
// 	display: block;
// 	font-size: 13px;
// 	font-style: normal;
// 	color: #999;
// 	font-weight: 300;
// }

// .feature_section15 .one_fourth_less p {
// 	width: 80%;
// 	margin: 0 auto 16px auto;
// }

// .feature_section15 .one_fourth_less a i {
// 	color: #fff;
// 	font-size: 13px;
// 	width: 26px;
// 	height: 26px;
// 	border-radius: 100%;
// 	background: #d3d3d3;
// 	text-align: center;
// 	vertical-align: middle;
// 	line-height: 26px;
// 	transition: all 0.3s ease;
// }

// .feature_section15 .one_fourth_less a:hover i {
// 	color: #fff;
// 	background: $primary;
// }


// /* feature section 16 */
// .feature_section16 {
// 	float: left;
// 	width: 100%;
// 	padding: 50px 0px 40px 0px;
// 	background: #f3f3f3;
// }


// /* feature section 17 */
// .feature_section17 {
// 	float: left;
// 	width: 100%;
// 	padding: 95px 0px 90px 0px;
// 	text-align: center;
// 	font-family: 'Roboto', sans-serif;
// }

// .feature_section17 .one_third {
// 	float: left;
// 	padding: 35px 50px 45px 50px;
// 	background: #fff;
// 	border-radius: 5px;
// 	border: 5px solid #f3f3f3;
// }

// .feature_section17 .one_third i {
// 	color: $dark;
// 	font-size: 45px;
// 	margin-bottom: 27px;
// 	border-radius: 100%;
// 	text-align: center;
// 	vertical-align: middle;
// }

// .feature_section17 .one_third a.but1 {
// 	color: #fff;
// 	border-radius: 3px;
// 	padding: 13px 27px;
// 	background: $primary;
// 	text-transform: uppercase;
// 	white-space: nowrap;
// 	transition: all 0.3s ease;
// }

// .feature_section17 .one_third a.but1:hover {
// 	color: #fff;
// 	background: $dark;
// }





// .video_full_site {
// 	width: 70%;
// 	height: 450px;
// }




// .client_logos {
// 	float: left;
// 	width: 100%;
// 	padding: 63px 0px 50px 0px;
// 	text-align: center;
// }

// .client_logos ul {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0 0 0 3%;
// }

// .client_logos li {
// 	float: left;
// 	padding: 0px 12px;
// 	margin: 0px;
// }

// .client_logos.two {
// 	padding: 0px 0px 50px 0px;
// }


// .price_compare {
// 	float: left;
// 	width: 100%;
// 	padding: 90px 0px;
// 	text-align: center;
// }

// .price_compare table {
// 	width: 100%;
// 	font-family: 'Roboto', sans-serif;
// }

// .price_compare table, .price_compare th, .price_compare td {
// 	border-collapse: separate;
// 	border-spacing: 10px 1px;
// }

// .price_compare table i {
// 	font-size: 14px;
// 	border-radius: 100%;
// 	width: 23px;
// 	height: 23px;
// 	text-align: center;
// 	vertical-align: middle;
// 	line-height: 22px;
// 	background: #fff;
// }

// .price_compare table td.first {
// 	background: #fff;
// 	border: none;
// }

// .price_compare td {
// 	padding: 12px 0px 12px 0px;
// 	text-align: left;
// 	background: #f9f9f9;
// 	text-align: center;
// 	color: $dark;
// }

// .price_compare th {
// 	padding: 12px 0px 12px 0px;
// 	text-align: left;
// 	background: #f3f3f3;
// 	text-align: center;
// 	font-weight: normal;
// 	color: $dark;
// }

// .price_compare th.alileft, .price_compare td.alileft {
// 	text-align: left;
// 	padding: 12px 0px 12px 18px;
// 	color: $dark;
// 	font-weight: 500;
// 	font-size: 15px;
// }

// .price_compare .rowfirst {
// 	width: 37%;
// }

// .price_compare .rowsremain {
// 	width: 21%;
// 	background: #eee;
// }

// .price_compare .title {
// 	float: left;
// 	width: 75%;
// 	text-align: right;
// }

// .price_compare .title .arrow_box {
// 	position: relative;
// 	background: #5f686f;
// 	padding: 12px 20px 12px 0px;
// }

// .price_compare .title .arrow_box:after {
// 	left: 100%;
// 	top: 50%;
// 	border: solid transparent;
// 	content: " ";
// 	height: 0;
// 	width: 0;
// 	position: absolute;
// 	pointer-events: none;
// 	border-color: rgba(153, 153, 153, 0);
// 	border-left-color: #5f686f;
// 	border-width: 18px;
// 	margin-top: -18px;
// }

// .price_compare .title h5 {
// 	color: #fff;
// 	margin-bottom: 0px;
// 	font-weight: 300;
// }

// .price_compare .title h3 {
// 	color: #fff;
// 	font-weight: 900;
// 	margin-bottom: 0px;
// }

// .price_compare .prices {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 20px 0px 30px 0px;
// }

// .price_compare .prices h4 {
// 	margin-bottom: 18px;
// }

// .price_compare .prices strong {
// 	color: $primary;
// 	font-size: 45px;
// 	font-weight: 800;
// 	display: block;
// }

// .price_compare .prices strong i {
// 	color: $dark;
// 	font-size: 12px;
// 	font-weight: 400;
// 	font-style: normal;
// 	background: none;
// }

// .price_compare .prices b {
// 	color: #727272;
// 	font-size: 12px;
// 	font-weight: 300;
// 	font-style: normal;
// 	display: block;
// 	margin-bottom: 22px;
// 	margin-top: 5px;
// }

// .price_compare .prices b em {
// 	font-style: normal;
// 	text-decoration: line-through;
// }

// .price_compare .prices a {
// 	color: #fff;
// 	font-size: 14px;
// 	background: $dark;
// 	padding: 10px 27px;
// 	border-radius: 3px;
// 	text-transform: uppercase;
// 	font-weight: 600;
// 	transition: all 0.3s ease;
// }

// .price_compare .prices a:hover {
// 	color: #fff;
// 	background: $primary;
// }

// .price_compare .rowsremain.center {
// 	background: $dark;
// }

// .price_compare .rowsremain.center .prices strong {
// 	color: #fff;
// }

// .price_compare .rowsremain.center .prices a {
// 	color: $dark;
// 	background: #fff;
// }

// .price_compare .rowsremain.center .prices a:hover {
// 	color: #fff;
// 	background: $primary;
// }

// .price_compare .rowsremain.center .prices i {
// 	color: #fff;
// }

// .price_compare .rowsremain.center span {
// 	color: #fff;
// 	padding: 3px 10px 4px 10px;
// 	background: $primary;
// 	margin-top: -62px;
// 	margin-bottom: 32px;
// 	display: block;
// 	text-transform: uppercase;
// 	font-weight: bold;
// 	letter-spacing: 0.5px;
// }



// /*----------------------------------------------------
//    PARALLAX SECTIONS
// ------------------------------------------------------*/

// /* parallax section 1 */
// .parallax_section1 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 90px 0px 70px 0px;
// 	background: #eee url("../../images/.DEMO/gray-full.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;
// }


// /* parallax section 2 */
// .parallax_section2 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 90px 0px 100px 0px;
// 	background: #eee url("../../images/.DEMO/gray-full.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;

// 	-moz-box-shadow: inset 0 3px 3px -3px #e0e0e0, 		inset 0 -3px 3px -3px #e0e0e0;
// 	-webkit-box-shadow: inset 0 3px 3px -3px #e0e0e0, 		inset 0 -3px 3px -3px #e0e0e0;
// 	box-shadow: inset 0 3px 3px -3px #e0e0e0, 		inset 0 -3px 3px -3px #e0e0e0;
// }

// .parallax_section2 .one_fifth_less {
// 	float: left;
// 	background: rgba(255, 255, 255, 0.75);
// 	border-radius: 3px;
// 	padding: 20px 0px 25px 0px;
// }


// /* parallax section 3 */
// .parallax_section3 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 90px 0px 70px 0px;
// 	background: #454545 url("../../images/.DEMO/site-img22.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;
// }


// /* parallax section 4 */
// .parallax_section4 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 90px 0px 70px 0px;
// 	background: #454545 url("../../images/.DEMO/site-img31.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;
// }


// /* parallax section 5 */
// .parallax_section5 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 90px 0px 70px 0px;
// 	background: #eee url("../../images/.DEMO/site-img31.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;
// }














// /*page titles*/

// .page_title1 {
// 	position: relative;
// 	float: left;
// 	width: 100%;
// 	color: #fff;
// 	text-align: center;
// 	margin-top: 108px;
// 	padding: 100px 0px 59px 0px;
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1 h1 {
// 	color: #fff;
// 	font-size: 45px;
// 	font-weight: 700;
// 	line-height: 45px;
// 	text-transform: uppercase;
// }

// .page_title1 h1 em {
// 	color: #fff;
// 	font-weight: 400;
// 	display: block;
// 	font-size: 18px;
// 	font-style: normal;
// 	text-transform: none;
// }

// .page_title1.sty2 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty3 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty4 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty5 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty6 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty8 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// 	text-align: left;
// 	padding: 120px 0px 81px 0px;
// }

// .page_title1.sty10 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// 	text-align: left;
// 	padding: 120px 0px 81px 0px;
// }

// .page_title1.sty11 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// 	text-align: left;
// 	padding: 90px 0px 59px 0px;
// }

// .page_title1.sty12 {
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// 	text-align: left;
// 	padding: 90px 0px 59px 0px;
// }


// .page_title1.sty9 {
// 	text-align: left;
// 	padding: 58px 0px 45px 0px;
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty9 h1 {
// 	float: left;
// 	font-size: 30px;
// 	color: $dark;
// 	margin-bottom: 12px;
// }

// .page_title1 .pagenation {
// 	float: right;
// 	color: #727272;
// 	font-size: 13px;
// 	margin-top: 13px;
// }

// .page_title1 .pagenation a {
// 	color: #999;
// 	transition: all 0.3s ease;
// }

// .page_title1 .pagenation a:hover {
// 	color: $dark;
// }

// .page_title1.sty13 {
// 	text-align: left;
// 	padding: 58px 0px 45px 0px;
// 	background: #999 url(../../images/.DEMO/gray-full.jpg) no-repeat center top;
// }

// .page_title1.sty13 h1 {
// 	float: left;
// 	font-size: 30px;
// 	color: #fff;
// 	margin-bottom: 12px;
// }

// .page_title1.sty13 .pagenation {
// 	color: #fff;
// }

// .page_title1.sty13 .pagenation a {
// 	color: #ccc;
// }

// .page_title1.sty13 .pagenation a:hover {
// 	color: #fff;
// }







// .page_title1.sty7 {
// 	padding: 100px 0px 85px 0px;
// 	background: #7ebb21 url(../../images/.DEMO/site-img28.png) no-repeat center top;
// }

// .page_title1.sty7 .serch_area {
// 	width: 61%;
// 	padding: 10px 0px 0px 0px;
// 	margin: 0 auto;
// 	font-family: 'Roboto', sans-serif;
// }

// .page_title1.sty7 .serch_area .enter_email_input {
// 	float: left;
// 	font-family: 'Roboto', sans-serif;
// 	padding: 3px 0px 4px 30px;
// 	height: 64px;
// 	width: 77.6%;
// 	background: #fff;
// 	border-radius: 3px;
// 	border: none;
// 	color: #727272;
// 	font-size: 16px;
// }

// .page_title1.sty7 .serch_area .input_submit {
// 	float: left;
// 	font-family: 'Roboto', sans-serif;
// 	padding: 20px 30px 21px 30px;
// 	background-color: $dark;
// 	border: 0px;
// 	font-size: 18px;
// 	font-weight: 500;
// 	color: #fff;
// 	text-transform: uppercase;
// 	transition: all 0.3s ease;
// 	border-radius: 3px;
// 	margin-left: 12px;
// }

// .page_title1.sty7 .serch_area .input_submit:hover {
// 	color: $dark;
// 	cursor: pointer;
// 	background-color: #fff;
// }

// .page_title1.sty7 .serch_area .molinks {
// 	float: left;
// 	width: 100%;
// 	text-align: left;
// 	margin-top: 14px;
// }

// .page_title1.sty7 .serch_area .molinks a {
// 	color: #fff;
// 	margin: 0px 10px 0px 0px;
// 	transition: all 0.3s ease;
// }

// .page_title1.sty7 .serch_area .molinks a:hover {
// 	color: $dark;
// }


// /* sidebar widget */
// .sidebar_widget {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .sidebar_widget ul.arrows_list1 {
// 	padding: 0px;
// 	margin: 0px;
// 	float: left;
// 	margin-top: -10px;
// }

// .sidebar_widget ul.arrows_list1 li a {
// 	color: $primary;
// 	line-height: 30px;
// }

// .sidebar_widget ul.arrows_list1 li a:hover {
// 	color: #272727;
// }

// .sidebar_widget ul.arrows_list1 i {
// 	margin-right: 4px;
// }

// .sidebar_title {
// 	float: left;
// 	width: 100%;
// }

// .sidebar_widget h4, .clientsays_widget h4 {
// 	margin-bottom: 30px;
// 	float: left;
// 	font-weight: 500;
// }

// /* Recent Posts */
// ul.recent_posts_list {
// 	margin: 0px;
// 	padding: 0px;
// 	width: 100%;
// 	float: left;
// }

// .recent_posts_list li {
// 	padding: 0px 0px 13px 0px;
// 	margin: 0px 0px 16px 0px;
// 	list-style-type: none;
// 	border-bottom: 1px solid #eee;
// 	float: left;
// 	width: 100%;
// }

// .recent_posts_list li a {
// 	text-decoration: none;
// 	line-height: 17px;
// 	display: block;
// 	color: #999;
// }

// .recent_posts_list li a:hover {
// 	color: $primary;
// }

// .recent_posts_list li span {
// 	float: left;
// 	margin-right: 15px;
// }

// .recent_posts_list li span img {
// 	float: left;
// 	margin-right: 0px;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
// 	-moz-opacity: 1;
// 	-khtml-opacity: 1;
// 	opacity: 1;
// }

// .recent_posts_list li span img:hover {
// 	float: left;
// 	margin-right: 0px;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
// 	-moz-opacity: 0.8;
// 	-khtml-opacity: 0.8;
// 	opacity: 0.8;
// }

// .recent_posts_list li i {
// 	padding: 1px 0px 0px 0px;
// 	margin: 0px;
// 	display: block;
// 	font-size: 10px;
// 	font-style: normal;
// 	color: #c9c9c9;
// }

// .recent_posts_list li.last {
// 	padding: 0px 0px 7px 0px;
// 	margin: 0px 0px 0px 0px;
// 	border-bottom: 0px solid #f5f6f6;
// }

// /* client says widget */
// .clientsays_widget {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// 	color: #999;
// }

// .clientsays_widget strong {
// 	font-weight: 600;
// 	color: #454545;
// }

// .clientsays_widget h3 {
// 	margin-bottom: 18px;
// }

// .clientsays_widget img {
// 	float: left;
// 	margin-right: 13px;
// 	margin-top: 7px;
// }



// .login_form {
// 	width: 35%;
// 	margin: 0 auto;
// }

// .regist_form {
// 	width: 45%;
// 	margin: 0 auto;
// }

// /* portfolio single image */
// .portfolio_area {
// 	padding: 0px;
// 	margin: 0px;
// 	width: 100%;
// 	float: left;
// }

// .portfolio_area_left {
// 	padding: 0px;
// 	margin: 0px;
// 	width: 74%;
// 	float: left;
// }

// .portfolio_area_left img {
// 	padding: 0px;
// 	margin: 0px;
// 	width: 100%;
// 	float: left;
// 	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
// }

// .portfolio_area_right {
// 	padding: 0px;
// 	margin: 0px 0px 0px 4%;
// 	width: 22%;
// 	float: left;
// }

// .project_details {
// 	padding: 0px;
// 	margin: 80px 0px 0px 0px;
// }

// .project_details span {
// 	padding: 10px 0px;
// 	margin: 0px 0px 0px 0px;
// 	font-size: 14px;
// 	font-weight: bold;
// 	color: #454545;
// 	float: left;
// 	width: 100%;
// 	border-bottom: 1px solid #e3e3e3;
// }

// .project_details strong {
// 	padding: 0px 0px;
// 	margin: 0px 0px 0px 0px;
// 	font-size: 13px;
// 	font-weight: bold;
// 	color: #727272;
// 	float: left;
// 	width: 40%;
// }

// .project_details em {
// 	padding: 0px 0px;
// 	margin: 0px 0px 0px 0px;
// 	font-size: 13px;
// 	font-weight: normal;
// 	font-style: normal;
// 	color: #727272;
// 	float: left;
// 	width: 60%;
// }

// .project_details span a {
// 	padding: 3px 10px;
// 	margin: 0px 0px 0px 3px;
// 	font-size: 11px;
// 	line-height: 30px;
// 	font-weight: normal;
// 	font-style: normal;
// 	color: #727272;
// 	background-color: #e3e3e3;
// 	border-radius: 3px;
// }

// .project_details span a:hover {
// 	background-color: #ddd;
// }

// .addto_favorites {
// 	padding: 3px 10px 5px 10px;
// 	margin: 27px 0px 0px 0px;
// 	width: 142px;
// 	display: block;
// 	border-radius: 3px;
// 	font-size: 13px;
// 	color: #999;
// 	background: #eee;
// }

// .addto_favorites:hover {
// 	background: #e3e3e3;
// }

// .addto_favorites i {
// 	color: #c3c3c3;
// }

// ul.small_social_links {
// 	padding: 0px;
// 	margin: 18px 0 25px 0px;
// }

// .small_social_links li {
// 	padding: 0px;
// 	margin: 0px 5px 0px 0px;
// 	float: left;
// }

// .small_social_links li i {
// 	font-size: 11px;
// 	color: $primary;
// 	border: 1px solid #eee;
// 	width: 25px;
// 	height: 25px;
// 	border-radius: 100%;
// 	text-align: center;
// 	vertical-align: middle;
// 	line-height: 23px;
// }

// .small_social_links li a {
// 	opacity: 1;
// }

// .small_social_links li a:hover {
// 	opacity: 0.7;
// }


// /* blog */
// .blog_post {
// 	float: left;
// 	width: 100%;
// }

// .blog_post h3 {
// 	text-align: left;
// 	color: #333;
// 	line-height: 30px;
// 	display: block;
// }

// .blog_post h3 a {
// 	color: $primary;
// 	text-decoration: none;
// 	margin-top: 3px;
// 	font-weight: 700;
// 	font-size: 27px;
// 	text-transform: uppercase;
// }

// .blog_post h3 a:hover {
// 	color: #383634;
// }

// ul.post_meta_links {
// 	float: left;
// 	margin: -5px 0px 0px 0px;
// 	padding: 0px 0px 0px 0px;
// }

// .post_meta_links li {
// 	float: left;
// 	margin: 0px;
// 	padding: 0px 0px 0px 4px;
// 	list-style-type: none;
// 	color: #999;
// }

// .post_meta_links li a {
// 	color: #999;
// 	padding: 0px 18px 0px 0px;
// 	text-decoration: none;
// 	font-size: 11px;
// 	text-transform: uppercase;
// }

// .post_meta_links li a:hover {
// 	color: #727272;
// 	text-decoration: none;
// }

// .post_meta_links li i {
// 	color: #bbb;
// 	font-size: 12px;
// 	font-style: normal;
// }

// .blog_postcontent {
// 	float: left;
// 	width: 100%;
// }

// .blog_postcontent .image_frame {
// 	float: left;
// 	width: 100%;
// 	margin-bottom: 27px;
// }

// .blog_postcontent .image_frame.mone {
// 	float: left;
// 	width: 100%;
// 	height: 280px;
// 	overflow: hidden;
// }

// .blog_postcontent .image_frame.mtwo {
// 	float: left;
// 	width: 100%;
// 	height: 180px;
// 	overflow: hidden;
// }

// .blog_postcontent .image_frame.mthree {
// 	float: left;
// 	width: 100%;
// 	height: 480px;
// 	overflow: hidden;
// }

// .blog_postcontent .image_frame.mone img, .blog_postcontent .image_frame.mtwo img, .blog_postcontent .image_frame.mthree img {
// 	float: left;
// 	width: auto;
// 	margin-left: -45%;
// 	margin-top: -10%;
// }


// .blog_postcontent .video_frame.mtwo iframe {
// 	float: left;
// 	width: 100%;
// 	height: 280px;
// 	overflow: hidden;
// }


// .blog_postcontent .image_frame.small {
// 	float: left;
// 	width: 43%;
// 	border: 7px solid #fff;
// 	margin-bottom: 25px;
// 	-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
// 	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
// }

// .blog_postcontent .image_frame.small:hover {
// 	border: 7px solid #f9f9f9;
// }

// .blog_postcontent .image_frame img {
// 	float: left;
// 	width: 100%;
// }

// .blog_postcontent .video_frame.small iframe {
// 	height: 250px;
// }

// .blog_postcontent .video_frame iframe {
// 	float: left;
// 	width: 100%;
// 	height: 520px;
// 	margin-bottom: 27px;
// }

// .post_info_content_small {
// 	padding: 0px;
// 	margin: 0 0 0 4%;
// 	width: 50%;
// 	float: left;
// }

// ul.post_meta_links_small {
// 	float: left;
// 	margin: -15px 0px 12px 80px;
// 	padding: 0px 0px 0px 0px;
// 	width: 80%;
// }

// .post_meta_links_small li a {
// 	color: #a1a1a1;
// 	padding: 0px 5px 0px 0px;
// 	text-decoration: none;
// 	font-size: 11px;
// }

// .post_meta_links_small li a:hover {
// 	color: #727272;
// 	padding: 0px 5px 0px 0px;
// 	text-decoration: none;
// 	font-size: 11px;
// }

// .blog_post a.date {
// 	color: #454545;
// 	font-size: 16px;
// 	font-weight: normal;
// }

// .blog_post a.date:hover {
// 	color: $primary;
// }

// .post_readmore:link, .post_readmore:visited {
// 	float: right;
// 	height: 27px;
// 	width: 185px;
// 	text-align: center;
// 	padding-top: 7px;
// 	color: #fff;
// 	text-decoration: none;
// 	background: url(../../images/.DEMO/skin01/but_post_readmore.jpg) no-repeat left top;
// }

// .post_readmore:hover {
// 	float: right;
// 	height: 27px;
// 	width: 185px;
// 	text-align: center;
// 	padding-top: 7px;
// 	color: #fff;
// 	text-decoration: none;
// 	background: url(../../images/.DEMO/skin01/but_post_readmore_hover.jpg) no-repeat left top;
// }

// .sharepost {
// 	float: left;
// 	width: 80%;
// 	margin-bottom: 40px;
// }

// .sharepost ul {
// 	margin: 0px;
// 	padding: 0px;
// }

// .sharepost li {
// 	margin: 0px 7px 5px 0px;
// 	padding: 2px 5px;
// 	float: left;
// 	border-radius: 2px;
// 	list-style-type: none;
// 	background-color: $primary;
// 	transition: all 0.3s ease;
// }

// .sharepost li i {
// 	color: #fff;
// }

// .sharepost li:hover {
// 	background-color: #383634;
// }

// .about_author {
// 	float: left;
// 	width: 100%;
// 	padding: 20px;
// 	background-color: #f9f9f9;
// 	border: 1px solid #eee;
// }

// .about_author img {
// 	float: left;
// 	margin-right: 20px;
// }

// .about_author a {
// 	font-family: 'Open Sans' !important;
// 	font-weight: normal;
// 	font-weight: 600;
// 	color: #333;
// }

// .about_author a:hover {
// 	color: $primary;
// }


// /* pagination */
// .pagination.center {
// 	text-align: center;
// }

// .pagination.center b {
// 	float: inherit;
// }

// .pagination {
// 	clear: both;
// 	padding: 10px 0;
// 	margin-bottom: 20px;
// 	font-family: 'Open Sans', sans-serif;
// }

// .pagination b {
// 	float: left;
// 	margin: 0px 5px 2px 0;
// 	padding: 0px 10px;
// 	font-weight: normal;
// 	color: #999;
// }

// .pagination a.navlinks {
// 	font-size: 12px;
// 	color: #454545;
// 	background-color: #eee;
// 	font-weight: 600;
// 	text-transform: uppercase;
// 	padding: 7px 10px;
// 	border-radius: 3px;
// }

// .pagination a.navlinks:hover {
// 	color: #fff;
// 	background-color: #454545;
// }

// .pagination a.current {
// 	color: #fff;
// 	background-color: #454545;
// }


// /* comments */
// .comment_wrap {
// 	padding: 20px 20px 20px 0px;
// 	overflow: hidden;
// 	border-bottom: 1px solid #eee;
// }

// .comment_wrap.chaild {
// 	border-bottom: 1px solid #eee;
// 	margin: 10px 20px 10px 50px;
// 	overflow: hidden;
// }

// .gravatar {
// 	float: left;
// }

// .comment_content {
// 	overflow: hidden;
// 	padding-left: 20px;
// 	position: relative;
// 	min-height: 80px;
// }

// .comment_content .comment_meta {
// 	position: relative;
// }

// .comment_content .comment_meta .comment_author {
// 	display: inline;
// 	font-size: 14px;
// 	font-style: normal;
// }

// .comment_content .comment_meta .comment_author i {
// 	display: inline;
// 	font-size: 11px;
// 	font-style: italic;
// }

// .comment_content .comment_text {
// 	clear: both;
// 	padding-top: 0px;
// 	overflow: hidden;
// }

// .comment_content .comment_text a {
// 	color: #d3d3d3;
// 	margin-top: 5px;
// 	float: left;
// }

// .comment_content .comment_text a:hover {
// 	color: #727272;
// 	margin-top: 5px;
// 	float: left;
// }

// #comments_section li ul li {
// 	margin-left: 30px;
// }

// /* comment form */
// .comment_form {
// 	float: left;
// 	width: 100%;
// 	margin-top: 30px;
// }

// .comment_form label {
// 	float: left;
// 	width: 90px;
// 	height: 15px;
// 	padding: 7px 10px;
// 	margin-bottom: 40px;
// 	font-weight: normal;
// }

// .comment_input_bg {
// 	float: left;
// 	width: 75%;
// 	height: 40px;
// 	padding: 7px 10px 0px 10px;
// 	border-top: 2px solid #e3e3e3;
// 	border-left: 2px solid #e3e3e3;
// 	border-right: 1px solid #e3e3e3;
// 	border-bottom: 1px solid #e3e3e3;
// 	-webkit-border-radius: 5px;
// 	-moz-border-radius: 5px;
// 	border-radius: 5px;
// 	background-color: #f9f9f9;
// }

// .comment_textarea_bg {
// 	float: left;
// 	width: 90%;
// 	height: 235px;
// 	padding: 7px 10px 0px 10px;
// 	margin-bottom: 20px;
// 	border-top: 2px solid #e3e3e3;
// 	border-left: 2px solid #e3e3e3;
// 	border-right: 1px solid #e3e3e3;
// 	border-bottom: 1px solid #e3e3e3;
// 	-webkit-border-radius: 5px;
// 	-moz-border-radius: 5px;
// 	border-radius: 5px;
// 	background-color: #f9f9f9;
// }

// .comment_checkbox {
// 	float: left;
// 	display: block;
// 	width: 90%;
// 	margin-top: 10px;
// }

// .comment_submit {
// 	background-color: #eee;
// 	border: 1px solid #ddd;
// 	padding: 9px 13px;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: 600;
// 	border-radius: 4px;
// 	color: #333;
// 	text-transform: uppercase;
// }

// .comment_submit:hover {
// 	background-color: #f9f9f9;
// 	cursor: pointer;
// }

// /* comments */
// .comment_wrap {
// 	padding: 20px 20px 20px 0px;
// 	overflow: hidden;
// 	border-bottom: 1px solid #eee;
// }

// .comment_wrap.chaild {
// 	border-bottom: 1px solid #eee;
// 	margin: 10px 20px 10px 50px;
// 	overflow: hidden;
// }

// .gravatar {
// 	float: left;
// }

// .comment_content {
// 	overflow: hidden;
// 	padding-left: 20px;
// 	position: relative;
// 	min-height: 80px;
// }

// .comment_content .comment_meta {
// 	position: relative;
// }

// .comment_content .comment_meta .comment_author {
// 	display: inline;
// 	font-size: 14px;
// 	font-style: normal;
// }

// .comment_content .comment_meta .comment_author i {
// 	display: inline;
// 	font-size: 11px;
// 	font-style: italic;
// }

// .comment_content .comment_text {
// 	clear: both;
// 	padding-top: 0px;
// 	overflow: hidden;
// }

// .comment_content .comment_text a {
// 	color: #d3d3d3;
// 	margin-top: 5px;
// 	float: left;
// }

// .comment_content .comment_text a:hover {
// 	color: #727272;
// 	margin-top: 5px;
// 	float: left;
// }

// #comments_section li ul li {
// 	margin-left: 30px;
// }

// /* Advertisements */
// ul.adsbanner-list {
// 	margin: 0px 0px 0px 0px;
// 	padding: 0px;
// 	float: left;
// 	width: 100%;
// }

// .adsbanner-list li {
// 	margin: 0px 10px 10px 0px;
// 	padding: 0px;
// 	float: left;
// 	width: 140px;
// 	height: 140px;
// 	list-style-type: none;
// }

// .adsbanner-list li img {
// 	margin: 0px;
// 	padding: 0px;
// 	float: left;
// 	width: 140px;
// 	height: 140px;
// 	list-style-type: none;
// }

// .adsbanner-list li.last {
// 	margin: 0px 0px 0px 0px;
// 	padding: 0px;
// 	float: left;
// 	width: 140px;
// 	height: 140px;
// 	list-style-type: none;
// }

// .address_info {
// 	float: left;
// 	background-color: #fff;
// 	border: 1px solid #eee;
// 	padding: 25px 30px 18px 30px;
// 	width: 100%;
// 	margin-bottom: 40px;
// }

// .address_info.two {
// 	background-color: #fff;
// 	border: none;
// 	padding-top: 0px;
// }

// .address_info h3 i {
// 	font-weight: 500;
// 	font-style: normal;
// }

// .google-map {
// 	width: 99%;
// 	height: 350px;
// 	border: 5px solid #fff;
// 	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
// 	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
// }

// .google-map2 {
// 	width: 100%;
// 	height: 450px;
// }

// .google-map3 {
// 	width: 100%;
// 	height: 300px;
// 	border: 10px solid #fff;
// 	-webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
// 	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
// }

// .google-map4 {
// 	width: 100%;
// 	height: 200px;
// 	border: 5px solid #fff;
// 	-webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
// 	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
// }


// /* contact form */
// .cforms label {
// 	float: left;
// 	width: 100%;
// 	color: #454545;
// 	font-weight: normal;
// }

// .cforms label em {
// 	color: $primary;
// 	font-size: 12px;
// 	font-style: normal;
// }

// .cforms input {
// 	color: #454545;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: 1px solid #eee;
// 	background: #f9f9f9;
// 	-webkit-box-shadow: inset 1px 0px 2px 0px rgba(238, 238, 238, 1);
// 	-moz-box-shadow: inset 1px 0px 2px 0px rgba(238, 238, 238, 1);
// 	box-shadow: inset 0px 1px 2px 0px rgba(238, 238, 238, 1);
// }

// .cforms textarea {
// 	color: #454545;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: 1px solid #eee;
// 	background: #f9f9f9;
// 	-webkit-box-shadow: inset 1px 0px 2px 0px rgba(238, 238, 238, 1);
// 	-moz-box-shadow: inset 1px 0px 2px 0px rgba(238, 238, 238, 1);
// 	box-shadow: inset 0px 1px 2px 0px rgba(238, 238, 238, 1);
// }

// .cforms .button {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	padding: 14px 20px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: $primary;
// 	border: 1px solid #7ea821;
// 	border-radius: 3px;
// 	font-family: 'Raleway', sans-serif;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms .button:hover {
// 	background: #454545;
// 	border: 1px solid #393939;
// }

// .cforms .button2 {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	padding: 14px 20px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: #d2527f;
// 	border: 1px solid #d2527f;
// 	border-radius: 3px;
// 	font-family: 'Raleway', sans-serif;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms .button2:hover {
// 	background: #727272;
// 	border: 1px solid #393939;
// }

// .cforms .button3 {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	padding: 18px 30px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: #db6364;
// 	border: 1px solid #db6364;
// 	border-radius: 3px;
// 	font-family: 'Raleway', sans-serif;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms .button3:hover {
// 	background: #454545;
// 	border: 1px solid #454545;
// }


// #form_status span {
// 	color: #fff;
// 	font-size: 14px;
// 	font-weight: normal;
// 	background: #E74C3C;
// 	width: 100%;
// 	text-align: center;
// 	display: inline-block;
// 	padding: 10px 0px;
// 	border-radius: 3px;
// 	margin-bottom: 18px;
// }

// #form_status span.loading {
// 	color: #333;
// 	background: #eee;
// 	border-radius: 3px;
// 	padding: 18px 0px;
// }

// #form_status span.notice {
// 	color: yellow;
// }

// #form_status .success {
// 	color: #fff;
// 	text-align: center;
// 	background: $primary;
// 	border-radius: 3px;
// 	padding: 30px 0px;
// }

// #form_status .success i {
// 	color: #fff;
// 	font-size: 45px;
// 	margin-bottom: 14px;
// }

// #form_status .success h3 {
// 	color: #fff;
// 	margin-bottom: 10px;
// }

// .cforms.two label {
// 	float: left;
// 	width: 100%;
// 	color: #fff;
// 	font-weight: normal;
// }

// .cforms.two label em {
// 	color: #999;
// 	font-size: 12px;
// 	font-style: normal;
// }

// .cforms.two input {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: none;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.two textarea {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: none;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.two .button {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	padding: 14px 20px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: $primary;
// 	border: 1px solid $primary;
// 	border-radius: 3px;
// 	font-family: 'Raleway', sans-serif;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms.two .button:hover {
// 	background: #999;
// 	border: 1px solid #999;
// }


// .cforms.three label {
// 	float: left;
// 	width: 100%;
// 	color: #fff;
// 	font-size: 14px;
// 	margin-bottom: 0px;
// 	font-weight: normal;
// }

// .cforms.three label em {
// 	color: #fff;
// 	font-size: 12px;
// 	font-style: normal;
// }

// .cforms.three input {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: #44c7af;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.three textarea {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: #44c7af;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.three .button {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	width: 100%;
// 	padding: 12px 0px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: #393939;
// 	border: 1px solid #393939;
// 	border-radius: 0px;
// 	font-family: 'Raleway', sans-serif;
// 	text-transform: uppercase;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms.three .button:hover {
// 	background: #575757;
// 	border: 1px solid #575757;
// }


// .cforms.four label {
// 	float: left;
// 	width: 100%;
// 	color: #fff;
// 	font-size: 14px;
// 	margin-bottom: 0px;
// 	font-weight: normal;
// 	margin: 0;
// 	padding-left: 0px;
// }

// .cforms.four label.lst {
// 	text-align: left;
// }

// .cforms.four label em {
// 	color: #fff;
// 	font-size: 12px;
// 	font-style: normal;
// }

// .cforms.four input {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: none;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.four textarea {
// 	color: #fff;
// 	float: left;
// 	width: 100%;
// 	padding: 9px 12px;
// 	margin-bottom: 10px;
// 	border: none;
// 	background: none;
// 	-webkit-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	-moz-box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// 	box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, 1);
// }

// .cforms.four .button {
// 	float: left;
// 	margin-top: 10px;
// 	color: #fff;
// 	width: 100%;
// 	padding: 12px 0px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	background: #6f6f75;
// 	border: 1px solid #6f6f75;
// 	border-radius: 0px;
// 	font-family: 'Raleway', sans-serif;
// 	text-transform: uppercase;
// 	cursor: pointer;
// 	transition: all 0.3s ease;
// }

// .cforms.four .button:hover {
// 	background: #919195;
// 	border: 1px solid #919195;
// }


// .logregform {
// 	float: left;
// 	width: 35%;
// 	margin-left: 32.5%;
// 	background: #fff;
// 	border: 1px solid #f9f9f9;
// 	-webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
// 	-moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
// 	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
// }

// .logregform.two {
// 	float: left;
// 	width: 50%;
// 	margin-left: 25%;
// }

// .logregform .title {
// 	float: left;
// 	width: 100%;
// 	background: #f3f3f3;
// 	text-align: center;
// 	padding: 23px 0px 21px 0px;
// }

// .logregform .title h3 {
// 	margin-bottom: 3px;
// 	font-weight: 700;
// 	text-transform: uppercase;
// }

// .logregform .title p {
// 	font-size: 12px;
// }

// .logregform .feildcont {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 30px 40px 30px;
// 	text-align: left;
// }

// .logregform .feildcont label {
// 	float: left;
// 	width: 100%;
// 	font-weight: normal;
// 	color: #727272;
// }

// .logregform .feildcont label em {
// 	color: $primary;
// 	font-style: normal;
// }

// .logregform .feildcont input {
// 	float: left;
// 	width: 100%;
// 	padding: 7px 12px;
// 	border: 1px solid #e3e3e3;
// 	margin-bottom: 12px;
// }

// .logregform.two .feildcont input {
// 	margin-bottom: 18px;
// }

// .logregform .feildcont .checkbox {
// 	float: left;
// 	width: 100%;
// }

// .logregform .feildcont .checkbox label {
// 	float: left;
// 	width: auto;
// 	font-size: 13px;
// }

// .logregform .feildcont .checkbox label strong {
// 	font-weight: 600;
// }

// .logregform .feildcont .checkbox label a {
// 	margin-left: 10px;
// }

// .logregform .feildcont .fbut {
// 	float: left;
// 	background: $primary;
// 	padding: 13px 23px;
// 	border: none;
// 	border-radius: 3px;
// 	color: #fff;
// 	font-size: 13px;
// 	font-weight: bold;
// 	text-transform: uppercase;
// 	font-family: 'Raleway', sans-serif;
// 	transition: all 0.3s ease;
// 	margin-top: 18px;
// }

// .logregform .feildcont .fbut:hover {
// 	background: #393939;
// }

// .logregform .feildcont .radiobut input {
// 	float: left;
// 	width: auto;
// }

// .logregform .feildcont .radiobut .onelb {
// 	float: left;
// 	color: #aaa;
// 	margin: 0px 12px 0px 5px;
// }

// .logregform .feildcont select {
// 	float: left;
// 	width: 100%;
// 	border: 1px solid #e3e3e3;
// 	padding: 9px 5px 10px 5px;
// 	color: #999;
// 	text-align: left;
// }


// .mgmenucols {
// 	float: left;
// 	width: 100%;
// }

// .mgmenucols .one_fourth {
// 	width: 23.5%;
// 	margin-right: 2%;
// 	padding-right: 2%;
// 	border-right: 1px solid #f3f3f3;
// 	text-align: center;
// }

// .mgmenucols .one_fourth.last {
// 	margin-right: 0;
// 	border-right: none;
// }


// .bordbox {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 40px;
// 	border: 2px solid #f3f3f3;
// 	text-align: left;
// 	border-radius: 3px;
// }

// .videofre1 {
// 	float: left;
// 	width: 100%;
// 	height: 350px;
// }





// /* Responsive CSS Styles Table of Contents */

// @include media-breakpoint-only(lg)
// @media only screen and (min-width: 1000px) and (max-width: 1169px) {
// 	.container {
// 		width: 1000px;
// 		margin: auto;
// 	}

// 	.feature_section2 .twoboxes i {
// 		margin-right: 20px;
// 		font-size: 45px;
// 		margin-top: 14px;
// 	}

// 	.feature_section2 .twoboxes strong {
// 		font-size: 35px;
// 		line-height: 30px;
// 	}

// 	.feature_section2 .twoboxes strong em {
// 		color: #fff;
// 		font-size: 14px;
// 	}

// 	.feature_section3 .lstblogs .date {
// 		margin: 20px 20px 20px 20px;
// 	}

// 	.feature_section3 .lstblogs h4 {
// 		margin: 20px 20px 20px 0px;
// 	}

// 	.feature_section5 li {
// 		width: 22%;
// 	}


// 	.feature_section13 .one_half_less img {
// 		width: 100%;
// 	}

// 	.video_full_site {
// 		height: 380px;
// 	}

// 	.feature_section17 .one_third {
// 		padding: 35px 40px 45px 40px;
// 	}

// 	.feature_section17 .one_third a.but1 {
// 		padding: 13px 20px;
// 	}





// 	.client_logos ul {
// 		margin: 0 0 0 0%;
// 	}

// 	.client_logos li {
// 		padding: 0px 3px;
// 	}

// 	.page_title1.sty7 .serch_area {
// 		width: 79%;
// 	}

// 	.page_title1.sty7 .serch_area .enter_email_input {
// 		width: 72%;
// 	}







// }

// @include media-breakpoint-only(md)
// @media only screen and (min-width: 768px) and (max-width: 999px) {
// 	.container {
// 		width: 747px;
// 		margin: auto;
// 	}



// 	.feature_section2 .twoboxes i {
// 		margin-right: 10px;
// 		font-size: 35px;
// 		margin-top: 12px;
// 	}

// 	.feature_section2 .twoboxes strong {
// 		font-size: 27px;
// 		line-height: 25px;
// 		margin-bottom: 25px;
// 	}

// 	.feature_section2 .twoboxes strong em {
// 		color: #fff;
// 		font-size: 13px;
// 	}

// 	.feature_section3 .lstblogs .date {
// 		margin: 10px 10px 10px 10px;
// 	}

// 	.feature_section3 .lstblogs h4 {
// 		margin: 10px 10px 10px 0px;
// 	}

// 	.feature_section5 li {
// 		width: 30%;
// 	}

// 	.feature_section6 .climg img {
// 		width: 20%;
// 	}

// 	.page_title1.sty7 .serch_area {
// 		width: 90%;
// 	}

// 	.page_title1.sty7 .serch_area .enter_email_input {
// 		width: 68%;
// 	}

// 	.feature_section13 .one_half_less img {
// 		width: 100%;
// 	}

// 	.video_full_site {
// 		height: 300px;
// 	}

// 	.feature_section17 .one_third {
// 		padding: 25px 20px 45px 20px;
// 	}

// 	.feature_section17 .one_third a.but1 {
// 		padding: 13px 10px;
// 	}



// }


// @include media-breakpoint-only(sm)
// @media only screen and (min-width: 640px) and (max-width: 767px) {

// 	/* columns */
// 	.one_half, 	.one_third, 	.one_fourth, 	.two_third, 	.three_fourth, 	.one_fifth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.two_third_less, 	.three_fourth_less, 	.one_fifth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		position: relative;
// 		margin-right: 0%;
// 		float: left;
// 	}

// 	.one_full, 	.one_half, 	.one_third, 	.one_fourth, 	.one_fifth, 	.two_third, 	.three_fourth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_full_less, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.one_fifth_less, 	.two_third_less, 	.three_fourth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.last {
// 		margin-right: 0 !important;
// 		margin-bottom: 0 !important;
// 	}


// 	.container {
// 		width: 590px;
// 		margin: auto;
// 	}

// 	.feature_section2 .twoboxes:before {
// 		right: 0%;
// 	}

// 	.feature_section2 .twoboxes .left {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.feature_section2 .twoboxes .right {
// 		float: left;
// 		width: 100%;
// 	}

// 	.feature_section2 .twoboxes strong {
// 		margin-bottom: 25px;
// 	}


// 	.feature_section4 .left, 	.feature_section4 .center, 	.feature_section4 .right {
// 		width: 100%;
// 	}

// 	.feature_section5 li {
// 		width: 22%;
// 	}

// 	.feature_section5 .two_third {
// 		padding-top: 0px;
// 	}

// 	.feature_section6 .climg img {
// 		width: 25%;
// 	}

// 	.page_title1.sty7 .serch_area {
// 		width: 95%;
// 	}

// 	.page_title1.sty7 .serch_area .enter_email_input {
// 		width: 64%;
// 	}

// 	.feature_section13 .one_half_less img {
// 		width: 100%;
// 	}

// 	.video_full_site {
// 		height: 250px;
// 	}

// }




// // @include media-breakpoint-between(sm, md) {
// @media only screen and (min-width: 480px) and (max-width: 639px) {

// 	/* columns */
// 	.one_half, 	.one_third, 	.one_fourth, 	.two_third, 	.three_fourth, 	.one_fifth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.two_third_less, 	.three_fourth_less, 	.one_fifth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		position: relative;
// 		margin-right: 0%;
// 		float: left;
// 	}

// 	.one_full, 	.one_half, 	.one_third, 	.one_fourth, 	.one_fifth, 	.two_third, 	.three_fourth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_full_less, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.one_fifth_less, 	.two_third_less, 	.three_fourth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.last {
// 		margin-right: 0 !important;
// 		margin-bottom: 0 !important;
// 	}

// 	.container {
// 		width: 440px;
// 		margin: auto;
// 	}

// 	.slidermar {
// 		margin-top: 105px;
// 	}

// 	.feature_section2 .twoboxes:before {
// 		right: 0%;
// 	}

// 	.feature_section2 .twoboxes .left {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.feature_section2 .twoboxes .right {
// 		float: left;
// 		width: 100%;
// 	}

// 	.feature_section2 .twoboxes i {
// 		margin-right: 20px;
// 		font-size: 45px;
// 		margin-top: 14px;
// 	}

// 	.feature_section2 .twoboxes strong {
// 		font-size: 35px;
// 		line-height: 30px;
// 		margin-bottom: 25px;
// 	}

// 	.feature_section2 .twoboxes strong em {
// 		color: #fff;
// 		font-size: 14px;
// 	}

// 	.feature_section3 .lstblogs .date {
// 		margin: 20px 20px 20px 20px;
// 	}

// 	.feature_section3 .lstblogs h4 {
// 		margin: 20px 20px 20px 0px;
// 	}

// 	.feature_section4 .left, 	.feature_section4 .center, 	.feature_section4 .right {
// 		width: 100%;
// 	}

// 	.feature_section5 li {
// 		width: 30%;
// 	}

// 	.feature_section5 .two_third {
// 		padding-top: 0px;
// 	}

// 	.feature_section6 .climg {
// 		background-position: center 34px;
// 	}

// 	.feature_section6 .climg img {
// 		width: 25%;
// 	}

// 	.page_title1 {
// 		margin-top: 105px;
// 	}

// 	.page_title1.sty7 .serch_area {
// 		width: 90%;
// 	}

// 	.page_title1.sty7 .serch_area .enter_email_input {
// 		width: 100%;
// 	}

// 	.page_title1.sty7 .serch_area .input_submit {
// 		margin-left: 0px;
// 		margin-top: 12px;
// 	}

// 	.feature_section11 ul.domlist1 {
// 		width: 33%;
// 	}

// 	.feature_section11 .tabs>li {
// 		font-size: 14px;
// 		line-height: 16px;
// 	}


// 	.feature_section13 .one_half_less img {
// 		width: 100%;
// 	}

// 	.video_full_site {
// 		width: 90%;
// 		height: 230px;
// 	}








// }





// @media only screen and (max-width: 479px) {

// 	/* columns */
// 	.one_half, 	.one_third, 	.one_fourth, 	.two_third, 	.three_fourth, 	.one_fifth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.two_third_less, 	.three_fourth_less, 	.one_fifth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		position: relative;
// 		margin-right: 0%;
// 		float: left;
// 	}

// 	.one_full, 	.one_half, 	.one_third, 	.one_fourth, 	.one_fifth, 	.two_third, 	.three_fourth, 	.onecol_forty, 	.onecol_sixty, 	.onecol_thirtyfive, 	.one_full_less, 	.one_half_less, 	.one_third_less, 	.one_fourth_less, 	.one_fifth_less, 	.two_third_less, 	.three_fourth_less, 	.onecol_forty_less, 	.onecol_sixty_less, 	.onecol_thirtyfive_less {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.last {
// 		margin-right: 0 !important;
// 		margin-bottom: 0 !important;
// 	}

// 	.container {
// 		width: 300px;
// 		margin: auto;
// 	}

// 	.slidermar {
// 		margin-top: 102px;
// 	}

// 	.feature_section2 .twoboxes:before {
// 		right: 0%;
// 	}

// 	.feature_section2 .twoboxes .left {
// 		width: 100%;
// 		margin-bottom: 50px;
// 	}

// 	.feature_section2 .twoboxes .right {
// 		float: left;
// 		width: 100%;
// 	}

// 	.feature_section2 .twoboxes i {
// 		margin-right: 10px;
// 		font-size: 27px;
// 		margin-top: 12px;
// 	}

// 	.feature_section2 .twoboxes strong {
// 		font-size: 27px;
// 		line-height: 25px;
// 		margin-bottom: 25px;
// 	}

// 	.feature_section2 .twoboxes strong em {
// 		color: #fff;
// 		font-size: 12px;
// 	}

// 	.feature_section3 .lstblogs .date {
// 		margin: 10px 10px 10px 10px;
// 		width: 14%;
// 	}

// 	.feature_section3 .lstblogs h4 {
// 		margin: 10px 10px 10px 0px;
// 		width: 72%;
// 	}

// 	.feature_section4 .left, 	.feature_section4 .center, 	.feature_section4 .right {
// 		width: 100%;
// 		padding: 45px 20px 56px 20px;
// 	}


// 	.feature_section5 li {
// 		width: 45%;
// 	}

// 	.feature_section5 .two_third {
// 		padding-top: 0px;
// 	}

// 	.feature_section6 .climg img {
// 		width: 50%;
// 	}


// 	.page_title1 {
// 		margin-top: 102px;
// 	}


// 	.page_title1.sty7 .serch_area {
// 		width: 90%;
// 	}

// 	.page_title1.sty7 .serch_area .enter_email_input {
// 		width: 100%;
// 	}

// 	.page_title1.sty7 .serch_area .input_submit {
// 		margin-left: 0px;
// 		margin-top: 12px;
// 	}

// 	.feature_section11 ul.domlist1 {
// 		width: 50%;
// 	}

// 	.feature_section11 .tabs>li {
// 		font-size: 12px;
// 		line-height: 16px;
// 		padding: 12px 12px 18px 12px;
// 	}

// 	.feature_section13 .one_half_less img {
// 		width: 100%;
// 	}

// 	.video_full_site {
// 		width: 90%;
// 		height: 154px;
// 	}

// 	.feature_section14 .one_half {
// 		text-align: center;
// 		padding: 30px 20px;
// 	}

// 	.feature_section14 .one_half img {
// 		float: none;
// 		margin-right: 0px;
// 		margin-bottom: 10px;
// 	}

// 	.feature_section17 .one_third {
// 		padding: 35px 40px 45px 40px;
// 	}

// 	.feature_section17 .one_third a.but1 {
// 		padding: 13px 20px;
// 	}



// }




// /*----------------------------------------------------
//    SHORTCODE ELEMENTS
// ------------------------------------------------------*/
// .button.one {
// 	color: $dark;
// 	font-size: 14px;
// 	text-transform: uppercase;
// 	background: #fff;
// 	padding: 13px 30px;
// 	border-radius: 3px;
// 	white-space: nowrap;
// 	transition: all 0.3s ease;
// 	font-family: 'Roboto', sans-serif;
// }

// .button.one:hover {
// 	color: #fff;
// 	background: $primary;
// }

// .button.two {
// 	color: $dark;
// 	font-size: 14px;
// 	text-transform: uppercase;
// 	border: 2px solid $dark;
// 	padding: 9px 20px;
// 	border-radius: 3px;
// 	white-space: nowrap;
// 	transition: all 0.3s ease;
// 	font-family: 'Roboto', sans-serif;
// }

// .button.two:hover {
// 	color: #fff;
// 	background: $dark;
// }

// .button.three {
// 	color: $dark;
// 	font-size: 14px;
// 	font-weight: bold;
// 	text-transform: uppercase;
// 	background: #fff;
// 	padding: 10px 25px;
// 	border-radius: 20px;
// 	white-space: nowrap;
// 	transition: all 0.3s ease;
// 	font-family: 'Roboto', sans-serif;
// }

// .button.three:hover {
// 	color: #fff;
// 	background: $primary;
// }

// .button.three.dark {
// 	color: #fff;
// 	background: $dark;
// }

// .button.three.dark:hover {
// 	color: #fff;
// 	background: $primary;
// }




















// /* lists */
// .list_divlines {
// 	padding: 0px;
// 	margin: 0px;
// }

// .list_divlines li {
// 	padding: 10px 0px 12px 0px;
// 	margin: 0px;
// 	line-height: 23px;
// 	border-bottom: 1px solid #3d3d3d;
// }

// .list_divlines li i {
// 	color: #999;
// 	font-size: 11px;
// 	margin-right: 3px;
// }

// .list_divlines2 {
// 	padding: 0px;
// 	margin: 0px;
// }

// .list_divlines2 li {
// 	color: #999;
// 	padding: 10px 0px 11px 0px;
// 	margin: 0px;
// 	line-height: 23px;
// 	border-top: 1px solid #eee;
// }

// .list_divlines2 li.last {
// 	border-bottom: 1px solid #eee;
// }

// .list_divlines2 li i {
// 	color: #ccc;
// 	margin-right: 7px;
// }


// .harrow_line {
// 	float: left;
// 	width: 100%;
// 	height: 20px;
// 	background: url(../../images/.DEMO/h-arrow-line2.png) no-repeat center top;
// }

// .harrow_line2 {
// 	float: left;
// 	width: 100%;
// 	height: 20px;
// 	background: #eee url(../../images/.DEMO/h-arrow-line3.png) no-repeat center top;
// }

// /* pie charts */
// .piechart1 {
// 	float: left;
// 	color: $dark;
// 	width: 20%;
// 	font-size: 16px;
// 	font-weight: normal;
// 	text-align: center;
// }

// .piechart1.white {
// 	color: #fff;
// }



// .piechart2 {
// 	float: left;
// 	width: 200px;
// 	text-align: center;
// 	color: #1e1e1e;
// }

// .piechart2.s1 {
// 	width: 100px;
// }

// .piechart2.s2 {
// 	width: 120px;
// }

// .piechart2.s3 {
// 	width: 160px;
// }

// .piechart2.s4 {
// 	width: 180px;
// }

// .piechart3 {
// 	float: left;
// 	text-align: center;
// 	color: #fff;
// 	font-size: 18px;
// 	font-weight: bold;
// 	width: 25%;
// 	text-align: center;
// }

// /* styled boxes */
// .infomes, .errormes, .noticemes, .successmes {
// 	-webkit-border-radius: 0px;
// 	-moz-border-radius: 0px;
// 	border-radius: 0px;
// 	border: 1px solid #bbb;
// 	font-size: 14px;
// }

// .infomes i, .errormes i, .noticemes i, .successmes i {
// 	margin-right: 7px;
// }

// p.error {
// 	border: none;
// }

// .infomes strong, .errormes strong, .noticemes strong, .successmes strong {
// 	font-size: 16px;
// }

// .message-box-wrap {
// 	border: 0px solid #fff;
// 	padding: 20px 20px;
// }

// .infomes {
// 	color: #3378b9;
// 	border-color: #9ecaf3;
// 	background-color: #dbedfd;
// }

// .errormes {
// 	color: #bd6767;
// 	border-color: #f1bdbd;
// 	background-color: #ffdddd;
// }

// .noticemes {
// 	color: #a79a30;
// 	border-color: #d7d099;
// 	background-color: #f6f2d2;
// }

// .successmes {
// 	color: #1ea133;
// 	border-color: #a9ecb4;
// 	background-color: #dffbe4;
// }

// .close-but {
// 	width: 10px;
// 	height: 10px;
// 	float: right;
// 	border: none;
// 	margin: 8px;
// 	cursor: pointer;
// 	font-size: 0px;
// 	background: url(../../images/.DEMO/elements/icon-remove.png) no-repeat center top;
// }

.alertymes {
	float: left;
	width: 100%;
	padding: 22px;
	border-radius: 5px;
	color: $dark;
	border: 1px solid $red;
	background-color: $red-100;
	-webkit-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
	-moz-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
	box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
}

.alertymes i {
	margin-right: 10px;
}

// .alertymes1 {
// 	float: left;
// 	width: 100%;
// 	padding: 22px;
// 	border-radius: 5px;
// 	color: $dark;
// 	border: 1px solid #eee;
// 	background-color: #f9f9f9;
// 	-webkit-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
// 	-moz-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
// 	box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
// }

// .alertymes1 i {
// 	margin-right: 10px;
// }

// .alertymes2 {
// 	float: left;
// 	width: 100%;
// 	padding: 22px;
// 	color: #fff;
// 	background-color: $primary;
// }

// .alertymes2 i {
// 	margin-right: 10px;
// }

// .alertymes3 {
// 	float: left;
// 	width: 100%;
// 	padding: 22px;
// 	color: #dda614;
// 	border: 5px solid #e3b028;
// 	background-color: #fff;
// }

// .alertymes3 i {
// 	margin-right: 10px;
// }

// .alertymes4 {
// 	float: left;
// 	width: 100%;
// 	padding: 22px;
// 	color: #eb3535;
// 	border: 1px solid #eb3535;
// 	background-color: #fff;
// 	text-align: center;
// }

// .alertymes4 i {
// 	margin-right: 10px;
// }


// /* Buttons */
// a.but_goback, a.but_ok_2, a.but_wifi, a.but_warning_sign, a.but_user, a.but_tag, a.but_table, a.but_star, a.but_search, a.but_phone, a.but_pencil, a.but_new_window, a.but_music, a.but_hand_right, a.but_thumbs_down, a.but_thumbs_up, a.but_globe, a.but_hospital, a.but_coffe_cup, a.but_settings, a.but_chat, a.but_play_button, a.but_remove_2, a.but_lock, a.but_shopping_cart, a.but_exclamation_mark, a.but_info, a.but_question_mark, a.but_minus, a.but_plus, a.but_folder_open, a.but_file, a.but_envelope, a.but_edit, a.but_cogwheel, a.but_check, a.but_camera, a.but_calendar, a.but_bookmark, a.but_book, a.but_download, a.but_pdf, a.but_word_doc, a.but_woman {
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 14px;
// 	color: #fff;
// 	font-weight: 600;
// 	-moz-border-radius: 3px;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// }

// a.but_goback i {
// 	color: #fff;
// }

// a.but_goback {
// 	padding: 13px 25px 13px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: $primary;
// 	border-bottom: 1px solid #999;
// }

// a.but_goback:hover {
// 	color: #fff;
// 	background-color: #383634;
// }

// a.but_ok_2 {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_ok_2:hover {
// 	background-color: #ff9900;
// }

// a.but_wifi {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #0099cc;
// 	border-bottom: 1px solid #909090;
// }

// a.but_wifi:hover {
// 	background-color: #00abde;
// }

// a.but_warning_sign {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #e5cc19;
// 	border-bottom: 1px solid #909090;
// }

// a.but_warning_sign:hover {
// 	background-color: #efda1b;
// }

// a.but_user {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #007f99;
// 	border-bottom: 1px solid #909090;
// }

// a.but_user:hover {
// 	background-color: #0090ad;
// }

// a.but_tag {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #e59919;
// 	border-bottom: 1px solid #909090;
// }

// a.but_tag:hover {
// 	background-color: #efa61b;
// }

// a.but_table {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #4c6699;
// 	border-bottom: 1px solid #909090;
// }

// a.but_table:hover {
// 	background-color: #5674ad;
// }

// a.but_star {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #99cc4c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_star:hover {
// 	background-color: #a6da52;
// }

// a.but_search {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #66667f;
// 	border-bottom: 1px solid #909090;
// }

// a.but_search:hover {
// 	background-color: #747490;
// }

// a.but_phone {
// 	padding: 10px 20px 10px 20px;
// 	margin: 0px 0px 0px 0px;
// 	background: #e56619;
// 	border-bottom: 1px solid #909090;
// }

// a.but_phone:hover {
// 	background-color: #ef6f1b;
// }

// a.but_pencil {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #cc4c19;
// 	border-bottom: 1px solid #909090;
// }

// a.but_pencil:hover {
// 	background-color: #da521b;
// }

// a.but_new_window {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #001900;
// 	border-bottom: 1px solid #909090;
// }

// a.but_new_window:hover {
// 	background-color: $dark;
// }

// a.but_music {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #cccc7f;
// 	border-bottom: 1px solid #909090;
// }

// a.but_music:hover {
// 	background-color: #dada8a;
// }

// a.but_hand_right {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_hand_right:hover {
// 	color: #fff;
// 	background-color: #ff9900;
// }

// a.but_thumbs_down {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #9999cc;
// 	border-bottom: 1px solid #909090;
// }

// a.but_thumbs_down:hover {
// 	background-color: #a6a6da;
// }

// a.but_thumbs_up {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #00cce5;
// 	border-bottom: 1px solid #909090;
// }

// a.but_thumbs_up:hover {
// 	background-color: #00daef;
// }

// a.but_globe {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #669999;
// 	border-bottom: 1px solid #909090;
// }

// a.but_globe:hover {
// 	background-color: #6fa6a6;
// }

// a.but_hospital {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #196666;
// 	border-bottom: 1px solid #909090;
// }

// a.but_hospital:hover {
// 	background-color: #1b6f6f;
// }

// a.but_coffe_cup {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #7f994c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_coffe_cup:hover {
// 	background-color: #8aa652;
// }

// a.but_settings {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #997f4c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_settings:hover {
// 	background-color: #a68a52;
// }

// a.but_chat {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #7f9919;
// 	border-bottom: 1px solid #909090;
// }

// a.but_chat:hover {
// 	background-color: #8aa61b;
// }

// a.but_play_button {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #4c664c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_play_button:hover {
// 	background-color: #567456;
// }

// a.but_remove_2 {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #e59966;
// 	border-bottom: 1px solid #909090;
// }

// a.but_remove_2:hover {
// 	background-color: #efa66f;
// }

// a.but_lock {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #994c19;
// 	border-bottom: 1px solid #909090;
// }

// a.but_lock:hover {
// 	background-color: #a6521b;
// }

// a.but_shopping_cart {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #cc7f4c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_shopping_cart:hover {
// 	background-color: #da8a52;
// }

// a.but_exclamation_mark {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #4c7f7f;
// 	border-bottom: 1px solid #909090;
// }

// a.but_exclamation_mark:hover {
// 	background-color: #528a8a;
// }

// a.but_info {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #667fcc;
// 	border-bottom: 1px solid #909090;
// }

// a.but_info:hover {
// 	background-color: #6f8ada;
// }

// a.but_question_mark {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #4c0019;
// 	border-bottom: 1px solid #909090;
// }

// a.but_question_mark:hover {
// 	background-color: #620020;
// }

// a.but_minus {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #666666;
// 	border-bottom: 1px solid #909090;
// }

// a.but_minus:hover {
// 	background-color: #747474;
// }

// a.but_plus {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: $dark;
// 	border-bottom: 1px solid #909090;
// }

// a.but_plus:hover {
// 	background-color: #444444;
// }

// a.but_folder_open {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #99cc99;
// 	border-bottom: 1px solid #909090;
// }

// a.but_folder_open:hover {
// 	background-color: #a6daa6;
// }

// a.but_file {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #999999;
// 	border-bottom: 1px solid #909090;
// }

// a.but_file:hover {
// 	background-color: #a6a6a6;
// }

// a.but_envelope {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #727272;
// 	border-bottom: 1px solid #909090;
// }

// a.but_envelope:hover {
// 	background-color: #7c7c7c;
// }

// a.but_edit {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #e5cc7f;
// 	border-bottom: 1px solid #909090;
// }

// a.but_edit:hover {
// 	background-color: #efda8a;
// }

// a.but_cogwheel {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #194c7f;
// 	border-bottom: 1px solid #909090;
// }

// a.but_cogwheel:hover {
// 	background-color: #1e5c99;
// }

// a.but_check {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #004c4c;
// 	border-bottom: 1px solid #909090;
// }

// a.but_check:hover {
// 	background-color: #005c5c;
// }

// a.but_camera {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #007fcc;
// 	border-bottom: 1px solid #909090;
// }

// a.but_camera:hover {
// 	background-color: #008ada;
// }

// a.but_calendar {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #4c99e5;
// 	border-bottom: 1px solid #909090;
// }

// a.but_calendar:hover {
// 	background-color: #52a6ef;
// }

// a.but_bookmark {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #990000;
// 	border-bottom: 1px solid #909090;
// }

// a.but_bookmark:hover {
// 	background-color: #b60000;
// }

// a.but_book {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #7f9999;
// 	border-bottom: 1px solid #909090;
// }

// a.but_book:hover {
// 	background-color: #8aa6a6;
// }

// a.but_download {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_download:hover {
// 	background-color: #ff9900;
// }

// a.but_pdf {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_pdf:hover {
// 	background-color: #ff9900;
// }

// a.but_word_doc {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_word_doc:hover {
// 	background-color: #ff9900;
// }

// a.but_woman {
// 	padding: 10px 22px 10px 22px;
// 	margin: 0px 0px 0px 0px;
// 	background: #ff8100;
// 	border-bottom: 1px solid #909090;
// }

// a.but_woman:hover {
// 	background-color: #ff9900;
// }

// /* small buttons */
// .but_small1 {
// 	color: #fff;
// 	padding: 10px 25px;
// 	background-color: $primary;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// }

// .but_small1:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_small1.gray {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_small1.gray:hover {
// 	color: #fff;
// 	background-color: $primary;
// }

// .but_small3 {
// 	display: inline-block;
// 	height: 41px;
// 	line-height: 41px;
// 	padding-right: 20px;
// 	padding-left: 58px;
// 	position: relative;
// 	background-color: $primary;
// 	color: #fff;
// 	border-radius: 4px;
// 	white-space: nowrap;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=1,Color=#ff123852,Positive=true)";
// 	zoom: 1;
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=#ff123852, Positive=true);
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=2,Color=#33000000,Positive=true)";
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=2, Color=#33000000, Positive=true);
// }

// .but_small3 span {
// 	position: absolute;
// 	left: 0;
// 	width: 41px;
// 	background-color: #8db737;
// 	-webkit-border-top-left-radius: 4px;
// 	-webkit-border-bottom-left-radius: 4px;
// 	-moz-border-radius-topleft: 4px;
// 	-moz-border-radius-bottomleft: 4px;
// 	border-top-left-radius: 4px;
// 	border-bottom-left-radius: 4px;
// 	border-right: 1px solid rgba(0, 0, 0, 0.15);
// 	transition: all 0.3s ease;
// }

// .but_small3:hover {
// 	color: #fff;
// }

// .but_small3 span i {
// 	color: #fff;
// 	font-size: 18px;
// 	margin-left: 12px;
// }

// .but_small3:hover span, .but_small3.active span {
// 	color: #fff;
// 	background-color: $dark;
// 	border-right: 1px solid #181818;
// }

// .but_small3.gray {
// 	background-color: #636363;
// }

// .but_small3.gray span {
// 	background-color: #454545;
// }

// .but_small3.gray:hover {
// 	color: #fff;
// }

// .but_small3.gray span i {
// 	color: #fff;
// 	font-size: 18px;
// 	margin-left: 12px;
// }

// .but_small3.gray:hover span, .but_small3.gray.active span {
// 	color: #fff;
// 	background-color: $dark;
// 	border-right: 1px solid #181818;
// }

// .but_small2 {
// 	color: #fff;
// 	padding: 10px 25px;
// 	background-color: $primary;
// 	border-radius: 20px;
// 	transition: all 0.3s ease;
// 	border-bottom: 1px solid $dark;
// 	white-space: nowrap;
// }

// .but_small2:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_small2.nob {
// 	padding: 10px 30px;
// 	border-bottom: 0px solid $dark;
// }

// .but_small4 {
// 	color: #fff;
// 	padding: 10px 25px;
// 	background-color: $primary;
// 	border-radius: 5px;
// 	transition: all 0.3s ease;
// 	border-bottom: 3px solid $dark;
// 	white-space: nowrap;
// }

// .but_small4:hover {
// 	color: #fff;
// 	background-color: $dark;
// 	border-bottom: 0px solid #454545;
// }

// .but_small5 {
// 	color: $primary;
// 	padding: 10px 25px;
// 	background-color: #fff;
// 	border-radius: 2px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	border: 2px solid $primary;
// }

// .but_small5:hover {
// 	color: $dark;
// 	border: 2px solid $dark;
// }

// .but_small5.light {
// 	color: #fff;
// 	background: none;
// 	border: 2px solid #fff;
// }

// .but_small5.light:hover {
// 	color: $primary;
// 	background-color: #fff;
// 	border: 2px solid #fff;
// }

// .but_small5.light2 {
// 	color: #fff;
// 	background: none;
// 	border: 1px solid #fff;
// 	padding: 8px 18px;
// }

// .but_small5.light2:hover {
// 	color: $primary;
// 	background-color: #fff;
// 	border: 1px solid #fff;
// }


// /* medium buttons */
// .but_medium1 {
// 	color: #fff;
// 	padding: 14px 35px;
// 	background-color: $primary;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// }

// .but_medium1:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_medium1.white {
// 	color: #fff;
// 	padding: 14px 35px;
// 	border: 2px solid #fff;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	text-transform: uppercase;
// 	font-weight: bold;
// }

// .but_medium1.white:hover {
// 	color: #272727;
// 	background-color: #fff;
// }

// .but_medium1.white.active {
// 	color: #272727;
// 	background-color: #fff;
// }

// .but_medium3 {
// 	display: inline-block;
// 	height: 48px;
// 	line-height: 48px;
// 	padding-right: 30px;
// 	padding-left: 68px;
// 	position: relative;
// 	background-color: $primary;
// 	color: #fff;
// 	border-radius: 4px;
// 	white-space: nowrap;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=1,Color=#ff123852,Positive=true)";
// 	zoom: 1;
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=#ff123852, Positive=true);
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=2,Color=#33000000,Positive=true)";
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=2, Color=#33000000, Positive=true);
// }

// .but_medium3 span {
// 	position: absolute;
// 	left: 0;
// 	width: 48px;
// 	background-color: #8db737;
// 	-webkit-border-top-left-radius: 4px;
// 	-webkit-border-bottom-left-radius: 4px;
// 	-moz-border-radius-topleft: 4px;
// 	-moz-border-radius-bottomleft: 4px;
// 	border-top-left-radius: 4px;
// 	border-bottom-left-radius: 4px;
// 	border-right: 1px solid rgba(0, 0, 0, 0.15);
// 	transition: all 0.3s ease;
// }

// .but_medium3:hover {
// 	color: #fff;
// }

// .but_medium3 span i {
// 	color: #fff;
// 	font-size: 18px;
// 	margin-left: 16px;
// }

// .but_medium3:hover span, .but_medium3.active span {
// 	color: #fff;
// 	background-color: $dark;
// 	border-right: 1px solid #181818;
// }

// .but_medium2 {
// 	color: #fff;
// 	padding: 14px 35px;
// 	background-color: $primary;
// 	border-radius: 30px;
// 	transition: all 0.3s ease;
// 	border-bottom: 1px solid $dark;
// 	white-space: nowrap;
// }

// .but_medium2:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_medium4 {
// 	color: #fff;
// 	padding: 14px 35px;
// 	background-color: $primary;
// 	border-radius: 5px;
// 	transition: all 0.3s ease;
// 	border-bottom: 3px solid $dark;
// 	white-space: nowrap;
// }

// .but_medium4:hover {
// 	color: #fff;
// 	background-color: $dark;
// 	border-bottom: 0px solid #454545;
// }

// .but_medium5 {
// 	color: $primary;
// 	padding: 12px 35px;
// 	background-color: #fff;
// 	border-radius: 2px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	border: 2px solid $primary;
// }

// .but_medium5:hover {
// 	color: $dark;
// 	border: 2px solid $dark;
// }


// /* large buttons */
// .but_large1 {
// 	color: #fff;
// 	padding: 18px 50px;
// 	background-color: $primary;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// }

// .but_large1:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_large1.two:hover {
// 	color: #fff;
// 	background-color: #727272;
// }


// .but_large1.small {
// 	color: #fff;
// 	padding: 13px 50px;
// 	background-color: $primary;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	text-transform: uppercase;
// 	font-size: 16px;
// 	font-weight: bold;
// }

// .but_large1.small:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_large3 {
// 	display: inline-block;
// 	height: 50px;
// 	line-height: 50px;
// 	padding-right: 35px;
// 	padding-left: 83px;
// 	position: relative;
// 	background-color: $primary;
// 	color: #fff;
// 	border-radius: 4px;
// 	white-space: nowrap;
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=1,Color=#ff123852,Positive=true)";
// 	zoom: 1;
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=1, Color=#ff123852, Positive=true);
// 	-ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=2,Color=#33000000,Positive=true)";
// 	filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=0, OffY=2, Color=#33000000, Positive=true);
// }

// .but_large3 span {
// 	position: absolute;
// 	left: 0;
// 	width: 58px;
// 	background-color: #8db737;
// 	-webkit-border-top-left-radius: 4px;
// 	-webkit-border-bottom-left-radius: 4px;
// 	-moz-border-radius-topleft: 4px;
// 	-moz-border-radius-bottomleft: 4px;
// 	border-top-left-radius: 4px;
// 	border-bottom-left-radius: 4px;
// 	border-right: 1px solid rgba(0, 0, 0, 0.15);
// 	transition: all 0.3s ease;
// }

// .but_large3:hover {
// 	color: #fff;
// }

// .but_large3 span i {
// 	color: #fff;
// 	font-size: 18px;
// 	margin-left: 20px;
// }

// .but_large3:hover span, .but_large3.active span {
// 	color: #fff;
// 	background-color: $dark;
// 	border-right: 1px solid #181818;
// }

// .but_large2 {
// 	color: #fff;
// 	padding: 16px 50px;
// 	background-color: $primary;
// 	border-radius: 30px;
// 	transition: all 0.3s ease;
// 	border-bottom: 1px solid $dark;
// 	white-space: nowrap;
// }

// .but_large2:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_large4 {
// 	color: #fff;
// 	padding: 16px 50px;
// 	background-color: $primary;
// 	border-radius: 5px;
// 	transition: all 0.3s ease;
// 	border-bottom: 3px solid $dark;
// 	white-space: nowrap;
// }

// .but_large4:hover {
// 	color: #fff;
// 	background-color: $dark;
// }

// .but_large4.white {
// 	color: $primary;
// 	background-color: #fff;
// 	border-bottom: 0px solid $dark;
// }

// .but_large4:hover {
// 	color: #fff;
// 	background-color: $dark;
// 	border-bottom: 0px solid #454545;
// }


// .but_large5 {
// 	color: $primary;
// 	padding: 16px 45px;
// 	background-color: #fff;
// 	border-radius: 2px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	border: 2px solid $primary;
// }

// .but_large5:hover {
// 	color: $dark;
// 	border: 2px solid $dark;
// }

// .but_full {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	color: #272727;
// 	padding: 30px 0px;
// 	background-color: #f7f7f7;
// 	border-radius: 4px;
// 	transition: all 0.3s ease;
// 	white-space: nowrap;
// 	border: 1px solid #e3e3e3;
// 	font-size: 22px;
// 	font-weight: 600;
// }

// .but_full:hover {
// 	background-color: #fff;
// }

// .imagede {
// 	-webkit-animation: spin 3s linear infinite;
// 	-moz-animation: spin 3s linear infinite;
// 	animation: spin 3s linear infinite;
// }

// @-moz-keyframes spin {
// 	100% {
// 		-moz-transform: rotate(360deg);
// 	}
// }

// @-webkit-keyframes spin {
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 	}
// }

// @keyframes spin {
// 	100% {
// 		-webkit-transform: rotate(360deg);
// 		transform: rotate(360deg);
// 	}
// }



// .but_full2 {
// 	width: 100%;
// 	height: 70px;
// 	background-color: #f3f3f3;
// 	overflow: hidden;
// 	text-align: center;
// 	color: #272727;
// 	border-radius: 4px;
// 	vertical-align: middle;
// 	line-height: 70px;
// 	font-size: 18px;
// 	font-weight: 600;
// }

// .but_full2 .butprogress {
// 	-webkit-transition: all 0.7s ease;
// 	-moz-transition: all 0.7s ease;
// 	-ms-transition: all 0.7s ease;
// 	-o-transition: all 0.7s ease;
// 	transition: all 0.7s ease;
// 	height: 70px;
// 	width: 10px;
// 	background: $primary;
// 	position: absolute;
// 	overflow: hidden;
// 	border-radius: 4px;
// 	border-top-right-radius: 0px;
// 	border-bottom-right-radius: 0px;
// }

// .but_full2 .done {
// 	float: left;
// 	text-align: center;
// 	line-height: 70px;
// 	font-size: 18px;
// 	font-weight: bold;
// 	color: #EEE;
// 	height: 70px;
// 	width: 100%;
// 	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
// 	cursor: pointer;
// 	vertical-align: middle;
// 	line-height: 70px;
// 	font-weight: 600;
// 	position: relative;
// 	z-index: 98;
// }

// .but_full2 strong {
// 	position: relative;
// 	z-index: 99;
// 	font-weight: normal;
// }

// .but_full2:hover .butprogress {
// 	-webkit-transition: all 0.7s ease;
// 	-moz-transition: all 0.7s ease;
// 	-ms-transition: all 0.7s ease;
// 	-o-transition: all 0.7s ease;
// 	transition: all 0.7s ease;
// 	width: 100%;
// 	border-radius: 4px;
// }

// .but_full2 .butprogress a {
// 	color: #fff;
// }

// .but_full2.two .butprogress {
// 	background: #e3e3e3;
// }





// /* Pricing Tables */
// .pritable {
// 	float: left;
// 	width: 100%;
// 	padding: 120px 0px;
// }

// .pritable.ltp {
// 	padding-top: 80px;
// }

// .pritable .pacdetails {
// 	float: left;
// 	width: 25%;
// 	text-align: center;
// 	border-right: 1px solid #eee;
// 	border-bottom: 1px solid #eee;
// 	border-left: 1px solid #eee;
// 	box-shadow: 0px -5px 0px $dark;
// }

// .pritable .pacdetails .title {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 0px 45px 0px;
// 	border-bottom: 1px solid #eee;
// 	margin-bottom: 40px;
// }

// .pritable .pacdetails .title strong {
// 	color: $dark;
// 	font-size: 40px;
// 	font-weight: bold;
// 	display: block;
// }

// .pritable .pacdetails .title h2 {
// 	font-size: 22px;
// 	color: #999;
// 	font-weight: normal;
// 	margin-bottom: 12px;
// 	display: block;
// }

// .pritable .pacdetails ul {
// 	padding: 0px;
// 	margin: 0px;
// }

// .pritable .pacdetails li {
// 	padding: 0px;
// 	margin: 18px 0px;
// }

// .pritable .pacdetails li strong {
// 	font-weight: 600;
// }

// .pritable .pacdetails .bottom {
// 	float: left;
// 	width: 100%;
// 	padding: 40px 0px;
// 	margin-top: 20px;
// 	border-top: 1px solid #eee;
// }

// .pritable .pacdetails .bottom a {
// 	color: $dark;
// 	font-size: 16px;
// 	background: #eee;
// 	padding: 12px 35px;
// 	border-radius: 3px;
// 	transition: all 0.3s ease;
// }

// .pritable .pacdetails .bottom a:hover {
// 	color: #fff;
// 	background: #999;
// }

// .pritable .pacdetails .title sup {
// 	font-size: 18px;
// 	font-weight: normal;
// 	padding: 0px;
// 	margin: 0px;
// }

// .pritable .pacdetails .title sub {
// 	font-size: 14px;
// 	font-weight: normal;
// 	padding: 0px;
// 	margin: 0px;
// }

// .pritable .pacdetails.two {
// 	border-right: 0px solid #eee;
// 	border-bottom: 1px solid #eee;
// 	border-left: 0px solid #eee;
// }

// .pritable .pacdetails.four {
// 	border-right: 1px solid #eee;
// 	border-bottom: 1px solid #eee;
// 	border-left: 0px solid #eee;
// }

// .pritable .pacdetails.three {
// 	margin-top: -30px;
// 	box-shadow: 0px -5px 0px $primary;
// }

// .pritable .pacdetails.three .title strong {
// 	color: $primary;
// }

// .pritable .pacdetails.three .bottom a {
// 	color: #fff;
// 	background: $primary;
// }

// .pritable .pacdetails.three h6 {
// 	width: 140px;
// 	color: #fff;
// 	font-size: 13px;
// 	font-weight: 400;
// 	text-transform: uppercase;
// 	background: $primary;
// 	margin: 0 auto;
// 	line-height: 18px;
// 	border-radius: 10px;
// }

// .pritable .pacdetails.three .title {
// 	padding-top: 30px;
// }

// .pritable .pacdetails.three .title h2 {
// 	margin-top: 12px;
// }


// .pritable.stwo {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 160px 0px 120px 0px;
// 	background: $dark url("../../images/.DEMO/site-img227.jpg");
// 	background-attachment: fixed;
// 	background-origin: initial;
// 	background-clip: initial;
// 	background-size: cover;
// 	background-repeat: no-repeat;
// 	background-position: 100% 0;
// 	background-position: center;
// }

// .pritable.stwo .pacdetails {
// 	background: rgba(255, 255, 255, 0.97);
// 	border-right: 1px solid #999;
// 	box-shadow: 0px -5px 0px #999;
// }

// .pritable.stwo .pacdetails .title {
// 	border-bottom: 1px solid #e3e3e3;
// }

// .pritable.stwo .pacdetails.three .title strong {
// 	color: #3498db;
// }

// .pritable.stwo .pacdetails.three .bottom a {
// 	color: #fff;
// 	background: #3498db;
// }

// .pritable.stwo .pacdetails.three h6 {
// 	width: 140px;
// 	color: #fff;
// 	font-size: 13px;
// 	font-weight: 400;
// 	text-transform: uppercase;
// 	background: #3498db;
// 	margin: 0 auto;
// 	line-height: 18px;
// 	border-radius: 10px;
// }

// .pritable.stwo .pacdetails.three {
// 	box-shadow: 0px -5px 0px #3498db;
// }


// .pricingtable1 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 70px 0px 0px 0px;
// }

// .pricingtable1 ul {
// 	float: left;
// 	width: 107%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .pricingtable1 li {
// 	width: 100%;
// 	padding: 10px 0px;
// 	margin: 0px;
// 	text-align: center;
// 	background-color: #f7f7f7;
// 	border-bottom: 1px solid #eee;
// }

// .pricingtable1 li.title {
// 	padding: 25px 0px;
// 	background-color: #eee;
// 	border-top-right-radius: 4px;
// 	border-top-left-radius: 4px;
// }

// .pricingtable1 li.title h4 {
// 	font-weight: 300;
// 	margin-bottom: 10px;
// }

// .pricingtable1 li.title h1 {
// 	font-weight: bold;
// 	margin-bottom: 0px;
// 	font-family: 'Open Sans', sans-serif;
// }

// .pricingtable1 li.title h1 em {
// 	font-size: 16px;
// 	font-weight: normal;
// 	font-style: normal;
// }

// .pricingtable1 li.planbut {
// 	padding: 35px 0px;
// 	background-color: #eee;
// 	border-bottom-right-radius: 4px;
// 	border-bottom-left-radius: 4px;
// }

// .pricingtable1 li.planbut a {
// 	color: #fff;
// 	font-size: 16px;
// 	padding: 12px 28px;
// 	background-color: #b1b1b1;
// 	border-radius: 4px;
// 	transition: all 0.3s ease;
// }

// .pricingtable1 li.planbut a:hover {
// 	color: #fff;
// 	background-color: $primary;
// }

// .pricingtable1 ul.active li.planbut a {
// 	color: #fff;
// 	background-color: $primary;
// }

// .pricingtable1 ul.active li.title h4 {
// 	color: $primary;
// }

// .pricingtable1 ul.active li.title h1 {
// 	color: $primary;
// }

// .pricingtable1 ul.active li.title {
// 	border-top-right-radius: 0px;
// 	border-top-left-radius: 0px;
// }

// .pricingtable1 ul.active li.mostp {
// 	color: #fff;
// 	padding: 5px 0px;
// 	background-color: $primary;
// 	border-top-right-radius: 4px;
// 	border-top-left-radius: 4px;
// }

// .pricingtable1 ul.active {
// 	margin-top: -34px;
// }

// /* pricing table style 2 */
// .pricing-tables-main {
// 	position: relative;
// 	float: left;
// 	margin-top: 50px;
// }

// .pricing-tables {
// 	position: relative;
// 	float: left;
// 	width: 24%;
// 	border: 1px solid #eee;
// }

// .pricing-tables .title {
// 	float: left;
// 	width: 100%;
// 	height: 57px;
// 	padding: 13px 0 10px 0;
// 	background-color: #454545;
// 	text-align: center;
// 	font-size: 23px;
// 	color: #fff;
// 	border-top: 3px solid #272727;
// 	border-bottom: 1px solid #818181;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: normal;
// }

// .pricing-tables .price {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 0 30px 0;
// 	background-color: #636363;
// 	text-align: center;
// 	font-size: 40px;
// 	color: #fff;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: normal;
// }

// .pricing-tables .price i {
// 	font-size: 14px;
// 	color: #ccc;
// 	display: block;
// 	font-weight: normal;
// 	font-style: normal;
// 	margin-top: 5px;
// }

// .pricing-tables .cont-list {
// 	float: left;
// 	width: 100%;
// 	padding: 15px 0;
// 	border-top: 3px solid #fff;
// 	border-bottom: 3px solid #fff;
// 	background-color: #f9f9f9;
// 	text-align: center;
// 	color: #727272;
// }

// .pricing-tables .cont-list ul {
// 	margin: 0;
// 	padding: 0;
// }

// .pricing-tables .cont-list li {
// 	margin: 0;
// 	padding: 0;
// 	list-style-type: none;
// 	line-height: 35px;
// 	border-bottom: 1px solid #fff;
// }

// .pricing-tables .cont-list li.last {
// 	border: none;
// }

// .pricing-tables .ordernow {
// 	float: left;
// 	width: 100%;
// 	padding: 28px 0px;
// 	background-color: #eee;
// 	text-align: center;
// }

// .pricing-tables .ordernow .but_small3 span i {
// 	margin-left: 3px;
// }

// .pricing-tables-helight .ordernow .but_small3 span i {
// 	margin-left: 3px;
// }

// .pricing-tables-helight {
// 	position: relative;
// 	float: left;
// 	width: 27%;
// 	z-index: 9;
// 	-webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
// 	box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
// }

// .pricing-tables-helight .title {
// 	float: left;
// 	width: 100%;
// 	height: 73px;
// 	padding: 20px 0 0 0;
// 	margin-top: -10px;
// 	background-color: #88b330;
// 	text-align: center;
// 	font-size: 23px;
// 	color: #fff;
// 	border-top: 3px solid #739b20;
// 	border-bottom: 1px solid #abd457;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: normal;
// }

// .pricing-tables-helight .price {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 0 30px 0;
// 	background-color: $primary;
// 	text-align: center;
// 	font-size: 45px;
// 	color: #fff;
// 	font-family: 'Open Sans', sans-serif;
// 	font-weight: normal;
// }

// .pricing-tables-helight .price i {
// 	font-size: 14px;
// 	color: #eee;
// 	display: block;
// 	font-weight: normal;
// 	font-style: normal;
// 	margin-top: 5px;
// }

// .pricing-tables-helight .cont-list {
// 	float: left;
// 	width: 100%;
// 	padding: 15px 0;
// 	border-top: 3px solid #fff;
// 	border-bottom: 3px solid #fff;
// 	background-color: #f9f9f9;
// 	text-align: center;
// 	color: #727272;
// }

// .pricing-tables-helight .cont-list ul {
// 	margin: 0;
// 	padding: 0;
// }

// .pricing-tables-helight .cont-list li {
// 	margin: 0;
// 	padding: 0;
// 	list-style-type: none;
// 	line-height: 35px;
// 	border-bottom: 1px solid #fff;
// }

// .pricing-tables-helight .cont-list li.last {
// 	border: none;
// }

// .pricing-tables-helight .ordernow {
// 	float: left;
// 	width: 100%;
// 	padding: 30px 0 35px 0;
// 	background-color: #f3f3f3;
// 	text-align: center;
// 	color: #fff;
// }

// /* pricing table style 3 */
// .pricingtable3 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.12);
// }

// .pricingtable3 ul {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .pricingtable3 li {
// 	width: 100%;
// 	padding: 10px 0px;
// 	margin: 0px;
// 	text-align: center;
// 	background-color: #fff;
// }

// .pricingtable3 li.title {
// 	color: #fff;
// 	padding: 18px 0px;
// 	background-color: #999;
// 	box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1);
// }

// .pricingtable3 li.title h3 {
// 	margin: 0px;
// }

// .pricingtable3 li.price {
// 	padding: 30px 0px 20px 0px;
// }

// .pricingtable3 li.price h1 {
// 	font-family: 'Open Sans', sans-serif;
// 	margin: 0px;
// 	font-weight: bold;
// }

// .pricingtable3 li.price h1 em {
// 	font-size: 16px;
// 	font-style: normal;
// 	font-weight: normal;
// }

// .pricingtable3 li.hecont {
// 	padding: 25px 50px 40px 50px;
// 	background-color: #f3f3f3;
// 	border-top: 1px solid #e7e7e7;
// 	border-bottom: 1px solid #e7e7e7;
// 	box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.1);
// }

// .pricingtable3 li.hecont strong {
// 	background-color: #454545;
// 	padding: 12px 30px;
// 	color: #fff;
// 	border-radius: 4px;
// 	white-space: nowrap;
// }

// .pricingtable3 li.title.act {
// 	background-color: $primary;
// }

// .pricingtable3 li.hecont.act strong {
// 	background-color: $primary;
// }

// .pricingtable3 li.price.act h1 {
// 	color: $primary;
// }


// /* pricing table style 9 */
// .pricingtable9 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	background: #fff;
// 	padding: 50px 0px 0px 0px;
// }

// .pricingtable9 strong {
// 	color: $primary;
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 72px;
// 	font-weight: 300;
// 	margin: 40px 0px 40px 0px;
// 	display: block;
// }

// .pricingtable9 b {
// 	color: $primary;
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 16px;
// 	font-weight: 400;
// 	display: block;
// }

// .pricingtable9 span {
// 	float: left;
// 	width: 100%;
// 	padding: 25px 0px 32px 0px;
// 	color: #fff;
// 	background: $dark;
// 	margin-top: 45px;
// 	line-height: 27px;
// }

// .pricingtable9 span i {
// 	font-size: 13px;
// 	margin-right: 1px;
// }

// .pricingtable9.two strong {
// 	color: #f9bf36;
// }

// .pricingtable9.two b {
// 	color: #f9bf36;
// }


// /* pricing table style 10 */
// .pricingtable10 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	background: #f3f3f3;
// 	padding: 50px 0px 0px 0px;
// }

// .pricingtable10 strong {
// 	color: #ee7164;
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 90px;
// 	font-weight: 300;
// 	margin: 50px 0px 45px 0px;
// 	display: block;
// }

// .pricingtable10 b {
// 	color: #999;
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 16px;
// 	font-weight: 400;
// 	display: block;
// }

// .pricingtable10 span {
// 	float: left;
// 	width: 100%;
// 	padding: 25px 0px 30px 0px;
// 	color: #fff;
// 	background: #ee7164;
// 	margin-top: 45px;
// 	line-height: 30px;
// 	font-size: 16px;
// }

// .pricingtable10 span i {
// 	font-size: 14px;
// 	margin-right: 5px;
// }






// /* list */
// ul.list_empty {
// 	float: left;
// 	padding: 0px;
// 	margin: 0px;
// 	width: 100%;
// }

// .list_empty li {
// 	float: left;
// 	padding: 0px;
// 	margin: 0px 16px 40px 0px;
// }

// ul.list_empty2 {
// 	float: left;
// 	padding: 0px;
// 	margin: 0px;
// 	width: 100%;
// }

// .list_empty2 li {
// 	float: left;
// 	padding: 0px;
// 	margin: 0px 5px 0px 0px;
// }

// ul.list1 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .list1 li {
// 	padding: 8px 0px;
// 	margin: 0px;
// }

// .list1 li i {
// 	font-size: 18px;
// 	margin-right: 8px;
// }

// .list1.small li i {
// 	font-size: 12px;
// 	margin-right: 5px;
// }

// ul.list2 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .list2 li {
// 	padding: 0px;
// 	margin: 0px;
// 	line-height: 32px;
// }

// .list2 li i {
// 	font-size: 14px;
// 	margin-right: 8px;
// }

// ul.list9 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	margin: 0px;
// }

// .list9 li {
// 	padding: 7px 0px;
// 	margin: 0px;
// 	font-size: 16px;
// }

// .list9 li i {
// 	color: $primary;
// 	font-size: 14px;
// 	margin-right: 8px;
// }

// .list9 li a {
// 	color: $dark;
// }

// .list9 li a:hover {
// 	color: $primary;
// }


// /* counters */
// .counters1 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// }

// .counters1 span {
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 40px;
// 	color: $dark;
// }

// .counters1 h4 {
// 	font-size: 18px;
// 	color: #999;
// 	font-weight: 400;
// 	margin: 13px 0px 0px 0px;
// }

// .counters1.two span {
// 	font-size: 40px;
// 	color: #fff;
// 	font-weight: 700;
// }

// .counters1.two h4 {
// 	font-size: 16px;
// 	color: #fff;
// 	margin: 3px 0px 0px 0px;
// }

// .counters1.three span {
// 	font-size: 40px;
// 	color: $dark;
// 	font-weight: 400;
// }

// .counters1.three h4 {
// 	font-size: 16px;
// 	color: #999;
// 	margin: 3px 0px 0px 0px;
// }

// .counters2 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// }

// .counters2 i {
// 	color: $primary;
// 	font-size: 40px;
// 	display: block;
// 	margin-bottom: 30px;
// }

// .counters2 h4 {
// 	font-size: 18px;
// 	color: #999;
// 	font-weight: 400;
// 	margin: 30px 0px 0px 0px;
// }

// .counters2 .one_fourth {
// 	background-color: #f3f3f3;
// 	padding: 30px 0px;
// 	border-radius: 4px;
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 50px;
// 	color: $dark;
// 	font-weight: bold;
// }

// .counters2 .one_fifth {
// 	background: none;
// 	padding: 0px 0px;
// 	border-radius: 4px;
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 50px;
// 	color: $dark;
// 	font-weight: bold;
// }

// .counters2 .one_fifth h4 {
// 	font-size: 16px;
// 	color: $dark;
// 	font-weight: 400;
// 	text-transform: uppercase;
// 	margin: 30px 0px 0px 0px;
// }

// .counters2 .one_fifth .vlines {
// 	font-size: 16px;
// 	color: $dark;
// 	font-weight: 400;
// 	margin: 10px 0px 0px 0px;
// }


// .counters3 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// }

// .counters3 .one_fourth {
// 	padding: 0px;
// 	border-radius: 4px;
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 45px;
// 	color: $dark;
// 	text-align: left;
// }

// .counters3 .one_fourth i {
// 	float: left;
// 	font-size: 55px;
// 	margin-bottom: 20px;
// 	margin-right: 25px;
// }

// .counters3 .one_fourth.white {
// 	color: #e3e3e3;
// }

// .counters3 .one_fourth.white i {
// 	color: $primary;
// }

// .counters3 .one_fourth.one {
// 	color: $primary;
// }

// .counters3 .one_fourth.one i {
// 	color: $primary;
// }

// .counters3 .one_fourth.two {
// 	color: $primary;
// }

// .counters3 .one_fourth.two i {
// 	color: $primary;
// }

// .counters3 .one_fourth.three {
// 	color: #3fc35f;
// }

// .counters3 .one_fourth.three i {
// 	color: #3fc35f;
// }

// .counters3 .one_fourth.four {
// 	color: #febc1d;
// }

// .counters3 .one_fourth.four i {
// 	color: #febc1d;
// }

// .counters3 h4 {
// 	text-align: left;
// 	font-size: 18px;
// 	color: #999;
// 	font-weight: 400;
// 	margin: 10px 0px 0px 0px;
// }

// .counters4 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	padding: 50px 0px 45px 0px;
// 	border-radius: 4px;
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 50px;
// 	border: 1px solid #e9e9e9;
// 	color: $primary;
// }

// .counters4 span {
// 	font-weight: bold;
// }

// .counters4 h4 {
// 	font-size: 21px;
// 	color: #454545;
// 	font-weight: 400;
// 	margin-top: 30px;
// 	margin-bottom: 0px;
// }

// .counters4.two {
// 	padding: 70px 0px;
// 	border: none;
// 	border-top: 1px solid #eee;
// 	border-bottom: 1px solid #eee;
// 	background: #f9f9f9;
// }

// .counters4.three {
// 	padding: 100px 0px 100px 0px;
// 	border: none;
// 	border-bottom: 1px solid #eee;
// }

// .counters5 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// }

// .counters5 span {
// 	font-size: 67px;
// 	font-weight: 600;
// 	color: #fff;
// }

// .counters5 h4 {
// 	color: #fff;
// 	font-weight: 400;
// 	margin: 0px 0px 35px 0px;
// }

// .counters6 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// 	font-size: 50px;
// 	color: #fff;
// 	font-weight: 300;
// }

// .counters6 i {
// 	color: #fff;
// 	font-size: 20px;
// 	display: block;
// 	margin-bottom: 35px;
// }

// .counters6 h4 {
// 	display: block;
// 	width: 50%;
// 	font-size: 16px;
// 	color: #fff;
// 	font-weight: 400;
// 	margin: 35px auto 0 auto;
// 	border-top: 1px solid rgba(255, 255, 255, 0.10);
// 	border-bottom: 1px solid rgba(255, 255, 255, 0.10);
// 	padding: 5px 0px 8px 0px;
// }


// .counters7 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// 	font-size: 45px;
// 	color: #fff;
// 	font-weight: 600;
// }

// .counters7 i {
// 	color: #59abe3;
// 	font-size: 30px;
// 	display: block;
// 	margin-bottom: 35px;
// }

// .counters7 h4 {
// 	display: block;
// 	width: 50%;
// 	font-size: 16px;
// 	color: #fff;
// 	font-weight: 600;
// 	text-transform: uppercase;
// 	margin: 21px auto 0 auto;
// }

// .counters7.two i {
// 	color: #46d8bd;
// }


// .counters8 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// 	font-size: 45px;
// 	color: $dark;
// 	font-weight: 600;
// }

// .counters8 h4 {
// 	display: block;
// 	width: 50%;
// 	font-size: 16px;
// 	color: #727272;
// 	margin: 27px auto 0 auto;
// }

// .counters9 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// }

// .counters9 .circle {
// 	width: 138px;
// 	height: 138px;
// 	padding-top: 40px;
// 	border: 1px solid #eee;
// 	border-radius: 100%;
// 	margin: 0 auto;
// }

// .counters9 span {
// 	font-family: 'Open Sans', sans-serif;
// 	font-size: 35px;
// 	color: $dark;
// 	font-weight: 300;
// }

// .counters9 h4 {
// 	font-size: 16px;
// 	color: #999;
// 	font-weight: 300;
// 	margin: 5px 0px 0px 0px;
// }


// .counters10 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// 	font-size: 45px;
// 	color: #2a2a30;
// 	font-weight: 700;
// }

// .counters10 h4 {
// 	display: block;
// 	width: 100%;
// 	font-size: 16px;
// 	color: #727272;
// 	margin: 18px auto 0 auto;
// }

// .counters10 .one_half {
// 	padding: 30px 0px 30px 0px;
// 	border: 2px solid #e9e9e9;
// }


// .counters11 {
// 	float: left;
// 	width: 100%;
// 	padding: 0px;
// 	text-align: center;
// 	font-size: 50px;
// 	color: #272727;
// 	font-weight: 300;
// 	font-family: 'Roboto', sans-serif;
// }

// .counters11 i {
// 	color: #d64541;
// 	font-size: 20px;
// 	display: block;
// 	margin-bottom: 35px;
// }

// .counters11 h4 {
// 	font-family: 'Roboto', sans-serif;
// 	display: block;
// 	width: 50%;
// 	font-size: 16px;
// 	color: #999;
// 	font-weight: 300;
// 	margin: 25px auto 0 auto;
// 	padding: 5px 0px 8px 0px;
// }

// .counters12 {
// 	float: left;
// 	width: 100%;
// }

// .counters12 h1 {
// 	font-weight: 800;
// 	margin-bottom: 0px;
// }

// .counters12 h5 {
// 	margin-bottom: 5px;
// }

// /* font awesome icons */
// .fati1 {
// 	width: 100px;
// 	height: 100px;
// 	color: #fff;
// 	font-size: 50px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	background-color: $dark;
// 	margin-right: 13px;
// 	margin-bottom: 13px;
// }

// .fati2 {
// 	color: $dark;
// 	font-size: 50px;
// 	vertical-align: middle;
// 	margin-right: 30px;
// 	margin-bottom: 30px;
// }

// .fati3 {
// 	color: $dark;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 1px solid #999;
// 	margin-bottom: 12px;
// }

// .fati4 {
// 	color: #fff;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: $primary;
// 	margin-bottom: 12px;
// }

// .fati4.two {
// 	float: left;
// 	font-size: 22px;
// 	width: 40px;
// 	height: 40px;
// 	line-height: 40px;
// 	margin-bottom: 30px;
// 	margin-right: 14px;
// }

// .fati5 {
// 	color: $primary;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 1px solid $primary;
// 	margin-bottom: 12px;
// }

// .fati6 {
// 	color: #760001;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #ed2b2b;
// 	margin-bottom: 12px;
// }

// .fati7 {
// 	color: #999;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #f3f3f3;
// 	border: 1px solid #e3e3e3;
// 	margin-bottom: 12px;
// }

// .fati8 {
// 	color: $dark;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 1px solid $dark;
// 	margin-bottom: 12px;
// }

// .fati9 {
// 	color: $primary;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 95px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 5px solid $primary;
// 	margin-bottom: 12px;
// }

// .fati10 {
// 	color: #fff;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #febc1d;
// 	margin-bottom: 12px;
// }

// .fati11 {
// 	color: #fff;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #35d3b7;
// 	margin-bottom: 12px;
// }

// .fati12 {
// 	color: #527c00;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #a5d549;
// 	margin-bottom: 12px;
// }

// .fati13 {
// 	color: #727272;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 95px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: #f9f9f9;
// 	border: 5px solid #e3e3e3;
// 	margin-bottom: 12px;
// }

// .fati14 {
// 	color: $dark;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 4px;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 1px solid #999;
// 	margin-bottom: 12px;
// }

// .fati15 {
// 	color: #fff;
// 	font-size: 45px;
// 	width: 100px;
// 	height: 100px;
// 	vertical-align: middle;
// 	line-height: 100px;
// 	border-radius: 4px;
// 	text-align: center;
// 	margin-right: 12px;
// 	background-color: $dark;
// 	margin-bottom: 12px;
// }

// .fati16 {
// 	width: 75px;
// 	height: 75px;
// 	color: #fff;
// 	font-size: 35px;
// 	vertical-align: middle;
// 	line-height: 75px;
// 	border-radius: 100%;
// 	text-align: center;
// 	background-color: $dark;
// 	margin-right: 14px;
// 	margin-bottom: 14px;
// }

// .fati17 {
// 	width: 50px;
// 	height: 50px;
// 	color: #fff;
// 	font-size: 27px;
// 	vertical-align: middle;
// 	line-height: 50px;
// 	border-radius: 100%;
// 	text-align: center;
// 	background-color: $dark;
// 	margin-right: 14px;
// 	margin-bottom: 14px;
// }

// .fati18 {
// 	width: 30px;
// 	height: 30px;
// 	color: #fff;
// 	font-size: 16px;
// 	vertical-align: middle;
// 	line-height: 30px;
// 	border-radius: 100%;
// 	text-align: center;
// 	background-color: $dark;
// 	margin-right: 7px;
// 	margin-bottom: 7px;
// }

// .fati19 {
// 	color: #fff;
// 	font-size: 21px;
// 	width: 50px;
// 	height: 50px;
// 	vertical-align: middle;
// 	line-height: 50px;
// 	border-radius: 100%;
// 	text-align: center;
// 	margin-right: 12px;
// 	border: 1px solid #fff;
// 	transition: all 0.3s ease;
// }

// .fati19:hover {
// 	color: $primary;
// 	border: 1px solid #fff;
// 	background: #fff;
// }


// /* image frames */
// .imgframe1 {
// 	float: left;
// 	width: 100%;
// 	border: 8px solid #d3d3d3;
// }

// .imgframe2 {
// 	float: left;
// 	width: 100%;
// 	border: 8px solid #fff;
// 	-webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.20);
// 	-moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.20);
// 	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.20);
// }

// .imgframe3 {
// 	float: left;
// 	width: 100%;
// 	text-align: center;
// 	background-color: $dark;
// 	border: 8px solid $dark;
// }

// .imgframe3 img {
// 	float: left;
// 	width: 100%;
// }

// .imgframe3 strong {
// 	float: left;
// 	color: #fff;
// 	font-size: 18px;
// 	font-weight: normal;
// 	width: 100%;
// 	padding: 14px 0px 12px 0px;
// 	text-align: center;
// }

// .imgframe4 {
// 	float: left;
// 	width: 100%;
// 	-webkit-box-shadow: 0 10px 6px -6px #454545;
// 	-moz-box-shadow: 0 10px 6px -6px #454545;
// 	box-shadow: 0 10px 6px -6px #454545;
// }


// .imgframe5 {
// 	float: left;
// 	width: 100%;
// 	position: relative;
// }

// .imgframe5 img {
// 	float: left;
// 	width: 100%;
// }

// .imgframe5:before, .imgframe5:after {
// 	z-index: -1;
// 	position: absolute;
// 	content: "";
// 	bottom: 15px;
// 	left: 10px;
// 	width: 50%;
// 	top: 80%;
// 	max-width: 300px;
// 	background: #777;
// 	-webkit-box-shadow: 0 12px 10px #454545;
// 	-moz-box-shadow: 0 12px 10px #454545;
// 	box-shadow: 0 12px 10px #454545;
// 	-webkit-transform: rotate(-3deg);
// 	-moz-transform: rotate(-3deg);
// 	-o-transform: rotate(-3deg);
// 	-ms-transform: rotate(-3deg);
// 	transform: rotate(-3deg);
// }

// .imgframe5:after {
// 	-webkit-transform: rotate(3deg);
// 	-moz-transform: rotate(3deg);
// 	-o-transform: rotate(3deg);
// 	-ms-transform: rotate(3deg);
// 	transform: rotate(3deg);
// 	right: 10px;
// 	left: auto;
// }

// .imgframe6 {
// 	float: left;
// 	width: 100%;
// 	position: relative;
// }

// .imgframe6 img {
// 	float: left;
// 	width: 100%;
// 	border: 10px solid #f3f3f3;
// }

// .imgframe6:before, .imgframe6:after {
// 	z-index: -1;
// 	position: absolute;
// 	content: "";
// 	bottom: 15px;
// 	left: 10px;
// 	width: 50%;
// 	top: 80%;
// 	max-width: 300px;
// 	background: #777;
// 	-webkit-box-shadow: 0 16px 10px #454545;
// 	-moz-box-shadow: 0 16px 10px #454545;
// 	box-shadow: 0 16px 10px #454545;
// 	-webkit-transform: rotate(-3deg);
// 	-moz-transform: rotate(-3deg);
// 	-o-transform: rotate(-3deg);
// 	-ms-transform: rotate(-3deg);
// 	transform: rotate(-3deg);
// }

// .imgframe6:after {
// 	-webkit-transform: rotate(3deg);
// 	-moz-transform: rotate(3deg);
// 	-o-transform: rotate(3deg);
// 	-ms-transform: rotate(3deg);
// 	transform: rotate(3deg);
// 	right: 10px;
// 	left: auto;
// }


// /* pop overs */
// .pop-wrapper {
// 	width: 90%;
// 	padding: 0;
// 	margin: 0px auto 30px auto;
// }

// .pop-wrapper li {
// 	float: left;
// 	margin: 0px 25px 70px 25px;
// }

// .pop-wrapper li a {
// 	display: block;
// 	width: 120px;
// 	height: 120px;
// 	margin: 0 2px;
// 	outline: none;
// 	position: relative;
// 	background-color: #f3f3f3;
// 	border-radius: 100%;
// 	text-align: center;
// }

// .pop-wrapper li a span {
// 	width: 300px;
// 	height: auto;
// 	line-height: 21px;
// 	padding: 10px;
// 	left: 50%;
// 	margin-left: -150px;
// 	font-family: 'Alegreya SC', Georgia, serif;
// 	font-weight: 400;
// 	font-style: italic;
// 	font-size: 14px;
// 	color: #719DAB;
// 	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
// 	text-align: center;
// 	border: 4px solid #fff;
// 	background: #f9f9f9;
// 	text-indent: 0px;
// 	border-radius: 5px;
// 	position: absolute;
// 	pointer-events: none;
// 	bottom: 100px;
// 	opacity: 0;
// 	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
// 	-webkit-transition: all 0.3s ease-in-out;
// 	-moz-transition: all 0.3s ease-in-out;
// 	-o-transition: all 0.3s ease-in-out;
// 	-ms-transition: all 0.3s ease-in-out;
// 	transition: all 0.3s ease-in-out;
// }

// .pop-wrapper li a span:before, .pop-wrapper li a span:after {
// 	content: "";
// 	position: absolute;
// 	bottom: -15px;
// 	left: 50%;
// 	margin-left: -9px;
// 	width: 0;
// 	height: 0;
// 	border-left: 10px solid transparent;
// 	border-right: 10px solid transparent;
// 	border-top: 10px solid rgba(0, 0, 0, 0.1);
// }

// .pop-wrapper li a span:after {
// 	bottom: -14px;
// 	margin-left: -10px;
// 	border-top: 10px solid #fff;
// }

// .pop-wrapper li a:hover span {
// 	opacity: 0.9;
// 	bottom: 116px;
// }

// .pop-wrapper h6 {
// 	margin-top: 10px;
// }

// .pop-wrapper2 {
// 	width: 90%;
// 	padding: 0;
// 	margin: 0px auto 30px auto;
// }

// .pop-wrapper2 li {
// 	float: left;
// }

// .pop-wrapper2 li a span {
// 	width: 200px;
// 	height: auto;
// 	line-height: 21px;
// 	padding: 10px;
// 	left: 50%;
// 	margin-left: -134px;
// 	font-weight: 400;
// 	font-style: italic;
// 	font-size: 14px;
// 	color: #719DAB;
// 	text-align: center;
// 	border: 4px solid #fff;
// 	background: #fff;
// 	text-indent: 0px;
// 	border-radius: 5px;
// 	position: absolute;
// 	pointer-events: none;
// 	bottom: 80px;
// 	opacity: 0;
// 	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
// 	-webkit-transition: all 0.3s ease-in-out;
// 	-moz-transition: all 0.3s ease-in-out;
// 	-o-transition: all 0.3s ease-in-out;
// 	-ms-transition: all 0.3s ease-in-out;
// 	transition: all 0.3s ease-in-out;
// 	white-space: normal;
// }

// .pop-wrapper2 li a span:before, .pop-wrapper2 li a span:after {
// 	content: "";
// 	position: absolute;
// 	bottom: -15px;
// 	left: 50%;
// 	margin-left: -9px;
// 	width: 0;
// 	height: 0;
// 	border-left: 10px solid transparent;
// 	border-right: 10px solid transparent;
// 	border-top: 10px solid rgba(0, 0, 0, 0.1);
// }

// .pop-wrapper2 li a span:after {
// 	bottom: -14px;
// 	margin-left: -10px;
// 	border-top: 10px solid #fff;
// }

// .pop-wrapper2 li a:hover span {
// 	opacity: 1;
// 	bottom: 59px;
// }

// .pop-wrapper2.colored li a span {
// 	color: #fff;
// 	border: 4px solid $primary;
// 	background: $primary;
// 	box-shadow: none;
// }

// .pop-wrapper2.colored li a span:after {
// 	border-top: 10px solid $primary;
// }

// .pop-wrapper2 li a span.grayd {
// 	color: #fff;
// 	background: #454545;
// 	border: 4px solid #454545;
// 	box-shadow: none;
// }

// .pop-wrapper2 li a span.grayd:after {
// 	border-top: 10px solid #454545;
// }

// .pop-wrapper2 li a span.bord {
// 	background: #eee;
// 	border: 4px solid #e3e3e3;
// 	box-shadow: none;
// }

// .pop-wrapper2 li a span.bord:after {
// 	border-top: 10px solid #e3e3e3;
// }

// .pop-wrapper4 {
// 	padding: 0;
// 	margin: 0px auto 30px auto;
// }

// .pop-wrapper4 li {
// 	float: left;
// 	margin: 0px 0px;
// 	padding: 0;
// }

// .pop-wrapper4 li a {
// 	position: relative;
// }

// .pop-wrapper4 li a span {
// 	width: 100px;
// 	height: auto;
// 	line-height: 21px;
// 	padding: 10px;
// 	left: 50%;
// 	margin-left: -56px;
// 	font-weight: 400;
// 	font-style: italic;
// 	font-size: 14px;
// 	color: #fff;
// 	text-align: center;
// 	border: 4px solid #454545;
// 	background: #454545;
// 	text-indent: 0px;
// 	border-radius: 5px;
// 	position: absolute;
// 	pointer-events: none;
// 	bottom: 40px;
// 	opacity: 0;
// 	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
// 	-webkit-transition: all 0.3s ease-in-out;
// 	-moz-transition: all 0.3s ease-in-out;
// 	-o-transition: all 0.3s ease-in-out;
// 	-ms-transition: all 0.3s ease-in-out;
// 	transition: all 0.3s ease-in-out;
// }

// .pop-wrapper4 li a span:before, .pop-wrapper4 li a span:after {
// 	content: "";
// 	position: absolute;
// 	bottom: -15px;
// 	left: 50%;
// 	margin-left: -9px;
// 	width: 0;
// 	height: 0;
// 	border-left: 10px solid transparent;
// 	border-right: 10px solid transparent;
// 	border-top: 10px solid rgba(0, 0, 0, 0.1);
// }

// .pop-wrapper4 li a span:after {
// 	bottom: -14px;
// 	margin-left: -10px;
// 	border-top: 10px solid #454545;
// }

// .pop-wrapper4 li a:hover span {
// 	opacity: 0.9;
// 	bottom: 50px;
// }

// .smlined {
// 	width: 21%;
// 	height: 1px;
// 	background: #999;
// 	margin: 0 auto;
// }

// /* left icon right text section */
// .lirts {
// 	float: left;
// 	width: 100%;
// }

// .lirts h5 {
// 	margin-bottom: 10px;
// }

// .lirts i {
// 	float: left;
// 	font-size: 40px;
// 	color: #999;
// 	margin-right: 30px;
// 	margin-top: 7px;
// }

// .lirts p {
// 	margin-left: 70px;
// }

// .lirts span {
// 	float: left;
// 	font-size: 40px;
// 	color: #999;
// 	margin-right: 30px;
// 	margin-top: 3px;
// }

// .big_text1 {
// 	font-family: 'Raleway', sans-serif;
// 	font-weight: 300;
// 	font-size: 21px;
// 	line-height: 32px;
// 	color: #454545;
// }

// .big_text1 i {
// 	color: $primary;
// 	font-style: normal;
// }


// .josstext {
// 	color: $dark;
// 	font-size: 27px;
// 	font-weight: normal;
// 	line-height: 36px;
// 	font-family: 'Josefin Sans', sans-serif;
// }

// .josstext span {
// 	width: 100px;
// 	height: 1px;
// 	background: $dark;
// 	display: block;
// 	margin-top: 20px;
// }

// .josstext2 {
// 	width: 85%;
// 	margin: 0 auto 67px auto;
// 	color: $dark;
// 	font-size: 27px;
// 	font-weight: normal;
// 	line-height: 36px;
// 	font-family: 'Josefin Sans', sans-serif;
// }

// .josstext2 span {
// 	width: 100px;
// 	height: 1px;
// 	background: $dark;
// 	display: block;
// 	margin: 20px auto 0 auto;
// }


// @media only screen and (min-width: 1000px) and (max-width: 1169px) {

// 	.flips1 {
// 		height: 264px;
// 	}

// 	.flips1_back {
// 		padding: 20px 10px;
// 		height: 264px;
// 	}

// 	.flips2 {
// 		height: 264px;
// 	}

// 	.flips2_back {
// 		padding: 20px 10px;
// 		height: 264px;
// 	}

// 	.flips4_back {
// 		padding: 12px 10px 0px 10px;
// 	}

// 	.pop-wrapper2 li a span {
// 		margin-left: -112px;
// 	}


// 	.counters10 {
// 		font-size: 30px;
// 	}

// 	.counters10 h4 {
// 		font-size: 12px;
// 		margin: 10px auto 0 auto;
// 	}

// 	.counters10 .one_half {
// 		padding: 20px 0px 20px 0px;
// 	}



// }


// // @include media-breakpoint-between(lg, xl) {
// @media only screen and (min-width: 768px) and (max-width: 999px) {

// 	.piechart1 {
// 		width: 30%;
// 	}

// 	.piechart3 {
// 		width: 50%;
// 	}

// 	.flips1 {
// 		height: 203px;
// 	}

// 	.flips1_back {
// 		padding: 20px 10px;
// 		height: 203px;
// 	}

// 	.flips1_back h4 {
// 		font-size: 16px;
// 		margin-bottom: 5px;
// 	}

// 	.flips1_back p {
// 		font-size: 12px;
// 		line-height: 18px;
// 		margin-bottom: -25px;
// 	}

// 	.flips2 {
// 		height: 203px;
// 	}

// 	.flips2_back {
// 		padding: 20px 10px;
// 		height: 203px;
// 	}

// 	.flips2_back h4 {
// 		font-size: 16px;
// 		margin-bottom: 5px;
// 	}

// 	.flips2_back p {
// 		font-size: 12px;
// 		line-height: 18px;
// 		margin-bottom: -25px;
// 	}

// 	.flips4_back {
// 		padding: 12px 0px 0px 0px;
// 	}

// 	.flips4_back p {
// 		font-size: 13px;
// 		line-height: 18px;
// 	}

// 	.pricingtable3 li.hecont {
// 		padding: 20px 5px 35px 5px;
// 	}

// 	.pricingtable3 .but_small1 {
// 		padding: 10px 10px;
// 	}

// 	.pop-wrapper {
// 		width: 63%;
// 	}

// 	.pop-wrapper li {
// 		float: left;
// 		margin: 0px 40px 120px 40px;
// 	}

// 	.pop-wrapper2 li a span {
// 		margin-left: -91px;
// 	}


// 	.counters5 span {
// 		font-size: 55px;
// 	}

// 	.counters5 h4 {
// 		font-size: 16px;
// 	}

// 	.counters9 .circle {
// 		width: 120px;
// 		height: 120px;
// 		padding-top: 34px;
// 	}








// }


// // @include media-breakpoint-between(md, lg) {
// @media only screen and (min-width: 640px) and (max-width: 767px) {

// 	.piechart1 {
// 		width: 50%;
// 	}

// 	.piechart3 {
// 		width: 50%;
// 	}

// 	.pritable .pacdetails {
// 		width: 100%;
// 		margin-bottom: 100px;
// 	}

// 	.pritable .pacdetails.two {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.pritable .pacdetails.four {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.counters4 {
// 		font-size: 35px;
// 		line-height: 35px;
// 	}

// 	.flips1_front img {
// 		width: 100%;
// 		height: 100%;
// 	}

// 	.flips1_front img.full {
// 		width: 100%;
// 		height: 320px;
// 	}

// 	.flips2_front img {
// 		width: 100%;
// 		height: 100%;
// 	}

// 	.flips4_front img {
// 		width: auto;
// 	}

// 	.flips4_back {
// 		padding: 22px 25px 0px 25px;
// 	}

// 	.pricing-tables {
// 		width: 100%;
// 		margin-bottom: 80px;
// 	}

// 	.pricing-tables-helight {
// 		width: 100%;
// 		margin-bottom: 80px;
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 		border: 1px solid #eee;
// 	}

// 	.pop-wrapper {
// 		width: 75%;
// 	}

// 	.pop-wrapper li {
// 		float: left;
// 		margin: 0px 40px 120px 40px;
// 	}

// 	.pop-wrapper2 li a span {
// 		margin-left: -281px;
// 	}


// 	.counters5 span {
// 		font-size: 45px;
// 	}

// 	.counters5 h4 {
// 		font-size: 16px;
// 	}




// 	.button.five {
// 		padding: 13px 20px;
// 	}

// 	.pricingtable9 strong {
// 		font-size: 55px;
// 		margin: 30px 0px 30px 0px;
// 	}

// 	.counters9 .circle {
// 		width: 100px;
// 		height: 100px;
// 		padding-top: 27px;
// 	}

// 	.counters9 span {
// 		font-size: 27px;
// 	}

// 	.counters9 h4 {
// 		font-size: 14px;
// 		margin: 0px 0px 0px 0px;
// 	}


// }


// // @include media-breakpoint-between(sm, md) {
// @media only screen and (min-width: 480px) and (max-width: 639px) {

// 	.piechart1 {
// 		width: 50%;
// 	}

// 	.piechart3 {
// 		width: 100%;
// 	}

// 	.pritable .pacdetails {
// 		width: 100%;
// 		margin-bottom: 100px;
// 	}

// 	.pritable .pacdetails.two {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.pritable .pacdetails.four {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.counters4 {
// 		font-size: 35px;
// 		line-height: 35px;
// 	}

// 	.flips1_front img {
// 		width: 100%;
// 		height: 100%;
// 	}

// 	.flips1_front img.full {
// 		width: 100%;
// 		height: 320px;
// 	}

// 	.flips2_front img {
// 		width: 100%;
// 		height: 100%;
// 	}

// 	.flips4_front img {
// 		width: auto;
// 	}

// 	.flips4_back {
// 		padding: 22px 25px 0px 25px;
// 	}

// 	.pricing-tables {
// 		width: 100%;
// 		margin-bottom: 80px;
// 	}

// 	.pricing-tables-helight {
// 		width: 100%;
// 		margin-bottom: 80px;
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 		border: 1px solid #eee;
// 	}

// 	.pop-wrapper {
// 		width: 50%;
// 	}

// 	.pop-wrapper li {
// 		float: left;
// 		margin: 0px 40px 120px 40px;
// 	}

// 	.pop-wrapper2 li a span {
// 		margin-left: -217px;
// 	}






// 	.counters5 span {
// 		font-size: 35px;
// 	}

// 	.counters5 h4 {
// 		font-size: 13px;
// 		margin-bottom: 10px;
// 	}


// 	.stcode_title13 {
// 		font-size: 30px;
// 	}





// }

// @media only screen and (max-width: 479px) {

// 	.piechart1 {
// 		width: 100%;
// 	}

// 	.piechart3 {
// 		width: 100%;
// 	}

// 	.pritable .pacdetails {
// 		width: 100%;
// 		margin-bottom: 100px;
// 	}

// 	.pritable .pacdetails.two {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.pritable .pacdetails.four {
// 		border-right: 1px solid #eee;
// 		border-bottom: 1px solid #eee;
// 		border-left: 1px solid #eee;
// 	}

// 	.counters4 {
// 		font-size: 35px;
// 		line-height: 35px;
// 	}

// 	.flips4_front img {
// 		width: auto;
// 	}

// 	.flips4_back {
// 		padding: 12px 10px 0px 10px;
// 	}

// 	.pricing-tables {
// 		width: 100%;
// 		margin-bottom: 80px;
// 	}

// 	.pricing-tables-helight {
// 		width: 100%;
// 		margin-bottom: 80px;
// 		-webkit-box-shadow: none;
// 		box-shadow: none;
// 		border: 1px solid #eee;
// 	}

// 	.pop-wrapper {
// 		width: 68%;
// 	}

// 	.pop-wrapper li {
// 		float: left;
// 		margin: 0px 40px 120px 40px;
// 	}

// 	.pop-wrapper2 li a span {
// 		margin-left: -150px;
// 	}

// 	.but_medium1, 	.but_medium1.white {
// 		padding: 14px 20px;
// 	}






// 	.counters5 span {
// 		font-size: 27px;
// 	}

// 	.counters5 h4 {
// 		font-size: 12px;
// 		margin-bottom: 7px;
// 	}

// 	.stcode_title13 {
// 		padding: 1px 10px;
// 		font-size: 25px;
// 	}




// }






// /*!
//  * Yamm!3
//  * Yet another megamenu for Bootstrap
//  * http://geedmo.github.com/yamm3
//  *
//  * Demo styles
//  */

// /* layout */

// pre {
// 	margin: 0;
// 	padding: 10px 20px !important;
// }

// /* menu styes */

// .list-unstyled, .list-unstyled ul {
// 	min-width: 120px
// }

// @media (min-width: 767px) {
// 	.panel-group {
// 		width: 400px;
// 	}

// 	.thumbnail {
// 		margin: 0;
// 	}
// }

// /* Grid demo styles */

// .grid-demo {
// 	padding: 10px 30px;
// }

// .grid-demo[class*="col-"] {
// 	margin-top: 5px;
// 	margin-bottom: 5px;
// 	font-size: 1em;
// 	text-align: center;
// 	line-height: 2;
// 	background-color: #e5e1ea;
// 	border: 1px solid #d1d1d1;
// }



// @media only screen and (min-width: 768px) and (max-width: 999px) {
// 	.navbar-nav .dropdown .dropdown-menu {
// 		display: block;
// 		position: static;
// 		float: none;
// 		width: auto;
// 		margin-top: 0;
// 		background-color: transparent;
// 		border: 0;
// 		box-shadow: none;
// 	}

// 	.navbar-collapse {
// 		position: absolute;
// 		margin: 0 !important;
// 	}

// 	.dropdown-menu {
// 		position: absolute;
// 		margin: 0 !important;
// 	}
// }

// // @include media-breakpoint-between(sm, md) {
// @media only screen and (min-width: 480px) and (max-width: 767px) {

// 	.navbar-nav .dropdown .dropdown-menu {
// 		display: block;
// 		position: static;
// 		float: none;
// 		width: auto;
// 		margin-top: 0;
// 		background-color: transparent;
// 		border: 0;
// 		box-shadow: none;
// 	}

// 	.navbar-collapse {
// 		position: absolute;
// 		margin: 0 !important;
// 	}

// 	.dropdown-menu {
// 		position: absolute;
// 		margin: 0 !important;
// 	}




// }

// // @include media-breakpoint-down(sm) {
// @media only screen and (max-width: 479px) {

// 	.navbar-nav .dropdown .dropdown-menu {
// 		display: block;
// 		position: static;
// 		float: none;
// 		width: auto;
// 		margin-top: 0;
// 		background-color: transparent;
// 		border: 0;
// 		box-shadow: none;
// 	}

// 	.navbar-collapse {
// 		position: absolute;
// 		margin: 0 !important;
// 	}

// 	.dropdown-menu {
// 		position: absolute;
// 		margin: 0 !important;
// 	}
// }











// ul.nav>li>a.active {
// 	color: $primary;
// }

// ul.nav>li>a:hover.active {
// 	color: $primary;
// }

// ul.nav>li>a:hover {
// 	color: $primary;
// 	transition: all 0.3s ease;
// }

// ul.nav .dropdown li a.active, ul.nav .dropdown li a.active i {
// 	color: $primary;
// }

// ul.nav .dropdown li:hover a.active {
// 	color: $primary;
// }

// .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
// 	color: #fff;
// 	background-color: $primary;
// }


// .list-unstyled a.active {
// 	color: #393939;
// }

// .list-unstyled a.active i {
// 	color: #393939;
// }

// .yamm .nav, .yamm .collapse, .yamm .dropup, .yamm .dropdown {
// 	position: static;

// }

// .yamm .container {
// 	position: relative;
// }

// .yamm .dropdown-menu {
// 	left: auto;
// }

// .yamm .nav.navbar-right .dropdown-menu {
// 	left: auto;
// 	right: 0;
// }

// .yamm .yamm-content {
// 	padding: 50px 40px 40px 40px;
// }

// .yamm .dropdown.yamm-fw .dropdown-menu {
// 	left: 0;
// 	right: 0;
// }

// .yamm .dropdown.yamm-hw .dropdown-menu {
// 	left: auto;
// 	right: 0;
// 	width: 550px;
// }

// .yamm .yamm-content .col-md-4, .yamm .yamm-content .col-md-8 {
// 	padding-right: 5px;
// 	padding-left: 5px;
// }


// .yamm .yamm-content .thumbnail {
// 	padding: 0;
// 	border: 0;
// }

// .yamm .yamm-content .caption {
// 	padding: 0;
// }

// .yamm .yamm-content .caption h3 {
// 	font-size: 18px;
// 	color: #272727;
// 	margin-top: 14px;
// 	margin-bottom: 8px;
// }

// .yamm .yamm-content .caption p {
// 	font-size: 14px;
// 	color: #727272;
// }

// .list-unstyled li:first-child {}

// .list-unstyled.two {
// 	margin-top: -12px;
// }


// .list-unstyled li p {
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 14px;
// 	color: $dark;
// 	text-transform: uppercase;
// 	font-weight: 500;
// 	line-height: 0px;
// 	margin-bottom: 18px;
// 	letter-spacing: 0.5px;
// }

// .list-unstyled li i {
// 	color: #999;
// 	margin-right: 4px;
// 	font-size: 11px;
// 	transition: all 0.3s ease;
// }

// .list-unstyled li {
// 	padding: 5px 0;
// }

// .list-unstyled a {
// 	color: #727272;
// 	padding: 5px 0px 5px 0px;
// 	width: 100%;
// 	font-size: 14px;
// 	transition-property: margin-left, background-color;
// 	transition-duration: 0.3s;
// 	transition-timing-function: ease-out;
// 	font-family: 'Roboto', sans-serif;
// 	font-weight: 400;
// 	transition: all 0.3s ease;
// }

// .list-unstyled a:hover {
// 	color: #393939;
// 	background: #f3f3f3;
// 	padding: 5px 10px;
// 	border-radius: 3px;
// }

// .list-unstyled a:hover i {
// 	color: #393939;
// }











// .list-unstyled a:before {
// 	font-size: 12px;
// 	margin-right: 5px;
// }

// .row-border+.row .caption h3 {
// 	margin-top: 0px;
// }

// .row-border {
// 	border-bottom: 1px solid #d1d1d1;
// 	margin-bottom: 20px;
// 	padding-bottom: 10px;
// }

// @media (max-width:767px) {
// 	.list-unstyled li:first-child {

// 		border-bottom: 0px;
// 		margin-bottom: 0px;
// 		top-bottom: 15px;

// 	}

// 	.list-unstyled li p {
// 		color: #bbb;
// 	}

// 	.list-unstyled li {
// 		padding: 8px 0;
// 	}

// 	.list-unstyled a {
// 		color: #bbb;

// 	}

// 	.list-unstyled a {
// 		color: #BBBBBB;
// 	}


// 	.list-unstyled a {
// 		color: #BBBBBB;
// 	}

// 	.list-unstyled a:hover {
// 		color: #fff;
// 	}

// 	.yamm .yamm-content .thumbnail {
// 		background-color: transparent;
// 		-webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075);
// 		box-shadow: 0 0px 0px rgba(0, 0, 0, 0.075);
// 	}

// 	.yamm .yamm-content .caption p {
// 		color: #ccc;
// 	}

// 	.yamm .yamm-content .caption h3 {
// 		color: #ccc;
// 	}

// 	.yamm .yamm-content .thumbnail>img {
// 		margin-left: 0;
// 	}

// 	.row-border {
// 		border-bottom: 0;
// 	}
// }



// @media (max-width:999px) {


// 	.list-unstyled a {
// 		color: #BBBBBB;
// 	}

// 	.list-unstyled li:first-child {
// 		border-bottom: 1px solid #3b3b3b;
// 		margin-bottom: 16px;
// 	}

// 	.list-unstyled li p {
// 		color: #fff;
// 		padding-top: 10px;
// 	}

// 	.list-unstyled .img_left4 {
// 		margin-right: 20px;
// 	}

// }

// .navbar-nav>li>.dropdown-menu {
// 	margin-top: 0;
// 	border-top-right-radius: 0;
// 	border-top-left-radius: 0;
// 	border: 0;
// 	border-radius: 0;
// }

// .navbar-nav>li>a {
// 	padding-top: 18px;
// 	padding-bottom: 18px;
// 	text-transform: uppercase;
// }

// .navbar-nav>li>a i {
// 	display: block;
// 	text-align: center;
// }

// .navbar-default {
// 	margin-right: -32px;
// }

// .navbar-default.two {
// 	width: 100%;
// 	/*margin-right: 140px;*/
// 	/*padding-right:140px;*/

// }

// .navbar-nav.dark>li>a {
// 	padding-top: 30px;
// 	padding-bottom: 27px;
// 	text-transform: uppercase;
// }

// .dropdown-submenu {
// 	position: relative;
// }

// .dropdown-submenu>.dropdown-menu {
// 	top: 0;
// 	left: 100%;
// 	margin-top: -1px;
// 	margin-left: -1px;
// 	/*    margin-left: -456px;*/
// 	-webkit-border-radius: 0;
// 	-moz-border-radius: 0;
// 	border-radius: 0;

// }

// .dropdown-submenu>.dropdown-menu:last-child {}

// @media (min-width:769px) {
// 	.dropdown-submenu>.dropdown-menu:last-child {
// 		border-top: 0px solid #000;

// 	}
// }

// .dropdown-submenu:hover>.dropdown-menu {
// 	display: block;

// }

// .dropdown-submenu.pull-left {
// 	float: none;
// }

// .dropdown-submenu.pull-left>.dropdown-menu {
// 	left: -100%;
// 	margin-left: 10px;
// 	-webkit-border-radius: 6px 0 6px 6px;
// 	-moz-border-radius: 6px 0 6px 6px;
// 	border-radius: 6px 0 6px 6px;
// }


// .navbar-brand {
// 	/* background: url("../../images/.DEMO/logo.png") no-repeat;
//     width: 162px;
//     height: 42px;
//     display: block;*/
// 	padding: 0;
// 	margin: 15px 0;
// }


// .wrapper {
// 	margin-top: 0px;
// }

// .navbar-fixed-top+.wrapper {
// 	margin-top: 104px;
// }

// .dropdown-menu {
// 	min-width: 200px;
// }

// .dropdown-menu>li>a {
// 	padding: 10px 20px;
// 	font-size: 14px;
// 	color: #727272;
// 	font-weight: 400;
// 	border-bottom: 1px solid #eee;
// 	transition: all 0.3s ease;
// }

// .dropdown-menu>li>a:hover {
// 	border-bottom: 1px solid #eee;
// }

// .dropdown-menu>li:last-child>a {
// 	border-bottom: 0px solid #d1d1d1;
// }



































// /*-------------- header animation Start -------------------*/




// .help {
// 	height: 40px;
// }

// .help>.container {
// 	padding-top: 10px;
// }

// @media (max-width: 999px) {
// 	.navbar-collapse.pull-right {
// 		width: 100%;
// 		/*float: none !important;*/
// 		background: #1e1e1e;
// 	}

// 	#navbar-collapse-1>ul>li.dropdown>a>b {
// 		float: right;
// 	}

// 	.navbar-default .navbar-brand {
// 		margin-left: 14px;
// 	}

// 	#navbar-collapse-1>ul>li {
// 		border-bottom: 1px solid #eee;
// 	}

// 	#navbar-collapse-1>ul>li.open {
// 		background-color: #2b2b2b;

// 	}

// 	.help {
// 		display: none;
// 	}

// 	.navbar-nav>li>a {
// 		color: #b3b3b3;
// 		padding: 14px 15px;
// 	}

// 	.navbar-nav>li>a:hover {
// 		color: #fff;
// 	}

// 	.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover, 	.navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
// 		color: #92d102;
// 		background-color: transparent;
// 		padding: 10px 20px;
// 		border-bottom: 0px;

// 	}

// 	.dropdown-menu>li>a {
// 		padding: 8px 20px !important;
// 		;
// 		border-bottom: 0px;
// 	}

// 	.dropdown-submenu>.dropdown-menu:last-child {
// 		border-top: 0px;
// 	}

// }

// /*
// .navbar-fixed-top .navbar-nav > li > a {padding-top: 15.666666666666666px; padding-bottom: 15.666666666666666px;}
// .navbar-fixed-top  .navbar-brand {margin-top: 3.666666666666666px; margin-bottom: 2.666666666666666px;}
// .navbar-fixed-top .navbar-brand img {height: 34.666666666666664px;}
// */
// /*-------------- header animation End -------------------*/
// .navbar-nav>li>a {
// 	box-shadow: 0 0px 0 rgba(255, 255, 255, 0.25);
// }

// .callus {
// 	color: #545454;
// 	font-size: 13px;
// 	padding-top: 13px;
// }

// .callus a {
// 	margin-right: 20px;
// 	color: #545454;
// }

// .social-icons ul li {
// 	float: left;
// 	list-style-type: none;
// 	margin: 10px 0 10px 7px;

// }

// .social-icons a {
// 	width: 25px;
// 	height: 25px;
// 	display: block;
// 	background-color: #fff;
// 	border: 1px solid #c7c7c7;
// 	border-radius: 50%;
// 	text-align: center;

// }

// .social-icons a i {
// 	font-size: 15px;
// 	color: #272727;
// 	line-height: 24px;
// 	text-align: center;

// }

// .social-icons a:hover {
// 	background-color: #272727;
// 	border: 1px solid #272727;

// }

// .social-icons a:hover i {
// 	color: #fff;

// }

// :focus {
// 	outline: 0
// }

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(134, 199, 36, .2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(134, 199, 36, .6);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(134, 199, 36, 1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(210, 210, 210, .4)
}







// @media only screen and (min-width: 768px) and (max-width: 999px) {

// 	.yamm .yamm-content {
// 		padding: 10px 20px 20px 20px;
// 	}




// 	.menu-sepbox {
// 		width: 100%;
// 		padding: 27px 20px 31px 20px;
// 	}





// }

// // @include media-breakpoint-between(md, lg) {
// @media only screen and (min-width: 640px) and (max-width: 767px) {


// 	.yamm .yamm-content {
// 		padding: 10px 20px 20px 20px;
// 	}



// 	.menu-sepbox {
// 		width: 100%;
// 		padding: 27px 20px 31px 20px;
// 	}




// }

// // @include media-breakpoint-between(sm, md) {
// @media only screen and (min-width: 480px) and (max-width: 639px) {


// 	.yamm .yamm-content {
// 		padding: 10px 20px 20px 20px;
// 	}



// 	.menu-sepbox {
// 		width: 100%;
// 		padding: 27px 20px 31px 20px;
// 	}



// }

// @media only screen and (max-width: 479px) {


// 	.yamm .yamm-content {
// 		padding: 10px 20px 20px 20px;
// 	}


// 	.menu-sepbox {
// 		width: 100%;
// 		padding: 27px 20px 31px 20px;
// 	}



// }








// @media (max-width: 999px) {
// 	#navbar-collapse-1>ul>li {
// 		border-bottom: 0;
// 	}
// }

// .nav>li>a {
// 	padding-left: 18px;
// 	padding-right: 18px;
// }

// .navbar-default .navbar-nav>li>a {
// 	color: $dark;
// 	font-size: 15px;
// 	font-weight: 400;
// 	text-transform: uppercase;
// 	font-family: 'Roboto', sans-serif;
// }


// .navbar-toggle {
// 	position: relative;
// 	float: none;
// 	padding: 7px 5px;
// 	margin-top: 16px;
// 	margin-right: 0px;
// 	margin-bottom: 0;
// 	background-color: transparent;
// 	border: 0px solid transparent;
// 	border-radius: 0px;
// 	margin-left: 0px;
// 	background: #272727;
// 	cursor: pointer;
// 	float: right;
// }

// .navbar-toggle>span {
// 	Color: #fff;
// }

// .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
// 	background-color: #272727;
// }

// .navbar-collapse {
// 	border-top: 0;
// }

// @media only screen and (min-width: 99px) and (max-width: 999px) {
// 	li.dropdown ul.dropdown-menu li a {
// 		color: #fff;
// 	}

// 	li.dropdown ul.dropdown-menu li a:hover {
// 		color: #333;
// 		border-bottom: none;
// 	}

// 	li.dropdown a:hover {
// 		color: #fff;
// 	}

// 	li.dropdown ul.dropdown-menu .yamm-content li a:hover {
// 		color: #999;
// 	}

// }

// @media (min-width: 1000px) {
// 	.yamm ul.nav li.dropdown>ul.dropdown-menu {

// 		/*opacity: 0;
//         filter:alpha(opacity=100);*/
// 		/* -webkit-transition: all 0.5s ease;
// 	-moz-transition: all 0.5s ease;
// 	-o-transition: all 0.5s ease;*/
// 	}


// 	.dropdown-submenu:hover>.dropdown-menu {
// 		-webkit-animation-duration: 0.5s;
// 		-webkit-animation-name: fadeInFromNone;
// 		-webkit-animation-fill-mode: forwards;

// 		animation-duration: 0.5s;
// 		animation-name: fadeInFromNone;
// 		animation-fill-mode: forwards;

// 		-ms-animation-duration: 0.5s;
// 		-ms-animation-name: fadeInFromNoneIE;
// 		-ms-animation-fill-mode: forwards;


// 	}

// 	.yamm ul.nav li.dropdown:hover>ul.dropdown-menu {
// 		display: block;

// 		/*-webkit-transition: all 0.5s ease;
// 	-moz-transition: all 0.5s ease;
// 	-o-transition: all 0.5s ease;*/

// 		animation-duration: 0.5s;
// 		animation-name: fadeInFromNone;
// 		animation-fill-mode: forwards;
// 		-webkit-animation-duration: 0.5s;
// 		-webkit-animation-name: fadeInFromNone;
// 		-webkit-animation-fill-mode: forwards;

// 		-ms-animation-duration: 5s;
// 		-ms-animation-name: fadeInFromNoneIE;
// 		-ms-animation-fill-mode: forwards;

// 	}

// 	@-webkit-keyframes fadeInFromNone {
// 		0% {
// 			opacity: 0;
// 		}

// 		1% {
// 			opacity: 0;
// 		}

// 		100% {
// 			opacity: 1;
// 		}
// 	}

// 	@keyframes fadeInFromNoneIE {
// 		0% {
// 			opacity: 0;
// 		}

// 		1% {
// 			opacity: 0;
// 		}

// 		100% {
// 			opacity: 1;
// 		}
// 	}

// 	@keyframes fadeInFromNone {
// 		0% {
// 			opacity: 0;
// 		}

// 		1% {
// 			opacity: 0;
// 		}

// 		100% {
// 			opacity: 1;
// 		}
// 	}


// 	/*.yamm ul.nav li.dropdown:hover > a {
//     color: #13afeb;
// }*/
// 	.navbar-default .navbar-nav>.open>a, 	.navbar-default .navbar-nav>.open>a:hover, 	.navbar-default .navbar-nav>.open>a:focus {
// 		padding: 22px 18px;
// 	}
// }

// .navbar-toggle>button {
// 	background: none;
// 	border: 0;
// 	float: right;
// }

// .navbar-default .navbar-toggle i {
// 	color: #fff;
// 	font-size: 21px;
// }

// .yamm ul.nav li.dropdown:hover b {
// 	border-top-color: #fff;
// 	border-bottom-color: #fff;
// }

// .container>.navbar-collapse {
// 	margin-right: 0px;
// }

// .navbar-brand, .navbar-nav>li>a {
// 	text-shadow: 0 0px 0 rgba(255, 255, 255, 0.25);
// }

// button.navbar-toggle {
// 	clear: right;
// }

// .width50 {
// 	width: 50%;
// }

// /* -------------- color Scheme Start --------------*/

// /*.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
// color: #fff;
// background-color: #454545;

// }*/

// .navbar-default .navbar-nav>.open>a .caret, .navbar-default .navbar-nav>.open>a:hover .caret, .navbar-default .navbar-nav>.open>a:focus .caret {
// 	border-top-color: #fff;
// 	border-bottom-color: #fff;
// }

// /* -------------- color Scheme End --------------*/

// .dropdown-menu.two {
// 	right: 0;
// }



// @media only screen and (min-width: 99px) and (max-width: 999px) {

// 	.navbar-default .navbar-nav>li>a {
// 		color: #999;
// 	}

// }





// @media only screen and (max-width:1199px) {

// 	.container>.navbar-collapse {
// 		z-index: 9999;
// 	}

// 	.navbar-default.two {
// 		margin-right: 200px;
// 	}

// 	.navbar-default {
// 		margin-right: 0px;
// 	}

// 	.nav>li>a {
// 		padding-left: 10px;
// 		padding-right: 10px;
// 	}

// }

// @media only screen and (max-width:1112px) {

// 	.container>.navbar-collapse {
// 		z-index: 9999;
// 	}

// 	.navbar-default.two {
// 		margin-right: 227px;
// 	}

// }



// @media only screen and (max-width:800px) {

// 	.navbar-default.two {
// 		margin-right: 0px;
// 	}


// }