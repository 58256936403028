@use 'sass:math';

header {
  .headerTop {
    height: $headerTopHeight;
    overflow: hidden;
    font-size: 0.8rem;

 	 @include main-transition;


    .select-language {
      padding: 0;
      margin: 0;
      width: 100px;
      border-radius: 2px;
      overflow: hidden;

      select {
        font-size: 0.8rem;
        letter-spacing: 0.5px;
        padding: 4px 10px;
        width: 100%;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: normal;
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    button {
      float: right;
      padding: 8px 12px 8px 12px;
      border-radius: 2px;
      margin-left: 10px;
      transition: all 0.3s ease;
      border: none;

      svg {
        color: #a0a5a9;
        transition: all 0.3s ease;
        margin-right: 5px;
      }

      &:hover {
        color: white;
        background: $primary;

        svg {
          color: white;
        }
      }
    }

    ul {
      float: right;
      padding: 0px;
      margin: 0px;

      >li {
        float: left;
        padding: 0px;
        margin: 0px 23px 0px 0px;
        letter-spacing: 0.3px;

        img {
          float: left;
          // margin-top: 6px;
          margin-right: 5px;
          opacity: 0.4;
        }

        svg {
          float: left;
          margin-top: 2px;
          margin-right: 5px;
        }

        strong {
          font-size: 0.9rem;
        }
      }
    }

    a {
      // color: white;
      color: white;
      border-radius: 100%;
      text-align: center;
      vertical-align: middle;
      transition: all 0.3s ease;
      margin-right: 0px;

      &:hover {
        color: #999;
      }
    }

  }

  .headerMain {
    height: $headerMainHeight;
    margin: 0;
    background: white;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -webkit-border-radius: 0;
    border-radius: 0;
    border-spacing: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: normal;
    -webkit-tap-highlight-color: transparent;
    outline: 0 !important;
    -webkit-touch-callout: none;
 	  @include main-transition;
    z-index: 9800 !important;
    background-color: transparent;
    background: white;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    .logo {
      display: flex;
      flex-grow: 0;
      width: $headerLogoWidth;
      height: $headerLogoHeight;
    }
  }

  &.compact {
    .headerTop {
      height: 0;
    }

    .headerMain {
      height: $headerMainComapctHeight;
    }

    .logo {
      width: $headerCompactLogoWidth;
      height: $headerCompactLogoHeight;
    }
  }

  @include media-breakpoint-only(xs) {
    .headerTop {
      font-size: 0rem;

      .select-language {
        width: 95%;

        select {
          padding: 7px 5px;
          width: 500px;
        }
      }

      button {
        > svg {
          margin: 0;
          font-size: 0.7rem;
        }
        > span {
          display: none;
       }
      }

      ul {
        >li {
          margin-right: 10px;
          strong {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(sm) {
    .headerTop {
      font-size: 0.7rem;
      button {
        > svg {
          margin: 0;
        }
        > span {
          display: none;
       }
      }

      ul {
        >li {
          margin-right: 10px;
          strong {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
  }

  @include media-breakpoint-only(lg) {
  }

  @include media-breakpoint-only(xl) {
  }

  @include media-breakpoint-only(xxl) {
  }
}