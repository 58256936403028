// Variables
@import '../../../assets/scss/variables/variables';

$fallbackContainerSize: 120px;
$componentContainerSizeXS: 15px;
$componentContainerSizeSM: 30px;
$componentContainerSizeMD: 60px;
$componentContainerSizeLG: 90px;
$componentContainerSizeXL: 120px;
$fallbacklogoWidth: 60px;
$fallbacklogoHeight: 60px;
$backgroundColor: hsla(0, 100%, 100%, 0.8);

.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $backgroundColor;
  z-index: 9700;

  .fallback-container {
    width: $fallbackContainerSize;
    height: $fallbackContainerSize;
    position: relative;
  }

  .component-container {
    position: relative;
    width: $componentContainerSizeMD;
    height: $componentContainerSizeMD;
    &.size-xs {
      width: $componentContainerSizeXS;
      height: $componentContainerSizeXS;
    }
    &.size-sm {
      width: $componentContainerSizeSM;
      height: $componentContainerSizeSM;
    }
    &.size-lg {
      width: $componentContainerSizeLG;
      height: $componentContainerSizeLG;
    }
    &.size-xl {
      width: $componentContainerSizeXL;
      height: $componentContainerSizeXL;
    }
  }

  .loading-logo {
    position: absolute;
    width: $fallbacklogoWidth;
    height: $fallbacklogoHeight;
    top: calc(50% - $fallbacklogoHeight / 2);
    left: calc(50% - $fallbacklogoWidth / 2);
  }

  .effect-1,
  .effect-2,
  .effect-3 {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-left: 3px solid $primary;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  .effect-1 {
    -webkit-animation: rotate 1s ease infinite;
    animation: rotate 1s ease infinite;
  }
  .effect-2 {
    -webkit-animation: rotateOpacity 1s ease infinite 0.1s;
    animation: rotateOpacity 1s ease infinite 0.1s;
  }
  .effect-3 {
    -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
    animation: rotateOpacity 1s ease infinite 0.2s;
  }
}


@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}