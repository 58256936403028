@font-face {
  font-family: "Dancing Script";
  src: url('../../fonts/dancingScript/DancingScript-VariableFont_wght.ttf') format('truetype');
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: $text-color-primary;
  &.light {
    font-weight: 300;
  }
  a {
    color: inherit;
  }
}

h1 {
  font-size: 2.188rem;
  line-height: 2.188rem;
  margin-bottom: 1.875rem;
}

h2 {
  font-size: 1.688rem;
  line-height: 2rem;
  margin-bottom: 1.25rem;
}

h3 {
  font-size: 1.313rem;
  line-height: 1.75rem;
  margin-bottom: 1.125rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.563rem;
  margin-bottom: 0.625rem;
  font-style: normal;
}

h5 {
  font-size: 1rem;
  line-height: 1.438rem;
  margin-bottom: 0.75rem;
  font-style: normal;
}

h6 {
  font-size: 0.875rem;
  line-height: 1.438rem;
  margin-bottom: 0.75rem;
  font-style: normal;
}

.white {
  color: $white;
}

em {
  color: $primary;
}

strong, .bold, .strong {
  font-weight: bold;
}

small, .small {
  font-size: 0.75rem;
}

.medium {
  font-size: 1.5rem;
}

.big {
  font-size: 2rem;
}

/* Dancing Script Font */
.dncfont {
  font-size: 1.125rem;
  font-weight: bold;
  font-family: 'Dancing Script', cursive;
  line-height: 1.688rem;
}


/* Shortcode Titles */
.stcode_title {
  > h3 > span {
    background-color: $white;
    padding-right: 1.125rem;
    font-size: 1.688rem;
    text-align: left;
    font-weight: normal;
  }
  &.type1 {
    h3 {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none' style='width:100%; height:100%;'><line x1='0' y1='60' x2='100' y2='60' stroke='" + str-replace(#{$dark}, '#', '%23') + "' stroke-width='1' /></svg>");
    }
  }
  &.type2 {
    h3 {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none' style='width:100%; height:100%;'><line x1='0' y1='45' x2='100' y2='45' stroke='" + str-replace(#{$dark}, '#', '%23') + "' stroke-width='1' /><line x1='0' y1='60' x2='100' y2='60' stroke='" + str-replace(#{$dark}, '#', '%23') + "' stroke-width='1' /></svg>");
    }
  }
  &.type3 {
    h3 {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none' style='width:100%; height:100%;'><line x1='0' y1='60' x2='100' y2='60' stroke='" + str-replace(#{$gray-200}, '#', '%23') + "' stroke-width='20' /></svg>");
    }
  }
  &.type4 {
    h3 {
      padding-bottom: 1rem;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none' style='width:100%; height:100%;'><line x1='0' y1='96' x2='15' y2='96' stroke='" + str-replace(#{$primary}, '#', '%23') + "' stroke-width='4' /></svg>");
    }
  }
  &.type5 {
    h3 {
      padding-bottom: 1rem;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none' style='width:100%; height:100%;'><line x1='0' y1='92' x2='15' y2='92' stroke='" + str-replace(#{$primary}, '#', '%23') + "' stroke-width='8' /><line x1='15' y1='92' x2='100' y2='92' stroke='" + str-replace(#{$gray-400}, '#', '%23') + "' stroke-width='2' /></svg>");
    }
  }
  &.type6 {
    text-align: center;
    h3 {
      font-size: 1.7rem;
      b {
      font-weight: bold;
      }
      strong {
        color: #fff;
        padding: 0rem 0.625rem 0.188rem 0.625rem;
        background-color: $dark;
      }
    }
  }
  &.type7 {
    text-align: center;
    em {
      display: block;
      font-size: 1rem;
      margin-top: 0.313rem;
      font-style: normal;
      font-weight: 400;
      color: $gray-800;
    }
    .line {
      background: $primary;
      height: 0.188rem;
      display: block;
      bottom: 0rem;
      width: 6.25rem;
      margin: 1.125rem auto 0 auto;
    }
  }
  &.type8 {
    h3 {
      font-size: 2.188rem;
      position: relative;
      text-align: center;
      font-weight: 300;
      z-index: 1;
      .text{
        padding: 0 1.2rem;
        background-color: $white;
        // background-color: red;
        z-index: 20;
        position: relative;
      }
      .line {
        background: url(../images/dividers/h-line.jpg) no-repeat center top;
        position: absolute;
        height: 0.063rem;
        display: block;
        top: 1.125rem;
        width: 100%;
        margin: 0 auto;
      }
    }

  }
  &.type9 {
    text-align: center;
    em {
      display: block;
      font-size: 1.125rem;
      margin-top: 0.313rem;
      font-style: normal;
      font-weight: 400;
      color: $gray-800;
    }
    .line {
      background: url(../images/dividers/h-line2.png) no-repeat center top;
      height: 0.188rem;
      display: block;
      bottom: 0rem;
      width: 13.125rem;
      margin: 1.125rem auto 0 auto;
    }
  }
  &.type10 {
    text-align: center;
    em {
      display: block;
      font-size: 1.125rem;
      margin-top: 0.313rem;
      font-style: normal;
      font-weight: 400;
      color: $gray-800;
    }
    .line {
      background: url(../images/dividers/h-line3.png) no-repeat center 0;
      height: 2.438rem;
      display: block;
      bottom: 0rem;
      width: 18.75rem;
      margin: 0.5rem auto;
    }
    svg {
      margin: 0 auto;
      padding: 0rem 0 0.5rem 1rem;
      width: 2.1rem;
      height: 2.1rem;
      display: block;
      color: #999;
      font-size: 0.875rem;
      line-height: 3.125rem;
    }
  }
  &.type11 {
    text-align: center;
    em {
      display: block;
      font-size: 1.125rem;
      margin-top: 0.313rem;
      font-style: normal;
      font-weight: 400;
      color: $gray-800;
    }
    .line {
      background: url(../images/dividers/h-line8.png) no-repeat center top;
      height: 0.438rem;
      display: block;
      bottom: 0rem;
      width: 8.75rem;
      margin: 0.438rem auto 0 auto;
    }
    .line2 {
      background: url(../images/dividers/h-line9.png) no-repeat center top;
      height: 0.438rem;
      display: block;
      bottom: 0rem;
      width: 8.75rem;
      margin: 1.125rem auto 0 auto;
    }
    .line3 {
      background: url(../images/dividers/h-line8.png) no-repeat center top;
      height: 0.438rem;
      display: block;
      bottom: 0rem;
      width: 8.75rem;
      margin: 1.25rem auto 0 auto;
    }
    .line4 {
      background: url(../images/dividers/h-line8.png) no-repeat center top;
      height: 0.438rem;
      display: block;
      bottom: 0rem;
      width: 8.75rem;
      margin: 1rem auto 0 auto;
    }
  }
  &.type12 {
    h3 {
      font-size: 2.188rem;
      z-index: 1;
      position: relative;
      text-align: center;
      font-weight: 300;
      margin-bottom: 0.875rem;
      color: $primary;
    }
    h4 {
      font-size: 1.375rem;
      z-index: 1;
      position: relative;
      text-align: center;
      font-weight: 400;
      color: $gray-800;
    }
    .line {
      background: url(../images/dividers/h-line.jpg) no-repeat center top;
      position: absolute;
      height: 0.063rem;
      display: block;
      top: 0.875rem;
      width: 100%;
      margin: 0 auto;
    }
    .text {
      background-color: #FFFFFF;
      z-index: 20;
      position: relative;
      text-align: center;
      padding: 0rem 1.25rem;
    }
  }

  // &.type13 {

  // }
  // &.type14 {

  // }
  // &.type15 {

  // }
}


// .stcode_title13 {
//   padding: 0.063rem 0.75rem;
//   font-size: 2.5rem;
//   color: #f7ca18;
//   font-family: 'Roboto', sans-serif;
//   font-weight: 800;
//   text-transform: uppercase;
//   border-top: 0.063rem solid #f7ca18;
//   border-left: 0.313rem solid #f7ca18;
//   border-right: 0.313rem solid #f7ca18;
//   border-bottom: 0.063rem solid #f7ca18;
//   background: #fff;
// }


// .stcode_title14 {
//   text-align: center;
// }

// .stcode_title14 em {
//   display: block;
//   font-size: 1.125rem;
//   font-style: normal;
//   font-weight: 400;
//   margin-bottom: 0.063rem;
// }

// .stcode_title14 strong {
//   font-weight: 800;
// }

// .stcode_title14 .line {
//   background: #d64541;
//   height: 0.125rem;
//   display: block;
//   bottom: 0rem;
//   width: 8.75rem;
//   margin: 1rem auto 0 auto;
// }

// .stcode_title14 .line.white {
//   background: #fff;
// }


// .stcode_title15 {
//   text-align: center;
// }

// .stcode_title15 .line {
//   background: #181818;
//   height: 0.125rem;
//   display: block;
//   bottom: 0rem;
//   width: 3.125rem;
//   margin: 0.313rem auto 0 auto;
// }
