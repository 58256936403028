.scrollup {
	position: fixed;
	width: 40px;
	height: 40px;
	right: 40px;
	bottom: 40px;
	z-index: 9001;
	cursor: pointer;
	border: none;
	outline: none;

	@include media-breakpoint-down(sm) {
		width: 30px;
		height: 30px;
		right: 20px;
		bottom: 30px;	
	}
}