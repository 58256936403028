.smallScrollbar {
  // Scrollbar styles
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: $gray-300;
    border-radius: 30px;
  }
}