.btn {

  &.btn-dark {
    color: $white;
    text-transform: uppercase;
    background: $dark;
    padding: 1rem 2.125rem;
    border-radius: 0.188rem;
    transition: all 0.3s ease;
    border: none;
    &:hover {
      background: $primary;
    }
  }

  &.btn-light {
    color: $gray-800;
    text-transform: uppercase;
    background: $light;
    padding: 1rem 2.125rem;
    border-radius: 0.188rem;
    transition: all 0.3s ease;
    border: none;
    &:hover {
      color: $white;
      background: $primary;
    }
  }

  &.btn-primary {
    color: $white;
    background: $primary;
    border-radius: 0.188rem;
    transition: all 0.3s ease;
    &:hover {
      background: darken($primary, 5);
    }
  }

  &.btn-hover-light:hover {
    color: $primary;
    background: $light;
  }

  &.btn-hover-dark:hover {
    color: $white;
    background: $dark;
    border-color: $dark;
  }

  &.btn-compact {
    padding: 0.625rem 1.625rem;
  }

  &.btn-showHide {
    border: $input-border-width solid $input-border-color;
    color: $input-color;
  }
}