@mixin main-transition {
	transition-property: all;
	-moz-transition-property: all;
	-webkit-transition-property: all;
	-o-transition-property: all;

	transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;

  transition-duration: .2s;
	-moz-transition-duration: .2s;
	-webkit-transition-duration: .2s;
	-o-transition-duration: .2s;
}

@mixin animationMixin($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}