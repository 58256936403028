//-----------------//
// Horizontal menu //
//-----------------//
#horizontal-menu {
  margin: auto 0;
  z-index: 9999 !important;
  line-height: 1;
  padding: 0;

  .navbar-toggler {
    background-color: rgba(0,0,0,.05);
    border-color: rgba(0,0,0,.2);
    .navbar-toggler-icon {
      background-image: $navbar-light-toggler-icon-bg;
    }
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .nav-link, .dropdown-item, .dropdown-toggle {
    display: flex;
    align-items: center;
    > span {
      flex-grow: 1;
    }
  }

  .nav-item {
    > a > * {
      display: flex;
    }
    > .nav-link {
      margin-left: 0.715rem;
      color: $text-color-primary;
      font-size: 0.95rem;
      &.dropdown-toggle::after {
        background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $text-color-primary), '#', '%23')) !important;
        width: 14px;
        height: 11px;
      }
      i,
      svg {
        margin-right: 0.5rem;
        height: 1rem;
        width: 1rem;
        font-size: 1rem;
      }
      &.active {
        background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
        box-shadow: 0px 0px 6px 1px rgba($primary, 0.6);
        color: $white;
        border-radius: 4px;
        &::after {
          background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $white), '#', '%23')) !important;
        }
      }
    }

    &:hover {
      > a:not(.active), > .nav-link:not(.active) {
        color: $text-color-secondary;
        background: $gray-100;
        border-radius: 4px;
        &::after {
          background-image: url(str-replace(str-replace($chevron-down, 'currentColor', $text-color-secondary), '#', '%23')) !important;
        }
      }
    }
  }

  .dropdown-toggle::after {
    border: none !important;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 16px;
    content: " ";
  }

  .dropdown-menu {
    box-shadow: $box-shadow;
    border: none;
    min-width: $menu-dropdown-min-width;
    max-width: $menu-dropdown-max-width;
    width: auto;
    padding: 0;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    .nav-link, .dropdown-item {
      font-size: 0.85rem;
      height: 2.2rem;
      margin: 0;
      color: $text-color-primary;
      & > * {
        transition: all 0.2s ease;
      }
      &:not(.active):hover > * {
        color: $text-color-secondary;
        transform: translateX(5px);
        transition: transform 0.2s ease;
      }
      i,
      svg {
        margin-right: 0.5rem;
        height: 0.85 rem;
        width: 0.85rem;
        font-size: 0.85rem;
        box-sizing: border-box;
      }
    }

    .dropdown-toggle {
      padding: 0.25rem 1rem;
      &::after {
        width: 14px;
        height: 11px;
        background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $text-color-primary), '#', '%23')) !important;
      }
      &.active::after, &:hover::after {
        background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $text-color-secondary), '#', '%23')) !important;
      }
    }

    &.dropdown-group {
      color:$text-color-primary;
      padding: 50px 40px 40px 40px;
      min-width: $menu-dropdown-big-min-width;
      max-width: $menu-dropdown-big-max-width;
      box-shadow: $box-shadow;

      .section-box {
        &.small {
          font-size: 0.95rem;
          letter-spacing: 0.5px;
          .title {
            text-transform: uppercase;
            font-weight: 500;
            margin-left: 1.2rem;
            margin-bottom: 1.2rem;
          }
        }
        &.big {
          li {
            padding: 0.5rem 0;
            > a > * {
              display: block;
            }
            &.active svg {
              border-right: 1px solid $primary;
            }
          }
          a:hover {
            > * {
              transform: none;
            }
            > svg {
              border-right: 1px solid $primary;
            }
          }
          svg {
            width: 3rem;
            height: auto;
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 1px solid $text-color-primary;
          }
          span {
            font-size: 1.15rem;
            font-weight: 500;
            p {
              font-size: 0.8rem;
              font-weight: 400;

              // text-transform: uppercase;
              // font-weight: 500;
              // margin-left: 1.2rem;
              // margin-bottom: 1.2rem;
            }
            .badge {
              position: absolute;
              font-size: 0.8rem;
              right: 0px;
              top: 25px;
            }
          }
        }
      }

      .separator {
        border-right: 1px solid #eee;
      }
    }

    .dropdown-submenu {
      position: relative;
      & > .dropdown-menu {
        position: absolute;
        top: 0 !important;
        left: 100% !important;
      }
    }

    .dropdown-menu {
      &.openLeft {
          left: auto !important;
          right: 100% !important;
      }
    }
  }

  .nav-item, .dropdown-submenu {
    > .active {
      color: $text-color-secondary;
      background: $gray-100;
    }
    &.disabled {
      pointer-events: none !important;
      a {
        color: $gray-500;
        &.dropdown-toggle::after {
          background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $gray-500), '#', '%23')) !important;
        }
      }
    }
  }

  .badge {
    transform: translate(5px, -8px);
  }

  @include media-breakpoint-down(xl) {
    .nav-item {
      i,
      svg {
        display: none;
      }
    }
  }
}

// menu scroll shadow
.shadow-bottom {
  display: none;
  position: absolute;
  height: 50px;
  width: 100%;
  pointer-events: none;
  margin-top: 4rem;
  background: linear-gradient(#fff 41%, hsla(0, 0%, 100%, 0.11) 95%, hsla(0, 0%, 100%, 0));
}

//-----------------//
// Vertical menu //
//-----------------//
.offcanvas-body {
  padding: 1rem 0;
}

#vertical-menu {
  box-shadow: 0 0 15px 0 rgba($black, 0.05);
  z-index: 9999 !important;
  .navigation-main {
    padding-right: 0.5rem;
  }

  li > .dropdown-divider {
    display: none;
  }

  .nav-item {
    padding-left: 0.5rem;
    > a {
      padding: 0.5rem;
      font-size: 0.95rem;
      color: $text-color-primary;
      border-radius: 4px;

      &.active {
        // background-color: $primary;
        background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
        box-shadow: 0 0 10px 1px rgb($primary, 0.7);
        color: $white;
      }

      &:not(.active):hover > * {
        // color: $text-color-secondary;
        transform: translateX(5px);
        transition: transform 0.2s ease;
      }

      > * {
        display: flex;
      }

      svg {
        width: 18px;
        height: auto;
        margin-right: 8px;
      }

      .badge {
        font-size: 0.7rem;
        margin-left: 3px;
        margin-top: -6px;
        font-weight: 400;
      }

    }

    &.menu-collapsed-open > a {
      background: linear-gradient(118deg, rgba($gray-100, 1), rgba($gray-100, 0.7));
      box-shadow: 0 0 10px 1px rgb($gray-100, 0.7);
    }

    &.sidebar-group-active > a {
      background: linear-gradient(118deg, rgba($gray-300, 1), rgba($gray-300, 0.7));
      box-shadow: 0 0 10px 1px rgb($gray-300, 0.7);
    }

    &.has-sub {
      > a:after {
        display: flex;
        margin-left: auto;
        // flex: 1 1 auto;
        content: "";
        background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $body-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: right;
        background-size: 1rem;
        height: 1rem;
        width: 1rem;
        float: left;
        transform: rotate(0deg);
        transition: all 0.2s ease-out;
      }
    }

    &.menu-collapsed-open:not(.menu-item-closing) {
      > a:after {
        transform: rotate(90deg);
      }
    }

    &.disabled {
      pointer-events: none !important;
      a {
        color: $gray-500;
      }
      &.has-sub a:after {
        background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $gray-500), '#', '%23')) !important;
      }
    }
  }
  .menu-content {
    border-left: 1px solid $primary;
    margin-left: 1rem;

    transform-origin: bottom center;
    animation: growDown 200ms ease-in-out reverse forwards;
    &.show {
      transform-origin: top center;
      animation: growDown 200ms ease-in-out forwards;
    }
    &.group {
      .component {
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
      }
      // > li {
        .big {
          > a > * {
            display: block;
          }
          > a.active svg {
            border-right: 1px solid $white;
          }
          svg {
            width: 2.8rem;
            height: auto;
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 1px solid $text-color-primary;
          }
          span {
            p {
              font-size: 0.8rem;
            }
          }
        }

        .small {
          font-size: 0.95rem;
          letter-spacing: 0.5px;
          .title {
            text-transform: uppercase;
            font-weight: 500;
            margin-left: 1.2rem;
            margin-bottom: 1.2rem;
          }
        }
      // }

    }
  }
}

// .nav-item:has(a) {
//   background-color: $primary;
//   background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
//   box-shadow: 0 0 10px 1px rgb($primary, 0.7);
//   color: $white;
// }
