footer {
  margin-top: 2rem;
  color: #aaa;
  font-size: 0.9rem;
  line-height: 1.3rem;

  a {
    color: #aaa;
    transition: all 0.3s ease;
    &:hover {
      color: $white;
    }
  }

  .ftop {
    background: url('../images/footer/footer-bg-top.png') no-repeat center top;
    width: 100%;
    .left {
      text-align: right;
      padding-top: 32px;
      padding-right: 80px;

      h1 > a {
        color: $primary;
        font-size: 35px;
        font-weight: 900;
        letter-spacing: 1px;
      }

      h4 {
        margin-bottom: 5px;
      }
    }

    .right {
      padding-top: 32px;
      p {
        color: $white;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 11px;
      }

      .newsle_eminput {
        font-family: 'Roboto', sans-serif;
        padding: 3px 0px 4px 20px;
        height: 50px;
        width: 70%;
        border-radius: 3px;
        border: none;
        color: $gray-600;
        font-size: 14px;
      }

    }
  }

  .secarea {
    width: 100%;
    padding: 75px 0px 67px 0px;
    background: $dark url('../images/footer/footer-bg-graph.png') no-repeat center bottom;
    h4 {
      margin-bottom: 14px;
    }
  }

  .foolist {
    width: 100%;
    padding: 0px;
    margin: 0px;

    li {
      padding: 4px 0px;
      margin: 0px;
    }
  }

  .address {
    width: 100%;
    text-align: right;

    b {
      font-weight: normal;
    }
  }

  ul.foosocial {
    width: 100%;
    padding: 0px;
    margin: 7px 0px 0px 0px;
    li {
      margin: 0px 7px 0px 0px;
      padding: 0px;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.facebook {
        background-color: #2b87c7;
      }
      &.twitter {
        background-color: #38c0dd;
      }
      &.gplus {
        background-color: #dc6218;
      }
      &.youtube {
        background-color: #b34300;
      }
      &.linkdin {
        background-color: #2862d8;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .copyrights {
    width: 100%;
    background: #1c2329;
    padding: 35px 0px 32px 0px;
    color: #8e9194;
    font-size: 13px;
    a {
      color: #8e9194;
      padding: 0px 5px;
      transition: all 0.3s ease;
      &:hover {
        color: $white;
      }
    }
  }


  @include media-breakpoint-only(xs) {
    .ftop {
      background-position: 30% 0px;

      .right .newsle_eminput {
          width: 60%;
      }
    }

    .secarea {
      padding-top: 0px;
      .address {
        text-align: left;
      }
    }
    .copyrights {
      text-align: center;
      .row > div:first-child {
        margin-bottom: 0.7rem;
      }
    }
  }

  @include media-breakpoint-only(sm) {
    .ftop {
      background-position: 15% 0px;
    }
  }

  @include media-breakpoint-only(md) {
    .ftop {
      background-position: 40% 0px;
      .container {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        .right .newsle_eminput {
          width: 60%;
        }
      }
    }
  }
  @include media-breakpoint-only(xxl) {
    .ftop {
      background-position: 40% 0px;
      background: url('../images/footer/footer-bg-top.png') no-repeat center top,
                  url('../images/footer/footerLeft.png') no-repeat left 0px / 40% 100%,
                  url('../images/footer/footerRight.png') no-repeat right 0px / 60% 100%;
    }
  }
}