.form-check-label {
  line-height: 1.6;
}

.form-switch-md {
  > .form-check-label {
    line-height: 1.875rem;
  }

  > .form-check-input {
    min-height: 1.125rem;
    width: 3rem;
    height: 1.5rem;
  }
}

.form-switch-xl {
  > .form-check-label {
    line-height: 2.5rem;
  }

  > .form-check-input {
    min-height: 1.5rem;
    width: 4rem;
    height: 2rem;
  }
}
.react-select {
  .select__control {
    &:hover {
      border: $input-border-width solid $input-border-color;
    }

    &.select__control--is-focused {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        box-shadow: $input-focus-box-shadow;
      }
    }

  }
  .select__menu-list {
    max-height: 10rem;
    >.select__option--is-focused {
      background-color: lighten($primary, 40);
    }
    >.select__option--is-selected {
      background-color: $primary;
      color: $white;
    }
  }
  &.is-valid {
    .select__control {
      border-color: $green;
      background-image: $form-feedback-icon-valid;
      background-repeat: no-repeat;
      background-position: right 4.75rem center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      &.select__control--is-focused {
        box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($green, $input-btn-focus-color-opacity);
      }
    }
  }
  &.is-invalid {
    .select__control {
      border-color: $red;
      background-image: $form-feedback-icon-invalid;
      background-repeat: no-repeat;
      background-position: right 4.75rem center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      &.select__control--is-focused {
        box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($red, $input-btn-focus-color-opacity);
      }
    }
  }
}