@include animationMixin(growDown) {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
  opacity: 0;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ie .animate, .no-js .animate, .onHoverBounce, .onHoverExplode, .onHoverFlash, .onHoverHeartbeat, .onHoverPanic, .onHoverPullback, .onHoverPulsate, .onHoverPulse, .onHoverShake, .onHoverSpin, .onHoverStrobe, .onHoverTada, .onHoverWave, .onHoverWobble, .trigger {
  opacity: 1;
}
.animate.infinite, .onHoverBounce.infinite, .onHoverExplode.infinite, .onHoverFlash.infinite, .onHoverHeartbeat.infinite, .onHoverPanic.infinite, .onHoverPullback.infinite, .onHoverPulsate.infinite, .onHoverPulse.infinite, .onHoverShake.infinite, .onHoverSpin.infinite, .onHoverStrobe.infinite, .onHoverTada.infinite, .onHoverWave.infinite, .onHoverWobble.infinite, .trigger.infinite {
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@include animationMixin(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate.fadeIn, .trigger.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@include animationMixin(fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
.animate.fadeInUp, .trigger.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@include animationMixin(fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
.animate.fadeInDown, .trigger.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@include animationMixin(fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
.animate.fadeInLeft, .trigger.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@include animationMixin(fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
.animate.fadeInRight, .trigger.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@include animationMixin(fadeInUpLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
.animate.fadeInUpLarge, .trigger.fadeInUpLarge {
  -webkit-animation-name: fadeInUpLarge;
  -moz-animation-name: fadeInUpLarge;
  -ms-animation-name: fadeInUpLarge;
  -o-animation-name: fadeInUpLarge;
  animation-name: fadeInUpLarge;
}
@include animationMixin(fadeInDownLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
.animate.fadeInDownLarge, .trigger.fadeInDownLarge {
  -webkit-animation-name: fadeInDownLarge;
  -moz-animation-name: fadeInDownLarge;
  -ms-animation-name: fadeInDownLarge;
  -o-animation-name: fadeInDownLarge;
  animation-name: fadeInDownLarge;
}
@include animationMixin(fadeInLeftLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
.animate.fadeInLeftLarge, .trigger.fadeInLeftLarge {
  -webkit-animation-name: fadeInLeftLarge;
  -moz-animation-name: fadeInLeftLarge;
  -ms-animation-name: fadeInLeftLarge;
  -o-animation-name: fadeInLeftLarge;
  animation-name: fadeInLeftLarge;
}
@include animationMixin(fadeInRightLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
.animate.fadeInRightLarge, .trigger.fadeInRightLarge {
  -webkit-animation-name: fadeInRightLarge;
  -moz-animation-name: fadeInRightLarge;
  -ms-animation-name: fadeInRightLarge;
  -o-animation-name: fadeInRightLarge;
  animation-name: fadeInRightLarge;
}
@include animationMixin(fadeOut) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animate.fadeOut, .trigger.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -ms-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}
@include animationMixin(fadeOutUp) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
}
.animate.fadeOutUp, .trigger.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -ms-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
@include animationMixin(fadeOutDown) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
}
.animate.fadeOutDown, .trigger.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -ms-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
@include animationMixin(fadeOutLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
  }
}
.animate.fadeOutLeft, .trigger.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -ms-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
@include animationMixin(fadeOutRight) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(100px);
  }
}
.animate.fadeOutRight, .trigger.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -ms-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
@include animationMixin(fadeOutUpLarge) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
  }
}
.animate.fadeOutUpLarge, .trigger.fadeOutUpLarge {
  -webkit-animation-name: fadeOutUpLarge;
  -moz-animation-name: fadeOutUpLarge;
  -ms-animation-name: fadeOutUpLarge;
  -o-animation-name: fadeOutUpLarge;
  animation-name: fadeOutUpLarge;
}
@include animationMixin(fadeOutDownLarge) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1000px);
  }
}
.animate.fadeOutDownLarge, .trigger.fadeOutDownLarge {
  -webkit-animation-name: fadeOutDownLarge;
  -moz-animation-name: fadeOutDownLarge;
  -ms-animation-name: fadeOutDownLarge;
  -o-animation-name: fadeOutDownLarge;
  animation-name: fadeOutDownLarge;
}
@include animationMixin(fadeOutLeftLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(-1000px);
  }
}
.animate.fadeOutLeftLarge, .trigger.fadeOutLeftLarge {
  -webkit-animation-name: fadeOutLeftLarge;
  -moz-animation-name: fadeOutLeftLarge;
  -ms-animation-name: fadeOutLeftLarge;
  -o-animation-name: fadeOutLeftLarge;
  animation-name: fadeOutLeftLarge;
}
@include animationMixin(fadeOutRightLarge) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(1000px);
  }
}
.animate.fadeOutRightLarge, .trigger.fadeOutRightLarge {
  -webkit-animation-name: fadeOutRightLarge;
  -moz-animation-name: fadeOutRightLarge;
  -ms-animation-name: fadeOutRightLarge;
  -o-animation-name: fadeOutRightLarge;
  animation-name: fadeOutRightLarge;
}
@include animationMixin(zoomIn) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
.animate.zoomIn, .trigger.zoomIn {
  -webkit-animation-name: zoomIn;
  -moz-animation-name: zoomIn;
  -ms-animation-name: zoomIn;
  -o-animation-name: zoomIn;
  animation-name: zoomIn;
}
@include animationMixin(zoomInUp) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.zoomInUp, .trigger.zoomInUp {
  -webkit-animation-name: zoomInUp;
  -moz-animation-name: zoomInUp;
  -ms-animation-name: zoomInUp;
  -o-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
@include animationMixin(zoomInDown) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.zoomInDown, .trigger.zoomInDown {
  -webkit-animation-name: zoomInDown;
  -moz-animation-name: zoomInDown;
  -ms-animation-name: zoomInDown;
  -o-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
@include animationMixin(zoomInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.zoomInLeft, .trigger.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  -moz-animation-name: zoomInLeft;
  -ms-animation-name: zoomInLeft;
  -o-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
@include animationMixin(zoomInRight) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.zoomInRight, .trigger.zoomInRight {
  -webkit-animation-name: zoomInRight;
  -moz-animation-name: zoomInRight;
  -ms-animation-name: zoomInRight;
  -o-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
@include animationMixin(zoomInUpLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.zoomInUpLarge, .trigger.zoomInUpLarge {
  -webkit-animation-name: zoomInUpLarge;
  -moz-animation-name: zoomInUpLarge;
  -ms-animation-name: zoomInUpLarge;
  -o-animation-name: zoomInUpLarge;
  animation-name: zoomInUpLarge;
}
@include animationMixin(zoomInDownLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.zoomInDownLarge, .trigger.zoomInDownLarge {
  -webkit-animation-name: zoomInDownLarge;
  -moz-animation-name: zoomInDownLarge;
  -ms-animation-name: zoomInDownLarge;
  -o-animation-name: zoomInDownLarge;
  animation-name: zoomInDownLarge;
}
@include animationMixin(zoomInLeftLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.zoomInLeftLarge, .trigger.zoomInLeftLarge {
  -webkit-animation-name: zoomInLeftLarge;
  -moz-animation-name: zoomInLeftLarge;
  -ms-animation-name: zoomInLeftLarge;
  -o-animation-name: zoomInLeftLarge;
  animation-name: zoomInLeftLarge;
}
@include animationMixin(zoomInRightLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(1000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.zoomInRightLarge, .trigger.zoomInRightLarge {
  -webkit-animation-name: zoomInRightLarge;
  -moz-animation-name: zoomInRightLarge;
  -ms-animation-name: zoomInRightLarge;
  -o-animation-name: zoomInRightLarge;
  animation-name: zoomInRightLarge;
}
@include animationMixin(zoomOut) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
}
.animate.zoomOut, .trigger.zoomOut {
  -webkit-animation-name: zoomOut;
  -moz-animation-name: zoomOut;
  -ms-animation-name: zoomOut;
  -o-animation-name: zoomOut;
  animation-name: zoomOut;
}
@include animationMixin(zoomOutUp) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(-100px);
  }
}
.animate.zoomOutUp, .trigger.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  -moz-animation-name: zoomOutUp;
  -ms-animation-name: zoomOutUp;
  -o-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}
@include animationMixin(zoomOutDown) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(100px);
  }
}
.animate.zoomOutDown, .trigger.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  -moz-animation-name: zoomOutDown;
  -ms-animation-name: zoomOutDown;
  -o-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}
@include animationMixin(zoomOutLeft) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(-100px);
  }
}
.animate.zoomOutLeft, .trigger.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  -moz-animation-name: zoomOutLeft;
  -ms-animation-name: zoomOutLeft;
  -o-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}
@include animationMixin(zoomOutRight) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(100px);
  }
}
.animate.zoomOutRight, .trigger.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  -moz-animation-name: zoomOutRight;
  -ms-animation-name: zoomOutRight;
  -o-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
@include animationMixin(zoomOutUpLarge) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(-1000px);
  }
}
.animate.zoomOutUpLarge, .trigger.zoomOutUpLarge {
  -webkit-animation-name: zoomOutUpLarge;
  -moz-animation-name: zoomOutUpLarge;
  -ms-animation-name: zoomOutUpLarge;
  -o-animation-name: zoomOutUpLarge;
  animation-name: zoomOutUpLarge;
}
@include animationMixin(zoomOutDownLarge) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(1000px);
  }
}
.animate.zoomOutDownLarge, .trigger.zoomOutDownLarge {
  -webkit-animation-name: zoomOutDownLarge;
  -moz-animation-name: zoomOutDownLarge;
  -ms-animation-name: zoomOutDownLarge;
  -o-animation-name: zoomOutDownLarge;
  animation-name: zoomOutDownLarge;
}
@include animationMixin(zoomOutLeftLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(-1000px);
  }
}
.animate.zoomOutLeftLarge, .trigger.zoomOutLeftLarge {
  -webkit-animation-name: zoomOutLeftLarge;
  -moz-animation-name: zoomOutLeftLarge;
  -ms-animation-name: zoomOutLeftLarge;
  -o-animation-name: zoomOutLeftLarge;
  animation-name: zoomOutLeftLarge;
}
@include animationMixin(zoomOutRightLarge) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(1000px);
  }
}
.animate.zoomOutRightLarge, .trigger.zoomOutRightLarge {
  -webkit-animation-name: zoomOutRightLarge;
  -moz-animation-name: zoomOutRightLarge;
  -ms-animation-name: zoomOutRightLarge;
  -o-animation-name: zoomOutRightLarge;
  animation-name: zoomOutRightLarge;
}
@include animationMixin(bounceIn) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  70% {
    opacity: 1;
    -webkit-transform: scale(.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
.animate.bounceIn, .trigger.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -ms-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@include animationMixin(bounceInUp) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.1) translateY(-40px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(.9) translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.bounceInUp, .trigger.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -ms-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@include animationMixin(bounceInDown) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateY(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.1) translateY(40px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(.9) translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateY(0);
  }
}
.animate.bounceInDown, .trigger.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -ms-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@include animationMixin(bounceInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.1) translateX(40px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(.9) translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.bounceInLeft, .trigger.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -ms-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@include animationMixin(bounceInRight) {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.1) translateX(-40px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(.9) translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
  }
}
.animate.bounceInRight, .trigger.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -ms-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@include animationMixin(bounceOut) {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(.9);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
}
.animate.bounceOut, .trigger.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -ms-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}
@include animationMixin(bounceOutUp) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
}
.animate.bounceOutUp, .trigger.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -ms-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
@include animationMixin(bounceOutDown) {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
}
.animate.bounceOutDown, .trigger.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -ms-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
@include animationMixin(bounceOutLeft) {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
}
.animate.bounceOutLeft, .trigger.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -ms-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
@include animationMixin(bounceOutRight) {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
}
.animate.bounceOutRight, .trigger.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -ms-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
@include animationMixin(spinLeft) {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-380deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
.animate.spinLeft, .trigger.spinLeft {
  -webkit-animation-name: spinLeft;
  -moz-animation-name: spinLeft;
  -ms-animation-name: spinLeft;
  -o-animation-name: spinLeft;
  animation-name: spinLeft;
}
@include animationMixin(spinRight) {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(380deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
.animate.spinRight, .trigger.spinRight {
  -webkit-animation-name: spinRight;
  -moz-animation-name: spinRight;
  -ms-animation-name: spinRight;
  -o-animation-name: spinRight;
  animation-name: spinRight;
}
@include animationMixin(flash) {
  0%,   100%,   50% {
    opacity: 1;
  }
  25%,   75% {
    opacity: 0;
  }
}
.animate.flash, .onHoverFlash:hover, .trigger.flash {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -ms-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}
@include animationMixin(strobe) {
  0%,   100%,   20%,   40%,   60%,   80% {
    opacity: 1;
  }
  10%,   30%,   50%,   70%,   90% {
    opacity: 0;
  }
}
.animate.strobe, .onHoverStrobe:hover, .trigger.strobe {
  -webkit-animation-name: strobe;
  -moz-animation-name: strobe;
  -ms-animation-name: strobe;
  -o-animation-name: strobe;
  animation-name: strobe;
}
@include animationMixin(shake) {
  0%,   100% {
    -webkit-transform: translateX(0);
  }
  10%,   30%,   50%,   70%,   90% {
    -webkit-transform: translateX(-10px);
  }
  20%,   40%,   60%,   80% {
    -webkit-transform: translateX(10px);
  }
}
.animate.shake, .onHoverShake:hover, .trigger.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -ms-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}
@include animationMixin(bounce) {
  0%,   100%,   20%,   50%,   80% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
.animate.bounce, .onHoverBounce:hover, .trigger.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -ms-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}
@include animationMixin(tada) {
  0%,   100% {
    -webkit-transform: scale(1) rotate(0);
  }
  10%,   20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%,   50%,   70%,   90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%,   60%,   80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
}
.animate.tada, .onHoverTada:hover, .trigger.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -ms-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}
@include animationMixin(wave) {
  20% {
    -webkit-transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.animate.wave, .onHoverWave:hover, .trigger.wave {
  -webkit-animation-name: wave;
  -moz-animation-name: wave;
  -ms-animation-name: wave;
  -o-animation-name: wave;
  animation-name: wave;
}
@include animationMixin(spin) {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.animate.spin, .onHoverSpin:hover, .trigger.spin {
  -webkit-animation-name: spin;
  -moz-animation-name: spin;
  -ms-animation-name: spin;
  -o-animation-name: spin;
  animation-name: spin;
}
@include animationMixin(pullback) {
  0% {
    -webkit-transform: rotate(0deg);
  }
  20%,   30% {
    -webkit-transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.animate.pullback, .onHoverPullback:hover, .trigger.pullback {
  -webkit-animation-name: pullback;
  -moz-animation-name: pullback;
  -ms-animation-name: pullback;
  -o-animation-name: pullback;
  animation-name: pullback;
}
@include animationMixin(wobble) {
  0% {
    -webkit-transform: translateX(0px);
  }
  15% {
    -webkit-transform: translateX(-25px) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10px) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5px) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0px);
  }
}
.animate.wobble, .onHoverWobble:hover, .trigger.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -ms-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}
@include animationMixin(pulse) {
  0%,   100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
}
.animate.pulse, .onHoverPulse:hover, .trigger.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -ms-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}
@include animationMixin(pulsate) {
  0%,   100%,   50% {
    -webkit-transform: scale(1);
  }
  25%,   75% {
    -webkit-transform: scale(1.1);
  }
}
.animate.pulsate, .onHoverPulsate:hover, .trigger.pulsate {
  -webkit-animation-name: pulsate;
  -moz-animation-name: pulsate;
  -ms-animation-name: pulsate;
  -o-animation-name: pulsate;
  animation-name: pulsate;
}
@include animationMixin(heartbeat) {
  0%,   30%,   50%,   60%,   80% {
    -webkit-transform: scale(1);
  }
  40%,   70% {
    -webkit-transform: scale(1.1);
  }
}
.animate.heartbeat, .onHoverHeartbeat:hover, .trigger.heartbeat {
  -webkit-animation-name: heartbeat;
  -moz-animation-name: heartbeat;
  -ms-animation-name: heartbeat;
  -o-animation-name: heartbeat;
  animation-name: heartbeat;
}
@include animationMixin(panic) {
  0%,   100% {
    -webkit-transform: scale(1) rotate(0);
  }
  10%,   60% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  20%,   40% {
    -webkit-transform: scale(1) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  50%,   70%,   90% {
    -webkit-transform: scale(1) rotate(3deg);
  }
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
}
.animate.panic, .onHoverPanic:hover, .trigger.panic {
  -webkit-animation-name: panic;
  -moz-animation-name: panic;
  -ms-animation-name: panic;
  -o-animation-name: panic;
  animation-name: panic;
}
@include animationMixin(explode) {
  0% {
    -webkit-transform: scale(1);
  }
  20% {
    -webkit-transform: scale(.3) rotate(-3deg);
  }
  60% {
    -webkit-transform: scale(.3) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(.4) rotate(8deg);
  }
  40% {
    -webkit-transform: scale(.3) rotate(-11deg);
  }
  50% {
    -webkit-transform: scale(.4) rotate(14deg);
  }
  70% {
    -webkit-transform: scale(.2) rotate(17deg);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(.2) rotate(-20deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(10) rotate(360deg);
  }
}
.animate.explode, .trigger.explode {
  -webkit-animation-name: explode;
  -moz-animation-name: explode;
  -ms-animation-name: explode;
  -o-animation-name: explode;
  animation-name: explode;
}