/* divider lines */

.divider {
  float: left;
  width: 100%;

  &.type1 {
    padding-top: 4.375rem;
    margin-bottom: 4.688rem;
    border-bottom: 0.063rem solid $gray-200;
  }

  &.type2 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/divider-line1.png) no-repeat center bottom;
  }

  &.type3 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    text-align: center;
    background: url(../images/dividers/h-line.jpg) repeat-x left 6.438rem;

    svg {
      padding: 5px;
      color: $white;
      width: 1.688rem;
      height: 1.688rem;
      border-radius: 100%;
      background-color: $gray-400;
    }
  }

  &.type4 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    text-align: center;
    background: url(../images/dividers/h-line.jpg) repeat-x left 6.438rem;

    svg {
      padding: 0rem 0.5rem 0.8rem 0.5rem;
      color: $gray-400;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      background-color: $white;
    }
  }

  &.type5 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/h-line.jpg) repeat-x left 6.438rem;
    text-align: center;
    svg {
      padding: 0rem 0.5rem 0.8rem 0.5rem;
      color: $gray-400;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      background-color: $white;
    }
  }

  &.type6 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/shadow3.png) repeat-x left bottom;
    text-align: center;
  }

  &.type7 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    border-bottom: 0.063rem dashed $gray-400;
    // border-bottom: 0.063rem dashed #e3e3e3;
  }

  &.type8 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    border-bottom: 0.063rem dotted $gray-600;
  }

  &.type9 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/shadow2.png) no-repeat center bottom;
    text-align: center;
  }

  // &.type9.lessm {
  //   padding-top: 3.688rem;
  //   margin-bottom: 3.125rem;
  // }

  // &.type9.lessm2 {
  //   padding-top: 0.625rem;
  //   margin-bottom: 6.625rem;
  // }

  &.type10 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/hcross-lines.jpg) repeat-x left bottom;
    text-align: center;
  }

  &.type11 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/h-line5.png) no-repeat center bottom;
    text-align: center;
  }

  &.type12 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/h-line6.png) no-repeat center bottom;
    text-align: center;
  }

  &.type13 {
    padding-top: 5.625rem;
    margin-bottom: 5.625rem;
    background: url(../images/dividers/h-line7.png) no-repeat center bottom;
    text-align: center;
  }

  // &.type13.lessm {
  //   padding-top: 3.125rem;
  //   margin-bottom: 3.125rem;
  // }

  // &.type20 {
  //   width: 80%;
  //   padding-top: 1.25rem;
  //   margin: 0 auto 0 auto;
  //   border-bottom: 0.063rem solid #999;
  // }

  // &.type21 {
  //   margin-top: 1.125rem;
  //   margin-bottom: 1.563rem;
  //   border-bottom: 0.063rem solid $gray-200;
  // }

  // &.type21.last {
  //   margin-bottom:0;
  // }

  // &.type22 {
  //   margin-top: 1.125rem;
  //   margin-bottom: 1.563rem;
  //   border-bottom: 0.063rem solid #e1e1e1;
  // }

  // &.type23 {
  //   padding-top: 3.125rem;
  //   margin-bottom: 3.125rem;
  //   border-bottom: 0.063rem solid #d0d0d0;
  // }

}