.box {
  position: relative;
  &.dark {
    background-color: $dark;
    color: $white;
  }
  &.darkGray {
    background-color: adjust-color($dark, $saturation: -12%, $lightness: 19%);
    color: $white;
    &::after {
      background-color: adjust-color($dark, $saturation: -12%, $lightness: 19%);
    }
  }
  &.primary {
    background-color: $primary;
    color: $white;
  }
  &.light {
    background-color: adjust-color($gray, $lightness: 5%);
    color: $dark;
  }
  &.bgWhite {
    background-color: $white;
    color: $dark;
  }
  &.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
  }
  &.transparent {
    opacity : 0.8;
  }
  &.title {
    display: block;
    background-color: $primary;
    text-transform: uppercase;
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding: 2rem 0rem;
    width: 100%;
    @include media-breakpoint-only(xs) {
      font-size: 1.5rem;
    }

    .form-switch {
      > label {
        text-transform: none;
        font-size: 1.2rem;
        @include media-breakpoint-only(xs) {
          font-size: 1rem;
        }
      }
      .form-check-input {
        &:checked {
          background-color: adjust-color($dark, $saturation: -12%, $lightness: 19%);
        }
      }
    }
    &.fixed-top {
      top: $headerMainHeight + $headerTopHeight;
      position: fixed;
      @include main-transition;
      z-index: 9800 !important;
      .compact-header & {
        top: $headerMainComapctHeight;
      }
      + div {
        margin-top: 9rem;
      }
    }
  }
  &.borderedGray {
    margin-bottom: 1rem;
    background-color: $white;
    border-radius: 0.5rem;
    // box-shadow: $box-shadow;
    border: 1px solid $gray;
  }
  &.borderedStrongBottom {
    border: 1px solid $gray;
    border-bottom: 5px solid $gray;
  }
  &.darkHover {
    border: 1px solid darken($gray, 1);
    background-color: $white;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.438rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-style: normal;
    > span {
      display: block;
      font-size: 3rem;
      width: 100%;
      margin-bottom: 1rem;
    }
    &:hover {
      color: white;
      background-color: $dark;
    }
  }
  &.bordered {
    border: 3px solid adjust-color($primary, $saturation: -12%, $lightness: 40%);
    border-radius: 1rem;
  }

  &.description {
    background-color: adjust-color($dark, $saturation: -12%, $lightness: 19%);
    padding: 2rem;
    margin-bottom: 1rem;
    h1, h2, h3 {
      color: $white;
    }
    h2 {
      font-size: 1.55rem;
      font-weight: 300;
    }
    h3 {
      font-weight: 200;
    }
  }

  &.arrow-top::after {
    position: absolute;
    width: 100%;
    height: 1rem;
    top: -1rem;
    left: 0;
    content: " ";
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,100 H 100 L 50,0 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,100 H 100 L 50,0 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-bottom::after {
    position: absolute;
    width: 100%;
    height: 1rem;
    bottom: -1rem;
    left: 0;
    content: " ";
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 H 100 L 50,100 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 H 100 L 50,100 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-right::after {
    position: absolute;
    width: 1rem;
    height: 100%;
    top: 0rem;
    right: -1rem;
    content: " ";
    background-size: 20px 15px;
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 V 100 L 100,50 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 V 100 L 100,50 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-left::after {
    position: absolute;
    width: 1rem;
    height: 100%;
    top: 0rem;
    left: -1rem;
    content: " ";
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 100,0 V 100 L 0,50 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 100,0 V 100 L 0,50 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-small-top::after {
    position: absolute;
    width: 3rem;
    height: 1.5rem;
    top: -1.5rem;
    left: calc(50% - 1.5rem);
    content: " ";
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,100 H 100 L 50,0 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,100 H 100 L 50,0 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-small-bottom::after {
    position: absolute;
    width: 3rem;
    height: 1.5rem;
    bottom: -1.5rem;
    left: calc(50% - 1.5rem);
    content: " ";
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 H 100 L 50,100 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 H 100 L 50,100 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-small-right::after {
    position: absolute;
    width: 1.5rem;
    height: 3rem;
    content: " ";
    top: calc(50% - 1.5rem);
    right: -1.5rem;
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 V 100 L 100,50 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 0,0 V 100 L 100,50 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }
  &.arrow-small-left::after {
    position: absolute;
    width: 1.5rem;
    height: 3rem;
    content: " ";
    top: calc(50% - 1.5rem);
    left: -1.5rem;
    -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 100,0 V 100 L 0,50 Z' /></svg>");
    mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'><path style='fill:#{$primary};stroke:none;' d='M 100,0 V 100 L 0,50 Z' /></svg>");
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  /* flip boxes */
  &.flip {
    // background-color: $white;
    width: 100%;
    height: 10rem;
    margin: 0;
    position: relative;
    overflow: hidden;
    > .front, > .back {
      width: 100%;
      height: 100%;
      -o-transition: all .5s;
      -ms-transition: all .5s;
      -moz-transition: all .5s;
      -webkit-transition: all .5s;
      transition: all .5s;
      -webkit-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      text-align: center;
    }
    > .front {
      z-index: 2;
      padding: 1.5rem 0rem;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1.438rem;
      margin-bottom: 1rem;
      font-style: normal;
      > span {
        width: 100%;
        font-size: 3rem;
        margin-bottom: 1rem;
      }
    }
    > .back {
      z-index: 1;
      padding: 0rem 1rem;

    }
    &:hover {
      .front {
        z-index: 1;
      }
      .back {
        z-index: 2;
      }
    }

    &.flipY {
      // border: 1px solid darken($gray, 1);
      > .front {
        // background-color: $white;
      }
      > .back {
        // color: #fff;
        // background-color: $dark;
        -webkit-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
        -sand-transform: rotateY(180deg);
      }
      &:hover {
        .front {
          -webkit-transform: rotateY(180deg);
          -ms-transform: rotateY(180deg);
          -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
          -sand-transform: rotateY(180deg);
        }
        .back {
          -webkit-transform: rotateY(0deg);
          -ms-transform: rotateY(0deg);
          -moz-transform: rotateY(0deg);
          transform: rotateY(0deg);
          -sand-transform: rotate(0deg);
        }
      }
    }

    &.flipX {
      // border: 1px solid darken($gray, 1);
      > .front {
        // background-color: lighten($gray, 3.5);
      }
      > .back {
        // color: $white;
        // background-color: $primary;
        -webkit-transform: rotateX(-180deg);
        -ms-transform: rotateX(-180deg);
        -moz-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
        -sand-transform: rotateX(180deg);
      }
      &:hover {
        .front {
          -webkit-transform: rotateX(180deg);
          -ms-transform: rotateX(180deg);
          -moz-transform: rotateX(180deg);
          transform: rotateX(180deg);
          -sand-transform: rotateX(180deg);
        }
        .back {
          -webkit-transform: rotateX(0deg);
          -ms-transform: rotateX(0deg);
          -moz-transform: rotateX(0deg);
          transform: rotateX(0deg);
          -sand-transform: rotate(0deg);
        }
      }
    }
  }

  &.adaptive {
    width: 100%;
    display: block;
    margin-bottom: 2rem;
    padding: 2rem 0 56.25% 0;
    width: 100%;
    // background-image: url(../images/backgrounds/services.jpeg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;


    // text-transform: uppercase;
    // color: $white;
    // font-size: 2rem;
    // font-weight: 500;
  }
}