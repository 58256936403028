// Headear
header {
  .headerTop {
    background: $dark;

		.select-language {
			background-color: $dark;
			background: #404a53 url("./images/arrow-down.png") no-repeat 90% 50%;
			select {
				color: #fff;
				option {
					background-color: #404a53;
				}
			}
		}

    button {
      color: #fff;
      background: #4e5760;
		}
	}
}

// ScrollToTop
.scrollup {
	background-color: tint-color($primary, 40%);
	border-radius: 50%;
	transition: opacity 1s ease-in-out;
	box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
	color: $white;
}